.dashboard-page {
  .mobile-top-bar {
    // .selectpicker {
    //   @media #{$navbreak-max}  {
    //     display: block !important;
    //     background-color: #6790A6;
    //     color: #fff;
    //     fill: #fff;
    //   }
    // }
    .custom-select-styles {
      [class$='-control'] {
        box-shadow: none !important;
        display: block !important;
        height: 20px;
        min-height: 20px;
      }
      [class$='singleValue'] {
        display: block;
        margin: 0;
        max-width: none;
        line-height: 22px;
        font-size: 20px;
        font-weight: 600;
        color: white;
        width: 100%;
        text-align: center;
        &::after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          transform: translate(-50%, -50%);
          border: 8px solid transparent;
          border-top: 6px solid white;
          border-bottom: none;
          margin-left: 20px;
          margin-top: 5px;
        }
      }
      svg {
        display: none;
      }
      [class$='-menu'] {
        border: none;
        box-shadow: none;
        padding: 0;
        border-radius: 8px;
        > div {
          padding: 0;
          filter: drop-shadow(0 1px 5px rgba(0, 0, 0, 0.2));
          background-color: transparent;
        }
      }
      [class$='-option'] {
        font-weight: 600;
        transition: 0.3s ease-in-out;
        &:hover,
        &:focus {
          color: white;
          background-color: $navy-50;
        }
        &:active {
          color: white;
          background-color: $navy;
        }
        &:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        &:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}
