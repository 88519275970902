.customer-details {
  padding: 1rem 0;
  margin-bottom: 2rem;

  @media #{$navbreak} {
    padding: 2rem;
    margin-bottom: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__header {
    margin-bottom: 1.5rem;

    &__name {
      display: flex;
      align-items: flex-end;
      gap: 0.6rem;
      margin-bottom: 0.4rem;

      h1 {
        margin-bottom: 0;
        line-height: 1;
        color: #1f5274;
        font-size: pxToRem(32);
        font-weight: 600;
      }

      &__salutation {
        margin-bottom: 3px;
        color: #1f5274;
        font-size: pxToRem(17);
        font-weight: 600;
      }
    }

    &__summary {
      color: $txt-header-2;
      font-size: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.6rem;

      @media #{$md} {
        flex-direction: row;
        align-items: center;
      }

      &__first-purchase {
        border-radius: 4px;
        background: #7195ad;
        padding: 2px 5px 0 5px;
        margin-top: -2px;
        color: $white;
        display: flex;
        align-items: center;
        align-self: flex-start;
      }
    }
  }

  &__stats,
  &__profile,
  &__visited-events {
    border-radius: $border-radius;

    .fd-accordion .fd-accordion-item .fd-accordion-header {
      border-radius: $border-radius;
    }

    @media #{$navbreak} {
      background-color: transparent;
    }
  }

  &__stats {
    border: 1px solid $border;
    background-color: $white;

    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media #{$md} {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__row {
      display: flex;
      align-items: center;
      gap: 1rem;

      &__icon {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $background;

        svg {
          width: 18px;
          height: 18px;
        }
        svg.navy path {
          fill: $navy-active;
        }

        svg.stroke-navy path {
          stroke: $navy-active;
        }
      }

      &__text {
        &__value {
          color: $black;
          font-size: pxToRem(18);
          font-weight: 600;
          margin: 0.2rem 0;
        }

        &__title {
          color: #616060;
          font-size: 1rem;
        }
      }
    }
  }

  &__profile, &__visited-events {
    &.open {
      border: 1px solid $border;
      background-color: $white;

      .fd-accordion .fd-accordion-item .fd-accordion-header {
        border-radius: 0;
      }
    }
  }

  &__profile {
    &__title {
      color: $black;
      font-size: 1rem;
      font-weight: 500;
    }

    &__value {
      color: #3b3b3b;
      font-size: 1rem;
    }

    a {
      color: #2f80ed !important;
    }
  }

  &__row {
    border-bottom: 1px solid $border;
    padding-bottom: 12px;
    margin-bottom: 16px;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .fd-accordion {
    &-item > * {
      padding: 0.775rem 1rem;
    }

    &-header {
      border-bottom: 1px solid $border;
      text-align: left;
      color: $black;
      font-size: pxToRem(18);
      font-weight: 500;

      // when not active
      &.collapsed {
        background-color: #f5f6fa;
        border: none !important;
        overflow: inherit;
        margin: 0;
      }
    }
  }

  .table {
    margin-bottom: 0;

    th,
    .table td {
      border-top: 0;
    }

    th {
      color: $black;
      font-size: 1rem;
      font-weight: 500;
      border-bottom: 1px solid $border;
    }

    td {
      color: #5c5c5c;
      font-size: 1rem;
    }
  }
}
