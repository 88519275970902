.bt {
  display: inline-block;
  display: inline-flex;
  vertical-align: middle;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  text-wrap: nowrap;

  color: white;

  transition: 0.25s ease-in-out;

  &.dashed {
    background: white;
    border: 1px dashed $orange;
    padding: 6px 12px;
    color: $orange;
    width: 100%;

    &.navy {
      border: 1px dashed $navy;
      color: $navy;
      // }
    }
  }

  &.hollow {
    background: transparent;
    border: 1px solid $orange;
    color: $orange;
    transition-duration: 0.2s;

    &--light {
      background: white;
    }

    & span {
      // border: 1px solid green;
      padding-top: 2px;
      font-size: 18px;
    }

    &:hover {
      background: $orange;
      color: white;
    }

    &.navy {
      border: 1px solid $navy;
      color: $navy;

      &:hover {
        background: $navy;
        color: white;
      }

      @media (hover: none) {
        &:hover {
          background-color: $white;
          color: inherit;
        }
      }
    }

    &--inverted {
      background-color: $white;
      border: 1px solid #D8D8D8 !important;
      color: #504141 !important;

      &:hover {
        background-color: #F5F5F5;
      }

      &:active {
        background-color: #E8E8E8;
      }

      &[disabled] {
        background-color: #F5F5F5;
        color: #D8D8D8;
      }

      @media (hover: none) {
        &:hover {
          background-color: $white;
          color: inherit;
        }
      }
    }
  }

  &.w-100 {
    width: 100% !important;

    @media screen and (min-width: 991px) {
      width: unset !important;
    }
  }

  &.w-md-100 {
    @media screen and (min-width: 768px) {
      width: 100% !important;
    }
  }

  &.s-24 {
    font-size: $fs-16;
    line-height: pxToRem(16);
    padding: pxToRem(8) pxToRem(17) pxToRem(4);
    min-height: pxToRem(24);
  }

  &.s-38 {
    font-size: $fs-18;
    line-height: pxToRem(19);
    padding: pxToRem(3) pxToRem(30);

    @media #{$ie} {
      padding: pxToRem(12) pxToRem(30) 0;
    }

    @media #{$sm-max} {
      padding: pxToRem(3) pxToRem(10);
    }

    min-height: pxToRem(38);

    span {
      padding-top: pxToRem(3);

      @media #{$ie} {
        padding-top: 0;
      }
    }

    i {
      font-size: $fs-14;

      @media #{$ie} {
        position: relative;
        top: pxToRem(-3);
      }
    }

    .icon-arrow-right {
      margin-left: pxToRem(16);
    }

    .icon-arrow-left {
      margin-right: pxToRem(16);
    }
  }

  &.s-50 {
    font-size: $fs-16;
    line-height: pxToRem(19);
    padding: pxToRem(8) pxToRem(17) pxToRem(2);
    min-height: pxToRem(50);
  }

  &.bt-orange {
    color: white !important;
    background-color: #f58562;

    &:hover,
    &:focus {
      background-color: #f17852;
    }

    &:active {
      background-color: #d15c37;
    }

    &[disabled] {
      background-color: #fbb8a3;
    }
  }

  &.bt-navy {
    color: white !important;
    background-color: #1f5274;

    &:hover,
    &:focus {
      background-color: #286d9b;
    }

    &:active {
      background-color: #104466;
    }

    &[disabled] {
      background-color: #7195ad;
    }
  }

  &.bt-gray {
    color: white !important;
    background-color: $arrow;

    &:hover,
    &:focus {
      background-color: #bfbfbfee;
    }

    &:active {
      background-color: #bcbcbc;
    }

    &[disabled] {
      background-color: #bebebe88;
    }
  }

  &.bt-b-orange {
    color: #f58562;
    border: 1px solid #f58562;
    background-color: transparent;

    &:hover,
    &:focus {
      color: #e86b44;
      border-color: #e0653f;
    }

    &:active {
      color: #dc5d35;
      border-color: #f58562;
      background-color: #fff4f1;
    }

    &[disabled] {
      color: #f9b29b;
      border-color: #f5b29d;
    }
  }
}

.bttn {
  position: relative;
  font-weight: 600;
  cursor: pointer;
  border-radius: 6px;
  overflow: hidden;
  font-size: $fs-16;
  line-height: pxToRem(16);
  padding: pxToRem(4) pxToRem(17) 0;
  min-height: pxToRem(40);
  min-width: pxToRem(80);
  color: white;
  transition-duration: 0.1s;
  border: 3px solid $navy;

  transition: 0.25s ease-in-out;

  &.w-100 {
    width: 100% !important;

    @media screen and (min-width: 991px) {
      width: unset !important;
    }
  }

  .background {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }

  .content {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 12;
    display: inline-block;
    display: inline-flex;
    vertical-align: middle;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    text-align: center;

    svg {
      path {
        fill: $navy;
      }
    }

    & i {
      color: $navy;
      font-size: 10px;
    }

    &:hover {
      background-color: $navy;
      transition-duration: 0.1s;

      svg {
        path {
          fill: white;
        }
      }

      & i {
        color: white;
      }
    }
  }

  &.dotted {
    background: transparent;
    border: 1px dashed $orange;
    padding: 6px 12px;
    color: $orange;

    &.navy {
      border: 1px dashed $navy;
      color: $navy;
    }
  }
}

button.bt[disabled],
button.bttn[disabled] {
  opacity: 0.5;

  &:hover {
    opacity: 0.5;
    cursor: not-allowed;
    filter: none;
  }
}

.bt.simulation-btn {
  background-color: $navy;
  border-radius: 4px;
  font-size: $fs-18;
  line-height: pxToRem(20);
  margin-right: pxToRem(15);

  &:hover {
    background-color: #286d9b;
  }

  span {
    white-space: normal !important;
  }

  @media #{$md-max} {
    width: calc(100% - 50px);
    font-size: $fs-16;
    line-height: pxToRem(18);
  }
}

.txt-btn {
  font-weight: 500;
  font-size: $fs-16;
  line-height: pxToRem(18);
  color: #7195ad;

  span {
    margin-right: 5px;
  }
}

.btn-group {
  gap: 1px;
  >* {
    &:not(:last-child), &:not(:last-child) .bt {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:not(:first-child), &:not(:first-child) .bt {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.icon-button {
  &:disabled {
    svg {
      opacity: 0.5;
    }
  }
}