.bg-transparent {
  background-color: transparent;
}
.bg-white {
  background-color: $white;
}

.bg-red {
  background-color: $red;
}
.bg-red-active {
  background-color: $red-active;
}

.bg-navy {
  background-color: $navy;
}
.bg-orange {
  background-color: $orange;
}

.bg-navy {
  background-color: $navy;
}
.bg-navy-active {
  background-color: $navy-active;
}
.bg-navy-75 {
  background-color: $navy-75;
}

.bg-green {
  background-color: $green;
}

html {
  font-size: $fs-16;
  /*
    It enables scalling
    @media #{$xxl} {
      font-size: 18px;
    }
    @media only screen and (min-width: 1600px) {
      font-size: 20px;
    }
    @media only screen and (min-width: 1780px) {
      font-size: 23px;
    }
  */
}

.hide-mobile {
  @media #{$navbreak-max} {
    display: none !important;
  }
}

.hide-desktop {
  @media #{$navbreak} {
    display: none !important;
  }
}
.hide-sm {
  @media #{$sm} {
    display: none !important;
  }
}

.mobile-bg-white {
  @media #{$navbreak-max} {
    background-color: $white;
    .mobile-top-bar {
      margin-bottom: 0;
    }
  }
}

.body-no-scroll {
  overflow-y: hidden;
}

/* Because there is a nav bar in left */
body[data-logged-in] {
  main {
    @media #{$navbreak} {
      // left padding is dynamic now since the left nav bar is expandable!
      // padding-left: pxToRem(80);
    }
  }
  .container-fluid {
    &.no-padding-sm {
      padding: 0;
      @media (min-width: 992px) and (max-width: 1300px) {
        padding-left: pxToRem(20);
        padding-right: pxToRem(20);
      }
      @media #{$xl} {
        padding-left: pxToRem(90);
        padding-right: pxToRem(90);
      }
    }
    @media #{$xl} {
      padding-left: pxToRem(90);
      padding-right: pxToRem(90);
    }

    @media (min-width: 992px) and (max-width: 1300px) {
      // this removes padding
      // padding-left: pxToRem(70);
      // padding-right: pxToRem(70);
    }
  }
  .container-fluid.half-fluid {
    @media #{$lg} {
      padding-right: 15px;
    }
    @media #{$xl} {
      padding-right: 15px;
    }
  }
}

.child-middle {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

/* col-lg-auto and put this inside */
.col-width-fix {
  @media #{$navbreak} {
    width: pxToRem(290);
  }
  @media #{$xl} {
    width: pxToRem(380);
  }
}
.pd-desktop-left {
  @media #{$navbreak} {
    padding-right: pxToRem(22);
  }
}
.pd-desktop-right {
  @media #{$navbreak} {
    padding-left: 0;
  }
}

.col-lg {
  min-width: 1px;
}

.card {
  border: none;
  border-radius: $card-border-radius;
  &.card-default {
    padding-top: pxToRem(20);
    margin-bottom: pxToRem(22);
    > * {
      padding-left: pxToRem(20);
      padding-right: pxToRem(20);
      @media #{$navbreak} {
        padding-left: pxToRem(40);
        padding-right: pxToRem(40);
      }
    }
    &.no-inner-padding {
      & > * {
        padding-left: pxToRem(0);
        padding-right: pxToRem(0);
        @media #{$navbreak} {
          padding-left: pxToRem(40);
          padding-right: pxToRem(40);
        }
      }
    }
    .card-header {
      padding-top: pxToRem(5);
      background-color: transparent;
      border-bottom-color: $border;
      &.no-border-mobile {
        @media #{$navbreak-max} {
          border: none !important;
        }
      }
    }
  }
  .card-title-default {
    color: $txt-header;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    font-weight: 600;
  }
}

.warning-red {
  color: rgb(245, 102, 102);
}

.capitalized {
  text-transform: capitalize;
}

.ta-r {
  text-align: right;
}
.mleft-2 {
  margin-left: 2em;
}

.mt-6 {
  margin-top: 5rem !important;
}

.rounded-circle {
  border-radius: 50%;
}

.txt-header {
  color: $txt-header;
}

.overflow-visible {
  overflow: visible !important;
}