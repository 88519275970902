.sales-upload {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;

  @media #{$navbreak} {
    padding: 1.125rem 2rem;
  }

  .card-header {
    background-color: transparent;
    border-bottom: none;
  }

  .how-it-works {
    margin-top: 0;

    .card {
      background-color: $background;

      .card-body,
      .card-header {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
      }

      @media #{$navbreak-max} {
        background-color: $white;
      }
    }
  }
}
