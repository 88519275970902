html,
p,
ul,
ol,
li,
input[type='submit'],
button {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  line-height: normal;
  font-family: 'TTCommmons', sans-serif;
  font-size: $fs-16;
  color: #000;
  background-color: $background;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
  min-height: 100vh;
}

main {
  display: block;
}

a {
  display: block;
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active {
    color: inherit;
    text-decoration: none;
  }
}

ul,
ol {
  list-style: none;
}

label {
  display: block;
}

input,
select {
  width: 100%;
  border: none;
}

input[type='submit'] {
  background-color: initial;
  border: none;
  cursor: pointer;
}

input[type='search'] {
  -webkit-appearance: none;
  border: none;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

textarea {
  display: block;
  width: 100%;
  resize: none;
}

button {
  display: block;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    padding: 0;
    border: none;
  }
}

img {
  display: block;
  max-width: 100%;
}

* {
  -webkit-overflow-scrolling: touch;
}

*,
*::before,
*::after {
  @include box-sizing();
  outline: none;
  background-repeat: no-repeat;
}
