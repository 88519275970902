.event-itc-portal {
  width: 100%;

  .portal-modal_outer_wrapper_trap {
    width: 100%;
  }

  .portal-modal_inner_content_wrapper {
    overflow: hidden;
    height: 100%;
    width: 92%;
    margin-left: 72px;
    
    @media #{$navbreak} {
      margin-left: 92px;
    }

    @media #{$xl} {
      margin-left: 100px;
    }

    @media #{$xxl} {
      margin-left: 110px;
    }
  }

  .event-itc-form {
    position: relative;
    height: 94vh;

    &_container {
      overflow-y: auto;
      height: 100%;
    }

    &_form {
      margin-left: 35%;
      height: 100%;

      &__container {
        min-height: 100%;
        padding: 15px;
        border-radius: 0;
        padding-bottom: 5rem;

        h2 {
          padding-top: 10px;
        }
      }
    }

    &_hiw {
      position: absolute;
      top: 0;
      left: 0;
      width: 35%;
      height: 100%;
      padding: 25px;
    }

    &_buttons {
      position: absolute;
      right: 0px;
      bottom: 0px;
      left: 0px;
      width: 100%;
      background-color: $white;
      border-top: 2px solid #e8e9ee;
      box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.25);
      z-index: 1000;

      &__container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
        padding: .8rem 1rem;
      }
    }
  }
}

.itc-event-page {
  background-color: $white;

  .event-itc-form {
    @media #{$navbreak} {
      padding: .8rem 1rem;
    }

    &_container {
      padding: 15px 0;
    }

    &_form {  
      &__container {
        padding: 15px;
        padding-bottom: 0;
        margin-bottom: 2rem;
      }
    }

    &_buttons {
      &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
      }
    }

    &_hiw {
      margin-bottom: 1.5rem;
    }
  }
}

.event-itc-form {
  background-color: $white;

  .modal_outer_wrapper {
    top: -32px;
    bottom: -32px;
  }
  .event-editor_copy-dialog {
    .modal_inner_wrapper {
      width: unset !important;
      max-height: 500px !important;
    }
  }

  &_form {
    &__container {
      background-color: $background;
      border-radius: 8px;
    }

    h2 {
      color: $navy;
      font-size: pxToRem(25);
      font-weight: 600;
    }
  }

  &_buttons {
    .bt {
      min-width: 100px;
    }
  }

  &_hiw {
    margin-bottom: 1.5rem;

    .how-it-works_notice, .how-it-works_readMore {
      color: $red !important;
    }

    h1 {
      color: $navy;
      font-size: pxToRem(25);
      font-weight: 600;
      margin-bottom: 22px;
    }

    .how-it-works {
      .card {
        background-color: $background;
      }
    }
  }
}
