.notice {
  background-color: #f5f6fa;
  padding: 1em;
  border-radius: 8px;
  display: flex;
  gap: 1em;
  align-items: center;

  svg {
    margin-top: -4px;
  }

  &--light {
    background-color: $white;
  }
}
