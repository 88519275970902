.card.card-default.card-sales-trend {
  position: relative;

  @media #{$navbreak-max} {
    padding-top: 0;
  }
}

.sales-trend-chart-wrapper {
  padding: 0rem !important;
}

.highcharts-container {
  width: 100%;
}

.sales-trend-chart {
  position: relative;

  .highcharts-container {
    max-width: 100%;
    width: 100% !important;
    height: 100% !important;
  }

  .highcharts-graph {
    stroke-width: 4 !important; //Width of lines
  }

  .highcharts-legend-item {
    .highcharts-graph {
      stroke-width: 10 !important; //Width of lines
      margin-right: 12px;
    }

    &-text {
      padding-left: 5px;
      font-weight: 400;
    }
  }

  .highcharts-legend-item,
  .highcharts-axis-title,
  .highcharts-axis-labels {
    text {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 13px !important;
    }
  }

  .highcharts-xaxis-labels {
    text,
    tspan {
      color: #3d3d3d !important;
      fill: #3d3d3d !important;
    }
  }

  .highcharts-yaxis-labels {
    text {
      color: #4b5f76 !important;
      fill: #4b5f76 !important;
    }
  }

  .highcharts-axis-title {
    tspan {
      font-family: 'Inter';
    }

    text,
    tspan {
      color: #3d3d3d !important;
      fill: #3d3d3d !important;
    }

    text-transform: uppercase;
  }

  .highcharts-popup-content {
    padding: 12px 15px 5px;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 13px !important;

    h5 {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 15px !important;
    }

    p {
      text-align: right;

      .date {
        color: #919191;
      }
    }
  }
}
