.campaign-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
  position: relative;

  &_toggle {
    width: 100%;
    padding: 0;
    display: flex !important;
    align-items: center;
    flex: 1;
    overflow: hidden;
    min-height: 32px;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &__title {
      display: flex;
      align-items: center !important;
      padding: 0.1rem 0;
      border: none;
      font-family: 'TTCommons', sans-serif;
      font-weight: 500;
      font-size: $fs-18;
      color: #000;
      text-align: left;
      position: relative;

      &__wrapper {
        display: flex;
        flex: 1;
        align-items: center;
        gap: 1rem;
        max-width: 100%;
        overflow: hidden;
        padding: 2px 0;

        .title {
          max-width: 100%;
          overflow: hidden;
          line-height: 1rem;

          &.lonely {
            margin-right: 0.8rem;
          }
        }

        .badge {
          cursor: pointer;
          background: $orange;
          padding: 4px 6px 4px 6px;
          color: white;
          border-radius: $border-radius;
          font-size: 14px;
          font-weight: 500 !important;

          &.ignored {
            background-color: $navy-60;
          }
        }
      }
    }
  }

  &_tc-status {
    font-size: $fs-18;
    font-weight: 500;
    color: #fff;
    width: 116px;
    line-height: 20px;
    transition: 0.3s ease-in-out;
    border: none;
    cursor: default !important;

    &.clickable {
      cursor: pointer !important;
    }

    &.select {
      background: $navy;

      &:focus,
      &:active,
      &:hover {
        background-color: $navy !important;
      }
    }

    &.select-disabled {
      cursor: default;
      background: #c0bfbe;

      &:focus,
      &:active,
      &:hover {
        background-color: #c0bfbe !important;
      }
    }

    &.selected,
    &.saved {
      background-color: $orange;

      &:focus,
      &:active,
      &:hover {
        background-color: $orange !important;
      }
    }

    &.pending {
      background-color: #c0bfbe;

      &:focus,
      &:active,
      &:hover {
        background-color: #c0bfbe !important;
      }
    }

    &.ignored {
      background-color: $navy-60;

      &:focus,
      &:active,
      &:hover {
        background-color: $navy-60 !important;
      }
    }

    &:focus,
    &:active,
    &:hover {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }
}
