.upload-box {
  margin-top: 8px;
  border-radius: $border-radius;
  border: 1px dashed $border-event-search;
  padding: 1em;

  &_add {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80px;
    height: 90px;
    border: 1px solid $navy-75;

    &:hover {
      cursor: pointer;
    }
  }

  .accepted-file {
    width: 76px;
    position: relative;

    &_icon {
      width: 100%;
      height: 100%;
    }

    &_remove-icon {
      position: absolute;
      top: 0;
      right: -4px;
      opacity: 0.9;
      overflow: hidden;
      transition-duration: 0.2s;

      &_bg {
        position: absolute;
        top: 0;
        right: -4px;
        opacity: 0.9;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: white;
        display: inline;
      }

      &:hover {
        cursor: pointer;
        opacity: 1;
        filter: contrast(120%);
        transform: scale(1.2);
        transition-duration: 0.2s;
      }
    }
  }

  .add-file {
    margin-top: 4px;
    line-height: 0.9;
    text-align: center;
    word-break: break-word;
    font-size: 0.8rem;
    color: #7195ad;
    font-weight: 500;
    max-width: 80%;
  }
}
