.notification-list {
  min-height: 100vh;

  @media #{$lg-max} {
    margin-top: 3rem;
  }

  .action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.2rem;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;

      &_actions,
      &_filters {
        justify-content: space-between;
        width: 100%;
      }
    }

    &_actions {
      display: flex;
      align-items: center;
      gap: 1rem;

      @media screen and (max-width: 370px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
      }
    }

    &_filters {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;

      .switch .switch_label {
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: $light-black;
        text-align: left;
        font-weight: 500;
        font-size: pxToRem(18);
      }
    }
  }

  #side-drawer {
    .side-drawer_close-btn_wrapper {
      top: 1.2rem;
      right: 0rem;
    }

    .notification-details {
      &_title {
        margin-right: 2.4rem;
      }
    }
  }
}
