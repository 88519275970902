.prediction-box-small {
  color: $txt-header;
  position: relative;

  &_wrapper {
    display: flex;
    // height: 60px;
    // width: 80px;
    z-index: 10;
    // &--red {
    //   border-top: 1px solid;
    //   border-left: 1px solid;
    //   border-bottom: 1px solid;
    //   border-color: #f12929;
    //   border-radius: 8px;
    // }

    // &--green {
    //   border-top: 1px solid;
    //   border-left: 1px solid;
    //   border-bottom: 1px solid;
    //   border-color: #60c640;
    //   border-radius: 8px;
    // }
  }

  &_inner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;

    // &--green {
    //   border: 1px solid;
    //   border-radius: 8px;

    //   .prediction-box-small_total::after {
    //     border-top: none;
    //     border-bottom-color: #60c640;
    //   }
    //   &,
    //   .prediction-box-small_arrow::after {
    //     background-color: white;
    //     border-color: #60c640;
    //     border-right: 2px solid transparent;
    //   }
    // }
    // &--red {
    //   border: 1px solid;
    //   border-radius: 8px;
    //   .prediction-box-small_total::after {
    //     border-top-color: #f12929;
    //     border-bottom: none;
    //   }
    //   &,
    //   .prediction-box-small_arrow::after {
    //     background-color: white;
    //     border-color: #f12929;
    //     border-right: 2px solid transparent;
    //   }
    // }
  }

  &_arrow {
    position: absolute;
    top: 0;
    left: 100%;
    left: calc(100% - 6px);
    height: 100%;
    width: pxToRem(22);
    pointer-events: none;
    overflow: hidden;
    z-index: 10;

    // &.red::after {
    //   border: 1px solid #f12929;
    // }
    // &.green::after {
    //   border: 1px solid #60c640;
    // }
    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      width: pxToRem(42);
      height: pxToRem(45);
      content: '';
      transform: translate(-50%, -50%) scaleX(0.5) rotate(45deg);
      border-radius: 8px;
      background-color: white;
      // border: 1px solid $border;
    }
  }

  &__benchmark {
    @media #{$md-max} {
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
}

.prediction-box {
  width: 100%;
  background-color: $background;
  border-radius: 8px;

  &__data {
    height: pxToRem(80);
    padding: 10px 0 0;
    border-radius: 8px;
    position: relative;

    &.small {
      height: unset;
      padding: 0;
    }
  }

  &__main {
    padding-left: pxToRem(15);
    background-color: $background;
    border: 1px solid $border;
    border-right: none;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;

    &,
    .prediction-box__arrow::after {
      transition: background 0.1s ease-in-out;
    }

    &--green {
      .prediction-box__total::after {
        border-top: none;
        border-bottom-color: #60c640;
      }

      &,
      .prediction-box__arrow::after {
        background-color: #f6fcf4;
        border-color: #60c640;
      }

      &:hover {
        &,
        .prediction-box__arrow::after {
          background-color: darken(#f6fcf4, 5);
        }
      }
    }

    &--red {
      .prediction-box__total::after {
        border-top-color: #f12929;
        border-bottom: none;
      }

      &,
      .prediction-box__arrow::after {
        background-color: #feebeb;
        border-color: #f12929;
      }

      &:hover {
        &,
        .prediction-box__arrow::after {
          background-color: darken(#feebeb, 5);
        }
      }
    }
  }

  &__second {
    padding-left: 0;
    display: flex;
    justify-content: center;

    &__content {
      display: inline-block;
    }
  }

  &__arrow {
    position: absolute;
    top: 0;
    left: 100%;
    left: calc(100% - 1px);
    height: 100%;
    width: pxToRem(20);
    pointer-events: none;
    overflow: hidden;

    &.small {
      left: calc(100% - 6px);
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      width: pxToRem(60);
      height: pxToRem(60);
      content: '';
      transform: translate(-50%, -50%) scaleX(0.5) rotate(45deg);
      border-radius: 8px;
      background-color: $background;
      border: 1px solid $border;
    }
  }

  &__total {
    display: block;
    font-size: pxToRem(26);
    line-height: pxToRem(43);
    font-weight: 600;

    @media screen and (min-width: 350px) {
      font-size: $fs-40;
      line-height: pxToRem(43);
    }

    @media screen and (max-width: 1440px) {
      font-size: $fs-24;
    }

    color: $black;
    white-space: nowrap;

    &::after {
      display: inline-block;
      content: '';
      border: pxToRem(12) solid transparent;
      vertical-align: middle;
      margin-left: pxToRem(5);
      position: relative;
      z-index: 1;
      // pointer-events: none;
    }
  }

  &__label {
    font-size: $fs-16;
    line-height: pxToRem(18);
    color: $txt-content;
    white-space: nowrap;
  }
}
