.custom-large-radio {
  box-sizing: border-box;
  opacity: 0.5;
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 0.8rem 8px;
  align-items: flex-end;
  margin-top: 4px;
  margin-bottom: 8px;
  color: $txt-content;
  border: 1px solid #bfc4d6;
  display: flex;
  flex-wrap: nowrap;
  line-height: 17px;
  background: #fff;

  & > .custom-radio {
    height: 10px;
    width: 10px;
  }

  &:hover {
    cursor: pointer;
  }

  .title {
    color: $txt-content;
    margin: 0;
    padding: 0;
    line-height: 16px;
    margin-left: 12px;
    font-size: $fs-18;
    font-weight: 600;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 0 1 content;
    overflow: hidden;
  }

  &.active {
    box-shadow: 0px 1px 4px #00000055;
    opacity: 1;
    background: $orange;
    color: #fff;
    span,
    p,
    div {
      color: #fff;
    }

    .custom-radio__box {
      border: 1px solid $orange;
    }

    .custom-radio__second_box {
      background: white;
      border: 2px solid $orange;
    }
    .expected-result {
      transition-duration: 0.2s;
      &-number {
        line-height: 16px;
        font-size: $fs-18;
        font-weight: 700;
      }
    }
  }
}
