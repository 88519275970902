.edit-result {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_icon {
    margin-bottom: 1.5rem;
    margin-top: 1rem;

    img {
      width: 80px;
      height: 80px;
    }
  }

  &_content {
    padding: 1rem;
    text-align: center;

    h1 {
      color: #6790a6;
      margin-bottom: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    p {
      text-align: center;
      color: #7195ad;
      margin-bottom: 1.5rem;
    }
  }

  &_footer-btn {
    width: 100% !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #6790a6 !important;
    color: white !important;
    background-color: #6790a6;
    height: 56px;
    font-size: 1.5rem;

    &:hover,
    &:focus {
      background-color: #286d9b;
    }
    &:active {
      background-color: #104466;
    }
    &[disabled] {
      background-color: #8db1c9;
    }
  }
}
