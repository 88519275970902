$Inter-Regular: './../../fonts/Inter/Inter-Regular';
$Inter-Light: './../../fonts/Inter/Inter-Light-BETA';

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src:
    local('Inter'),
    url('#{$Inter-Regular}.woff2') format('woff2'),
    url('#{$Inter-Regular}.woff') format('woff'),
    url('#{$Inter-Regular}.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src:
    local('Inter'),
    url('#{$Inter-Light}.woff2') format('woff2'),
    url('#{$Inter-Light}.woff') format('woff'),
    url('#{$Inter-Light}.ttf') format('truetype');
}
