/**
 * Fix fonts that render as bold in Firefox
 *
 * Put this near the top of your style.css
 * Before any overriding styles
 */

/*Reset Safari User Agent Styles*/
* {
  -webkit-padding-start: 0px;
  -webkit-padding-end: 0px;
}

html {
  -webkit-padding-start: 0px;
  -webkit-padding-end: 0px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
}

html,
body {
  overflow-x: hidden;
  max-width: 100vw;
}

/**
 * Firefox specific rule
 */

@-moz-document url-prefix() {
  body {
    font-weight: regular !important;
  }
}

@import 'variables';
@import 'feature';

@import 'helpers/bootstrap-overrides';
@import '~bootstrap/scss/bootstrap';
// @import "@fortawesome/fontawesome-free";
@import '~highcharts/css/highcharts';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/v4-shims.scss';

@import '~react-datepicker/dist/react-datepicker.css';

@import 'helpers/themes';
@import 'helpers/mixins';
@import 'helpers/flexbox';
@import 'helpers/breakpoints';
@import 'helpers/base';
@import 'fonts/fonts';

@import 'modules/layout';
@import 'modules/calendar';
@import 'modules/main-nav';
@import 'modules/mobile-top-bar';
@import 'modules/mobile-bottom-bar';
@import 'modules/mobile-nav';
@import 'modules/user-nav';
@import 'modules/user-nav-mobile';

@import 'modules/user-status-bar';
@import 'modules/select-ad-platform';
@import 'modules/review-campaign-choices';
@import 'modules/active-campaigns';
@import 'modules/custom-select';
@import 'modules/upload-box';
@import 'modules/yes-no-prompt';

@import 'shared/accordion';
@import 'shared/fd-accordion';
@import 'shared/buttons';
@import 'shared/card';
@import 'shared/dots-menu';
@import 'shared/drop-down';
@import 'shared/searchable-drop-down';
@import 'shared/modal';
@import 'shared/portal-modal';
@import 'shared/inputs';
@import 'shared/side-drawer';
@import 'shared/events-list-wrapper';
@import 'shared/event-row';
@import 'shared/checkbox';
@import 'shared/radio';
@import 'shared/drop-zone';
@import 'shared/date-picker';
@import 'shared/react-select';
@import 'shared/slider';
@import 'shared/searchbar';
@import 'shared/tabs';
@import 'shared/tip-dialogue-box';
@import 'shared/custom-radio-large-check-button';
@import 'shared/notice';
@import 'shared/paginator';
@import 'shared/copy-to-clipboard';

@import 'shared/controls/switch';

@import 'modules/add-events-modal';
@import 'components/recommendations/campaigns/variation-indicator';
@import 'components/recommendations/campaigns/campaign-header';
@import 'components/recommendations/campaigns/campaign-list';
@import 'components/recommendations/campaigns/campaign-drawer';
@import 'components/recommendations/campaigns/running-campaign';
@import 'components/recommendations/campaigns/edit-result';
@import 'components/recommendations/campaigns/summary';
@import 'components/recommendations/campaigns/campaign-preview';
@import 'components/recommendations/campaigns/open-campaigns';
@import 'components/recommendations/campaigns/event-itc-dialog';

@import 'components/recommendations/campaign/autofill';
@import 'components/recommendations/campaign/targeting';

@import 'components/events/event-header';
@import 'components/events/filters/event-filter';
@import 'components/events/filters/past-events-filter';
@import 'components/events/controlPanel/manual-adjustments';

@import 'components/event/sales-edit/sales-document-upload';
@import 'components/event/sales-edit/cancel-sales-edit-modal-content';

@import 'components/shared/how-it-works';
@import 'components/shared/filters/multi-select';
@import 'components/shared/badge';
@import 'components/shared/stepper';

@import 'components/notifications/notification-list';
@import 'components/notifications/notification-item';
@import 'components/notifications/notification-print';
@import 'components/notifications/notification-details';
@import 'components/shared/toast/app-toast';
@import 'components/shared/toast/toast-header';
@import 'components/shared/toast/toast-body';

@import 'components/meta/assets';
@import 'components/meta/connection';
@import 'components/meta/dialogs';
@import 'components/packageBuilder/target-mode';
@import 'components/packageBuilder/customer-segmentation';
@import 'components/packageBuilder/customer-ids';

@import 'components/sentinel/menu';
@import 'components/sentinel/results';
@import 'components/sentinel/query';
@import 'components/sentinel/skeleton';
@import 'components/sentinel/input';
@import 'components/sentinel/saved-questions';
@import 'components/sentinel/customer-details';

@import 'modules/campaign-social-share-modal.scss';
@import 'modules/sales-edit.scss';
@import 'modules/nav-tabs';
@import 'modules/events-bulk-upload';
@import 'modules/page-header';
@import 'modules/buttons';
@import 'modules/badges';
@import 'modules/progress';
@import 'modules/headings';
@import 'modules/message';
@import 'modules/messages-list';
@import 'modules/events-list';
@import 'modules/events-list-table';
@import 'modules/time-range';
@import 'modules/prediction-box';
@import 'modules/loading-spinner';
@import 'modules/language-switcher';
@import 'modules/footer';
@import 'modules/error-message';
@import 'modules/infobox';
@import 'modules/custom-switch';
@import 'modules/sales-trend';
@import 'modules/ticket-type';
@import 'modules/transactions-chart';
@import 'modules/notification-dropdown';
@import 'modules/splash-screen';
@import 'modules/falling-dots';
@import 'modules/session_timer';

@import 'modules/dashboard';
@import 'modules/recommendations';
@import 'modules/recommendations_single';
@import 'modules/campaign-field';
@import 'modules/media-list';
@import 'modules/campaign-form';
@import 'modules/event-insights';
@import 'modules/event-statistics';
@import 'modules/topic';
@import 'modules/search-dropdown';

@import 'pages/dashboard';
@import 'pages/events';
@import 'pages/event-editor';
@import 'pages/recommendations';
@import 'pages/authentication';
@import 'pages/registration';
@import 'pages/events-insights';
@import 'pages/simulation';
@import 'pages/settings';
@import 'pages/notifications';
@import 'pages/notification';
@import 'pages/package-builder';
@import 'pages/uploads';
@import 'pages/sentinel';

p,
strong,
tr {
  color: $txt-header-2;
}

.login-page {
  @import 'pages/login';
}

.forgot-password-page {
  @import 'pages/forgot-password';
}

.set-password-page {
  @import 'pages/set-password';
}

body {
  font-family: 'TTCommons', sans-serif;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

// Transitions && classes

#fb-share-button {
  background: #4267b2;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  color: white;

  & a {
    display: block;
    text-align: center;
    margin-left: 12px;
    font-weight: 600;
    padding-top: 6px;
    font-size: 17px;
  }

  & img {
    width: 16px;
  }
}

.c-hover {
  &:hover {
    cursor: pointer;
  }
}

.default-cursor {
  cursor: default !important;
}

.hover-color {
  transition-duration: 300ms;

  &:hover {
    cursor: pointer;
    background: rgba(241, 242, 248, 0.6);
  }
}

.bg-hover-color {
  background: rgba(241, 242, 248, 0.6);
}

.bg-white {
  background-color: white;
}

.bg-white-sm {
  background: white;

  @media screen and (min-width: 991px) {
    background: transparent;
  }
}

.c-hover {
  cursor: pointer;
}

.display-flex {
  display: flex;
  align-items: baseline;
}

.hide-scroll-bar {
  overflow: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
  }

  /* Optional: show position indicator in red */
}

.flex-lg {
  display: block !important;

  @media screen and (min-width: 991px) {
    display: flex !important;
  }
}

.card-no-padding-mobile {
  padding: 0.75rem 4px !important;

  @media screen and (min-width: 991px) {
    padding: 0.75rem 1.25rem !important;
  }
}

.card-no-padding {
  padding: 0.75rem 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

// fade-in
// this fade will be static (not moving like the FadeIn pkg)

@-moz-keyframes fadein {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  -webkit-animation: fadein 2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s;
  /* Firefox < 16 */
  -ms-animation: fadein 2s;
  /* Internet Explorer */
  -o-animation: fadein 2s;
  /* Opera < 12.1 */
  animation: fadein 2s;
}

// fade-out

@-moz-keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-out {
  -webkit-animation: fadeout 2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 2s;
  /* Firefox < 16 */
  -ms-animation: fadeout 2s;
  /* Internet Explorer */
  -o-animation: fadeout 2s;
  /* Opera < 12.1 */
  animation: fadeout 2s;
}

.ellipsis {
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100%);
}

.gap-1 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 0.75rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.25rem;
}

.gap-5 {
  gap: 2em;
}

.w-60 {
  width: 60%;
}

.w-md-60 {
  width: 60%;

  @media #{$navbreak-max} {
    width: 100%;
  }
}

.white-icon {
  svg {
    rect,
    stroke,
    path {
      fill: $white;
    }
  }
}

.break-word {
  word-wrap: break-word;
}
