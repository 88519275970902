.yes-no-prompt {
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_content {
    padding: 1.5rem 1rem 0;
    padding-top: 1.5rem;
    text-align: center;
    max-width: 380px;

    h1 {
      font-size: 1.3rem;
      font-weight: 600;
      color: $navy;
      margin-bottom: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    p {
      text-align: center;
      color: $navy;

      &:last-child {
        margin-bottom: 1.5rem;
      }
    }
  }

  &_footer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 0 1rem 1.5rem;

    .bt-navy {
      span {
        white-space: wrap !important;
      }
    }
  }

  &_footer-btn {
    width: 100% !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #6790a6 !important;
    color: white !important;
    background-color: #6790a6;
    height: 56px;
    font-size: 1.5rem;

    &:hover,
    &:focus {
      background-color: #286d9b;
    }

    &:active {
      background-color: #104466;
    }

    &[disabled] {
      background-color: #8db1c9;
    }
  }
}
