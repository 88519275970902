.variation-indicator {
  margin: 0 1.2rem;
  padding: 0 !important;

  &_wrapper {
    position: relative;
    height: 10px;
    background-color: #c6d5de;
    border-radius: 6px;
  }

  &_progress {
    background-color: #104466;
    height: 10px;
    border-radius: 6px;
  }

  &_msg {
    text-align: center;
    padding: 1rem 0;
    color: #134e75;
    font-size: 1rem;
    font-weight: 500;
  }
}
