.open-campaigns {
  &_itc-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    @media #{$navbreak} {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__message {
      font-size: pxToRem(15);

      &.failed {
        color: $red;
      }
    }

    &__error {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 !important;

      p {
        font-size: pxToRem(15);
        color: $red;
      }
    }
  }

  .custom.dots-menu_each-item_wrapper {
    &:hover {
      background-color: transparent !important;
    }
  }

  .itc.menu-option__content.disabled-option {
    padding: .4rem;
    max-width: 260px;

    &:hover {
      cursor: default;
    }

    h6 {
      color: rgba(79, 79, 79, 0.60) !important;
      font-size: $fs-18;
    }

    p {
      color: #D63C24;
      font-size: pxToRem(14);
      font-weight: normal;
    }
  }

  .dots-menu_wrapper {
    right: 36px;
  }
}
