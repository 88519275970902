.registration-page {
  & {
    background-color: #ffffff;

    @include media-breakpoint-down(md) {
      padding: 2.25rem 0;
    }
  }

  .image-col {
    @include media-breakpoint-up(lg) {
      width: 63.61%;
      background: gray no-repeat center / cover;
    }

    img {
      @include media-breakpoint-up(lg) {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .login-content {
    @include media-breakpoint-down(md) {
      //padding-top: 0;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      padding-top: 3rem;
      padding-bottom: 8rem;
    }

    @include media-breakpoint-up(xl) {
      //padding-top: 14.125rem;
    }
  }

  h1 {
    color: #2f2f2f;
    font-family: 'TTCommons', sans-serif;
    font-weight: 400;
    font-size: 1.6875rem;
    text-align: center;
  }

  h2 {
    color: #7f7e7e;
    font-family: 'TTCommons', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-bottom: 2.3125rem;
    }
  }

  form {
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      margin: 2rem auto 0;
    }
  }
}
