@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?669epu');
  src: url('fonts/icomoon.eot?669epu#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?669epu') format('truetype'),
    url('fonts/icomoon.woff?669epu') format('woff'),
    url('fonts/icomoon.svg?669epu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-package-builder:before {
  content: "\e918";
}

.icon-search:before {
  content: "\e917";
}

.icon-pencil:before {
  content: "\e916";
}

.icon-check-large:before {
  content: "\e915";
  color: #2d5171;
}

.icon-undo:before {
  content: "\e914";
  color: #2d5171;
}

.icon-notifications:before {
  content: "\e901";
}

.icon-dashboard:before {
  content: "\e902";
}

.icon-events:before {
  content: "\e903";
}

.icon-pie-chart:before {
  content: "\e904";
}

.icon-user:before {
  content: "\e905";
}

.icon-arrow-left:before {
  content: "\e906";
}

.icon-arrow-right:before {
  content: "\e907";
}

.icon-bar-chart:before {
  content: "\e908";
}

.icon-campaign:before {
  content: "\e909";
}

.icon-caret-left:before {
  content: "\e90a";
}

.icon-cash:before {
  content: "\e90b";
}

.icon-coin:before {
  content: "\e90c";
}

.icon-debitcard:before {
  content: "\e90d";
}

.icon-drawer:before {
  content: "\e90e";
}

.icon-fd:before {
  content: "\e90f";
}

.icon-bin:before {
  content: "\e910";
}

.icon-plus:before {
  content: "\e911";
}

.icon-simulation:before {
  content: "\e912";
}

.icon-cloud-upload:before {
  content: "\e913";
}

.icon-bell:before {
  content: "\e900";
}

.icon-location:before {
  content: "\e947";
}

.icon-clock:before {
  content: "\e94e";
}

.icon-printer:before {
  content: "\e954";
}

.icon-mobile:before {
  content: "\e958";
}

.icon-tv:before {
  content: "\e95b";
}

.icon-floppy-disk:before {
  content: "\e962";
}

.icon-users:before {
  content: "\e972";
}

.icon-lock:before {
  content: "\e98f";
}

.icon-cog:before {
  content: "\e994";
}

.icon-hammer:before {
  content: "\e996";
}

.icon-stats-bars:before {
  content: "\e99c";
}

.icon-fire:before {
  content: "\e9a9";
}

.icon-target:before {
  content: "\e9b3";
}

.icon-eye:before {
  content: "\e9ce";
}

.icon-point-up:before {
  content: "\ea03";
}

.icon-arrow-down2:before {
  content: "\ea3e";
}