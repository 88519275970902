.sentinel_results {
  margin-top: 1rem;
  position: relative;

  &--loading {
    opacity: 0.5;
  }

  &__empty-msg {
    color: #000;
    font-size: 1rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: .5rem;
  }

  .table {
    thead {
      background-color: $white;
      border-radius: 6px;
      margin-bottom: 0.5rem;

      th {
        border-bottom: 0;
        border-top: 0;
        color: $txt-header;
        font-weight: 500;
        text-transform: capitalize;

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          padding-left: 2rem;
        }
        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          padding-left: 2rem;
        }
      }
    }

    tbody:before {
      content: '@';
      display: block;
      line-height: 12px;
      text-indent: -99999px;
    }
  }

  &__aggregates {
    position: absolute;
    top: 24px;
    left: 20px;
    .btn-group {
      gap: 0;
    }
    button {
      width: 40px !important;
      height: 28px;
      min-height: 28px !important;
      padding: 6px 12px !important;
      background-color: $white !important;
      border: 1px solid #D8D8D8;

      svg path {
        fill: #3B3B3B !important;
      }

      &.active {
        background-color: #286d9b !important;
        border-color: #286d9b !important;
        svg path {
          fill: #fff !important;
        }
      }
    }

    .sigma {
      border-right: none;
      svg {
        width: 11px;
        height: 11px;
      }
    }
    .phi {
      border-left: none;
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }

  .column-header-with-aggregated-value {
    .aggregated-value-label {
      height: 28px;
      border-radius: 4px;
      background: #E1EBF2;
      color: $txt-header-2;
      margin-top: 26px;
      margin-bottom: 24px;
      padding-top: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__spacer {
    height: 0.3rem;
  }

  &__grid {
    background-color: transparent;
    border: none;

    &--with-aggregates {
      .rdg-cell {
        align-items: flex-end !important;
        padding-bottom: 10px;

        &:hover {
          &:before {
            top: 60% !important;
            bottom: 10% !important;
          }
        }
      }

      .rdg-header-row {
        .rdg-checkbox-label:first-of-type {
          top: 60px;
        }
      }
    }

    .rdg-checkbox-label {
      width: 24px;
      height: 16px;
      margin-top: 14px;
      margin-left: 18px;

      input:checked+.rdg-checkbox {
        outline: none;
        outline-offset: 0;
        background-color: $navy !important;
        &:before {
          content: '✓';
          position: absolute;
          color: $white;
          top: -13px;
          left: 2px;
          width: 8px;
          height: 8px;
          font-size: 13px;
        }
      }
    }

    &__row--customer:hover {
      cursor: pointer;
    }

    .rdg-checkbox {
      width: 16px;
      height: 16px;
      border: 1px solid #cccecf;
      border-radius: 4px;
      position: relative;
  
    }

    input:checked {
      border: none;
      background: transparent !important;
      display: none !important;
    }

    .rdg-row {
      &:hover {
        background-color: rgb(232, 240, 254);
      }

      // when aria-selected is true
      &[aria-selected=true] {
        background-color: $white;
      }

      .rdg-cell {
        margin-bottom: .3rem;
        color: #3B3B3B;
        font-size: 1rem;
        font-weight: 400;
        min-width: 48px;
        padding-right: .4rem;

        &:first-of-type {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        &:last-of-type {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }

    .rdg-header-row {
      .rdg-checkbox-label {  
        input:checked+.rdg-checkbox {
          &:before {
            top: -2px;
          }
        }
      }

      .rdg-cell {
        margin-bottom: 10px;
        background-color: $white;
        display: flex;
        align-items: center;

        color: #3B3B3B;
        font-size: 1rem;
        font-weight: 500;
        line-height: normal;

        &:first-of-type {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:last-of-type {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        &:hover {
          &:before {
            content: '';
            position: absolute;
            top: 25%;
            bottom: 25%;
            right: 0;
            width: 2px;
            border-radius: 6px;
            background-color: #3B3B3BBB;
            z-index: 1;
          }
        }
      }
    }

    .rdg-cell {
      border: none;
      box-shadow: none;
    }

    .rdg-cell[aria-selected=true] {
      outline: none;
    }
  }
}
