.language-switcher {
  text-align: center;
  &.dev {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 10px;
    border: 2px solid tomato;
  }
  .language-switcher__link {
    display: inline-block;
  }
}

.language-switcher--list {
  .language-switcher__option {
    margin-bottom: 12px;
  }
  .language-switcher__button {
    color: $txt-content;
    &--active {
      font-weight: 500;
      color: $navy;
    }
  }
}

.authentication-page {
  .language-switcher {
    position: fixed;
    @media #{$navbreak} {
      position: absolute;
    }
    left: 50%;
    transform: translateX(-50%);
    bottom: 15px;
  }
  .language-switcher__link {
    text-decoration: underline;
    padding-left: 4px;
    padding-right: 4px;
    color: $navy;
    display: block;
    margin-top: 1.5rem;
    color: $navy-active;
    font-family: 'TTCommons', sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    cursor: pointer;
  }
}
