.customer-ids {
  &_content {
    @media #{$navbreak} {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .fd-select__control {
    padding-top: 0.2rem;

    &--is-disabled {
      background-color: transparent !important;
    }
  }

  &_select-container {
    height: 220px;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $border-color;
    overflow: auto;
    margin-bottom: .5rem;

    @media #{$navbreak} {
      min-width: 800px;
    }

    @media screen and (max-height: 1200px) {
      height: 300px;
    }

    @media screen and (min-height: 1201px) {
      height: 320px;
    }
  }

  .modal_inner_header_close-btn_wrapper {
    right: 2em;

    @media #{$navbreak} {
      right: 2.5em;
    }
  }

  .error-msg {
    color: $red;
    font-size: $fs-14;
    margin-bottom: 1.4rem;

    @media #{$navbreak} {
      font-size: $fs-16;
    }

    &--small {
      font-size: pxToRem(15);
    }
  }

  .loading-container,
  .success-container {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    font-size: pxToRem(15);
    margin-top: -0.2rem;
  }
}
