.targeting.creative-composer-section-wrapper {
  display: flex;
  align-items: center;

  @media #{$navbreak} {
    align-items: flex-start;
  }

  .targeting-success, .targeting-loading {
    margin-top: 28px;
  }
}

.geo-targeting {
  &-field {
    display: flex;
    align-items: flex-end;
    gap: .6rem;
    flex: 1;

    @media #{$navbreak} {
      flex: initial;
    }

    .creative-composer-section-wrapper {
      flex: 1;
      margin: 0;
      @media #{$navbreak} {
        max-width: 55%;
        min-width: 400px;
      }
    }

    .form-group.input-cell.input-cell--input {
      margin-bottom: 0;

      input {
        height: 37px !important;
        padding: .6rem .75rem;
      }
    }
  }

  &__button {
    margin-bottom: 1px;
    height: 36px;

    @media #{$navbreak} {
      min-width: 132px;
    }
  }
}


.postal-codes {
  &_content {
    @media #{$navbreak} {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .fd-select__control {
    padding-top: 0.2rem;

    &--is-disabled {
      background-color: transparent !important;
    }
  }

  &_select-container {
    height: 200px;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $border-color;
    overflow: auto;
    margin-bottom: .5rem;

    @media #{$navbreak} {
      min-width: 800px;
    }

    @media screen and (max-height: 1200px) {
      height: 180px;
    }
  }

  .modal_inner_header_close-btn_wrapper {
    right: 2em;

    @media #{$navbreak} {
      right: 2.5em;
    }
  }

  .error-msg {
    color: $red;
    font-size: $fs-14;
    margin-bottom: 1.4rem;

    @media #{$navbreak} {
      font-size: $fs-16;
    }

    &--small {
      font-size: pxToRem(15);
    }
  }

  .loading-container,
  .success-container {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    font-size: pxToRem(15);
    margin-top: -0.2rem;
  }

  &__location-recency, &__country {
    margin-bottom: .6rem;
    box-shadow: none;

    &:hover {
      cursor: pointer;
    }

    .title.required::after {
      content: '' !important;
    }
  }
}

.postal-codes-summary {
  height: 220px;
  border-radius: 4px;
  border: 1px solid $border-color;
  background: #FFF;
  padding: 1rem;

  &__country {
    display: flex;
    gap: .5rem;
    align-items: center;
    margin-bottom: 1rem;

    @media #{$navbreak} {
      gap: 1rem;
    }

    &__container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid $border-color;
      border-radius: 4px;
      height: 37px;
      color: #504141;
      padding: .4rem;

      @media #{$navbreak} {
        padding: .8rem;
      }
    }
  }
}

.range-locations {
  &_content {
    min-height: 300px;
  }

  &__qualifier {
    height: 38px;
    @media #{$navbreak} {
      max-width: 138px;
    }

    .dropdown_input {
      height: 38px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &_location-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;

    @media #{$navbreak} {
      flex-direction: row;
    }

    .fd-select {
      flex: 1;
      width: 100%;

      &__control {
        border: 1px solid rgba(178, 178, 178, 0.5);
        border-radius: .25rem;
        &:hover {
          cursor: pointer;
          border-color: rgba(178, 178, 178, 0.5);;
        }
      }

      @media #{$navbreak} {
        width: unset;
      }
    }

    &__option {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      &:hover {
        cursor: pointer !important;
      }

      &--error {
        padding: 1rem;
        background-color: $white;
        flex-direction: column;
        align-items: flex-start;

        @media #{$navbreak} {
          flex-direction: row;
        }

        &:hover {
          background-color: $white;
        }

        .error-msg {
          color: $red;
          font-size: pxToRem(15px);
          flex: 1;

          @media #{$navbreak} {
            flex: initial;
          }
        }
      }

      &-right, &-left {
        color: #888585;
      }

      &-right {
        text-transform: capitalize;
      }
    }
  }

  &_summary {
    min-height: 200px;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    background-color: $white;
    padding: 1.2rem;

    &--empty {
      opacity: 0;
    }

    &__country {
      margin-bottom: 24px;
      &__name {
        color: #504141;
        font-weight: 400;
      }
    }

    &__location {
      display: flex;
      align-items: center;
      gap: .4rem;
      margin-bottom: 1rem;

      @media #{$md} {
        flex-direction: row;
        gap: 1rem;
      }

      @media #{$lg} {
        margin-bottom: 6px;
      }

      &__inner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 1;
        gap: .4rem;

        @media #{$md} {
          gap: .6rem;
        }

        @media #{$lg} {
          gap: 1rem;
        }
      }

      &__qualifier {
        min-width: 138px;
        width: unset;
      }

      &-name {
        flex: 1;
        flex-grow: 1;
        border-radius: $border-radius;
        border: 1px solid $border-color;
        padding: 12px;
        height: 38px;
        color: #504141;
        text-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
      }

      &-radius {
        position: relative;
        flex: 1 0 100%; // flex-grow, flex-shrink, flex-basis
        
        @media #{ $navbreak } {
          max-width: 150px;
        }

        input {
          height: 38px !important;
          padding: 12px;
          padding-left: 24px;
          color: #504141;
        }

        &__prefix, &__suffix {
          position: absolute;
          top: 30%;
          color: #504141;
        }

        &__prefix {
          left: 12px;
        }
      }

      &-remove {
        width: 1.2rem;
        height: 1.2rem;

        @media #{$navbreak} {
          width: 1rem;
          height: 1rem;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &_actions {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin-top: 1.2rem;
  }
}
