.falling-dots {
  display: flex;
  justify-content: center;

  &_container {
    width: 100%;
    padding-top: 4em;
  }

  &_inner {
    & > * {
      color: $txt-content;
    }
    width: 100%;
    padding-top: 2em;
    text-align: center;
  }

  // https://codepen.io/nzbin/pen/GGrXbp

  &.snippet {
    position: relative;
    background: transparent;
    padding: 2rem 5%;
    margin: 1.5rem 0;
    border-radius: 0.25rem;
  }

  &.examples .snippet:before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 5px;
    font-size: 2.75rem;
    border-radius: 0.25rem 0 0.25rem 0;
  }

  &.stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2rem 0;
    overflow: hidden;
    text-align: unset;
    width: 70px;
  }

  &.filter-contrast {
    text-align: unset;
    text-align: unset;
    filter: contrast(5);
    background-color: transparent;
  }

  &.dot-falling {
    text-align: left;
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #6790a6;
    color: #6790a6;
    box-shadow: 9999px 0 0 0 #6790a6;
    animation: dotFalling 1s infinite linear;
    animation-delay: 0.1s;
  }

  &.dot-falling::before,
  &.dot-falling::after {
    text-align: left;
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &.dot-falling::before {
    text-align: left;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #5e7988;
    color: #5e7988;
    animation: dotFallingBefore 1s infinite linear;
    animation-delay: 0s;
  }

  &.dot-falling::after {
    text-align: unset;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #5e7988;
    color: #5e7988;
    animation: dotFallingAfter 1s infinite linear;
    animation-delay: 0.2s;
  }

  @keyframes dotFalling {
    0% {
      box-shadow: 9999px -15px 0 0 rgba(31, 82, 116, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9999px 0 0 0 #5e7988;
    }
    100% {
      box-shadow: 9999px 15px 0 0 rgba(31, 82, 116, 0);
    }
  }

  @keyframes dotFallingBefore {
    0% {
      box-shadow: 9984px -15px 0 0 rgba(248, 252, 255, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 9984px 0 0 0 #5d7886;
    }
    100% {
      box-shadow: 9984px 15px 0 0 rgba(31, 82, 116, 0);
    }
  }

  @keyframes dotFallingAfter {
    0% {
      box-shadow: 10014px -15px 0 0 rgba(31, 82, 116, 0);
    }
    25%,
    50%,
    75% {
      box-shadow: 10014px 0 0 0 #5e7988;
    }
    100% {
      box-shadow: 10014px 15px 0 0 rgba(31, 82, 116, 0);
    }
  }
}
