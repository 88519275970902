#campaigns-container {
  .campaign {
    .campaign-header {
      &__expand {
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: pxToRem(4);
          width: 0;
          height: 0;
          border: pxToRem(8) solid #c4c4c4;
          border-top: none;
          border-left-color: transparent;
          border-right-color: transparent;
          transform: translate(0, -50%) rotate(180deg);
          transition: 0.3s ease-in-out;

          @media #{$navbreak} {
            right: pxToRem(22);
          }
        }
      }

      &.expanded {
        .campaign-header_toggle {
          &:hover,
          &:focus {
            text-decoration: none;
          }
        }

        &::after {
          transform: translate(0, -50%) rotate(0);
        }
      }
    }
  }
}
