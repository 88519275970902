.event-control-panel {
  width: 100%;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;

  &_container {
    display: flex;
    flex-direction: column;
    gap: 6rem;
    width: 100%;

    @media #{$lg-max} {
      &.active {
        display: block;
        position: absolute;
        display: inline-table;
        background: #fff;
        overflow: hidden;
        width: 100%;
        min-height: 100vh;
        top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2000;
        margin-left: 0;
        margin-right: 0;
        padding: 12px;
      }

      .event-filter-container {
        @media #{$sm-max} {
          flex-wrap: wrap;
          &>div {
            flex: 1 0 auto;
          }
          .event-control-panel_filter {
            flex-basis: 100%;
          }
        }
      }
    }

    @media #{$lg} {
      height: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      display: flex;

      &_dropdowns {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1.25rem;
      }
    }
  }

  @media #{$navbreak-max} {
    flex-direction: column;
  }

  &_add-event {
    position: relative;
    width: 140px;
    min-width: min-content;

    button {
      width: 100% !important;
    }

    &_dropdown {
      position: absolute;
      background-color: $white;
      right: 0;
      top: 45px;
      width: 100%;
      z-index: 1;
      border-radius: 4px;
      box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.2);
      border: 1px solid #dee2e6;

      &_item {
        position: relative;
        color: $txt-content;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 4px 12px;
        height: 42px;

        &:hover {
          background-color: #f5f6fa;
          color: $txt-content;
        }

        &:first-of-type {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }

        &:last-of-type {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  &_search {
    display: block;
    background: white;
    width: 100%;

    @media #{$lg} {
      max-width: 50%;
    }

    @media only screen and (min-width: 992px) {
      background: transparent;
    }

    @media #{$sm-max} {
      max-width: 100%;
    }
  }

  .backdrop {
    z-index: 1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    opacity: 0.5;

    @media #{$navbreak} {
      display: none;
    }
  }

  .close-mobile {
    position: absolute;
    z-index: 1;
    top: -40px;
    left: 50vw;
    cursor: pointer;
    transform: translate(-50%, 0);

    svg {
      filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.7));

      path {
        fill: white;
      }
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
      filter: contrast(110%);
      transform: translate(-50%, 0) scale(1.1);
      transition-duration: 0.2s;
    }

    @media #{$navbreak} {
      display: none;
    }
  }

  &_filter {
    position: relative;
    width: 140px;

    button {
      width: 100%;
    }

    &_dropdown {
      position: absolute;
      background-color: $white;
      right: 0;
      top: 45px;
      width: 270px;
      z-index: 1;
      border-radius: 4px;
      box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.2);
      border: 1px solid #dee2e6;

      @media #{$navbreak-max} {
        position: fixed;
        bottom: 50px;
        right: 0;
        left: 0;
        top: 28vh;
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
      }

      &_reset-btn {
        position: absolute;
        left: 2px;
        top: 2px;
        background: #7195ad;
        height: calc(100% - 4px);
        padding: 0em 0.5em 0em 0.5em !important;
        display: flex;
        border-radius: 4px;
        align-items: center;

        &:hover {
          span {
            opacity: 1;
            filter: contrast(110%);
            transform: scale(1.1);
            transition-duration: 0.2s;
          }
        }

        span {
          padding: 0 !important;

          svg {
            path {
              fill: white;
            }
          }
        }
      }

      &_wrapper {
        @media #{$navbreak-max} {
          overflow: auto;
          max-height: 100%;
        }
      }

      &_item {
        position: relative;
        color: #616060;
        margin-bottom: 4px;

        &:last-of-type {
          margin-bottom: 0px;
        }

        &.desktop {
          &:hover {
            background-color: #f5f6fa;
          }
        }

        &.side-selected {
          background-color: #f5f6fa;
        }

        &.down-selected {
          &:hover {
            background-color: transparent;
          }

          &:last-of-type {
            margin-bottom: 4px;
          }
        }

        &_background {
          &.down-selected {
            background-color: #f5f6fa;
            position: absolute;
            height: 100%;
            width: calc(100% - 8px);
            border-radius: 4px;
            margin: 0px 4px 4px 4px;
            z-index: -1;
          }

          &.side-selected {
            @media #{$navbreak-max} {
              background-color: #f5f6fa;
              position: absolute;
              height: calc(100% - 4px);
              width: calc(100% - 8px);
              border-radius: 4px;
              margin: 4px;
              z-index: -1;
            }
          }
        }

        &_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          padding: 4px 12px;
          height: 42px;

          i {
            font-size: 0.8rem;
            color: #616060;
            width: 11px;
          }
        }

        &_cancel {
          opacity: 0.9;
          overflow: hidden;
          transition-duration: 0.2s;

          svg {
            path {
              fill: #616060;
            }
          }

          &:hover {
            cursor: pointer;
            opacity: 1;
            filter: contrast(120%);
            transform: scale(1.2);
            transition-duration: 0.2s;
          }
        }

        &_expanded-side {
          padding: 1em;
          padding-top: 0em;

          @media #{$navbreak} {
            position: absolute;
            background-color: white;
            padding: 0em;
            z-index: 1;
            top: 0px;
            right: 275px;
            border-radius: 4px;
            box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.1);
            min-width: 100%;
            border: 1px solid #dee2e6;
          }
        }

        &_expanded-down {
          padding: 12px;
          padding-top: 0em;
        }

        &:first-of-type {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }

        &:last-of-type {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }

    @media #{$navbreak-max} {
      width: 100%;
    }
  }

  &_dropdown-menu {
    &_checkbox-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.3em;
    }

    &_custom-checkbox {
      position: relative;
      display: block;
      margin-bottom: 0px;
      line-height: 20px;

      & > * {
        border-radius: 4px;
      }

      &:hover {
        cursor: pointer;

        & span {
          color: #464646;
        }
      }

      & span {
        display: block;
        margin-left: 20px;
        padding-left: 12px;
        padding-top: 2px;
        line-height: 20px;
        text-align: left;
      }

      & span::before {
        content: '';
        display: block;
        position: absolute;
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
        top: 0;
        left: 0;
        background: white;
        border: 1px solid #d4d4d4;
        border-radius: 4px;
      }

      & span::after {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
      }

      & input[type='checkbox'] {
        opacity: 0;
        z-index: -1;
        position: absolute;
      }

      & input[type='checkbox']:checked + span::after {
        font-family: 'FontAwesome';
        padding-top: 1px;
        padding-left: 1px;
        border-radius: 4px;
        content: '✓';
        background: $orange;
        color: #fff;
      }
    }

    &_container {
      -webkit-padding-start: 0px;
      background: #fff;
      height: max-content;
      min-width: 100%;
      display: inline-table;
      min-height: 100%;

      @media only screen and (min-width: 992px) {
        min-height: unset;
        position: absolute;
        min-width: 300px;
        z-index: 40000000;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        left: 0;
        top: 47px;

        &.right {
          left: unset;
          right: 0;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    &_item {
      width: 100%;
      display: flex;
      padding: 4px;
    }

    &_buttons {
      list-style-type: none;

      &-wrapper {
        margin-top: 1em;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
      }

      &_cancel {
        display: block;
        font-weight: 500;
        padding: 8px 14px 6px 14px;
        border: 1px solid #aaaaaa;
        color: $txt-header-2;
      }

      &_apply {
        display: block;
        font-weight: 500;
        padding: 8px 14px 6px 14px;
        background: $orange;
        color: #fff;

        &:hover {
          color: #fff;
        }
      }
    }

    &_dates {
      padding: 1em;

      &_select_input_wrapper {
        padding: 10px 6px 8px 6px;
        border-radius: 6px;
        border: 1px solid #c1c5d3;
        display: flex;
        align-items: center;
        background-color: white;
      }

      &_select-option {
        padding: 8px 6px;
        transition-duration: 0.2s;

        &:hover {
          background: $background;
        }
      }

      &_select-menu {
        &_outer {
          position: relative;
          cursor: pointer;
          color: $black;
        }

        &-wrapper {
          background: #fff;
          display: none;
          position: absolute;

          &.open {
            width: 100%;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            z-index: 2000000000000;
            border: 1px solid #c1c5d3;
            border-top: none;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
          }
        }
      }

      &_input {
        margin-top: 6px;
        padding: 6px;
        padding-top: 10px;
        border-radius: 6px;
        border: 1px solid #c1c5d3;
        display: flex;

        & input {
          &:invalid {
            color: rgb(245, 102, 102);
          }

          & [type='date']::-webkit-inner-spin-button {
            display: none;
          }

          &[type='date']::-webkit-calendar-picker-indicator {
            display: none;
          }
        }
      }

      &_row {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        gap: 1em;

        @media only screen and (min-width: 600px) {
          flex-direction: row;
          justify-content: space-evenly;
        }

        &_calendar_left {
          height: 365px;
          background-color: white;
          border-radius: 4px;

          @media #{$navbreak-max} {
            padding: 1em;
          }
        }

        &_calendar_right {
          height: 365px;
          background-color: white;
          border-radius: 4px;

          @media #{$navbreak-max} {
            padding: 1em;
          }
        }
      }
    }

    &_location {
      &_container {
        background-color: white;
        padding: 1em;
        border-radius: 4px;
        color: black;
      }

      &_inner {
        margin-top: 0.2em;
        height: 290px;
        overflow-x: hidden;
      }

      &_row {
        display: flex;
        align-items: center;
        text-align: left;
        margin: 8px 0;
        line-height: 18px;

        &:first-of-type {
          margin-top: 0px;
        }

        &:last-of-type {
          margin-bottom: 0px;
        }
      }

      &_searchbar {
        &_wrapper {
          padding-bottom: 8px;
        }

        &_input {
          display: flex;
          padding: 8px 16px 4px 4px;
          border-radius: 4px;
          border: 1px solid #cfcfcf;

          & i {
            color: #bebebe;
          }
        }
      }
    }
  }

  @media #{$lg} {
    position: unset;
    display: flex !important;
    margin-left: auto;
    margin-top: 1rem;
    padding: 0;
  }

  @media screen and (max-width: 1320px) and (min-width: 1219px) {
    gap: 0.5rem;

    &_filters_container {
      gap: 0.5rem;

      &_dropdowns {
        gap: 0.75rem;

        .event-filter_toggle-btn {
          margin-left: 0.2rem;
        }
      }
    }
  }
}

.layout__wrapper-expanded {
  @media screen and (max-width: 1040px) {
    .event-filter {
      &_filters_container {
        gap: 1rem;

        &_dropdowns {
          gap: 0.25rem;
        }
      }
    }
  }
}
