.side-drawer {
  &_wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    padding: 18px 32px;
    overflow-y: auto;
    overflow-x: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 400px;
    max-width: 80vw;
    width: 100%;
    background-color: white;
    transform: translateX(100%);
    transition-duration: 0.3s;
    z-index: 999999;

    @media #{$xl} {
      max-width: 65vw;
    }

    &.no-padding {
      padding: 0px;
      overflow-y: hidden;
    }

    &.open {
      transition-duration: 0.4s;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      transform: translateX(0);
    }
  }

  &_close-btn {
    &_wrapper {
      position: absolute;
      top: pxToRem(32);
      right: 2rem;

      background: $border;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 34px;
      max-width: 40px;
      min-height: 34px;
      min-width: 40px;
      border-radius: 6px;
      transition-duration: 0.2s;
      opacity: 0.7;

      &:hover {
        // background: $red-50;
        transition-duration: 0.2s;
        opacity: 1;
      }
    }

    &_icon {
      color: $txt-content;
      width: 16px;
    }
  }

  &_content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;

    .card-title-default {
      color: $txt-header;
      font-size: pxToRem(18);
      line-height: 1.3125rem;
      font-weight: 600;
    }
  }
}
