.btn {
  .icon {
    display: inline-block;
    vertical-align: middle;
  }
  .icon-left {
    margin-right: pxToRem(12);
  }
  .icon-right {
    margin-left: pxToRem(12);
  }
}
