.recommendations-page {
  .event-details {
    background-color: #fff;

    .event-header-header {
      display: flex;
      justify-content: space-between;
      margin-left: 30px;
      margin-right: 8px;
      margin-top: 4px;
      color: $txt-content-2;
      font-weight: 700;
    }

    .event-header-title h2 {
      color: $black;
    }

    .event-header-card {
      min-height: fit-content;
      height: 100%;
      flex: 1;
    }

    .current-date-container {
      @include flex-parent($jc: center, $ai: center, $fd: column);
      width: pxToRem(81);
      height: pxToRem(81);
      border: 1px solid #bfc4d6;
      border-radius: 6px;

      .current-date {
        font-family: 'TTCommons-Bold', sans-serif;
        font-size: 1.875rem;
        color: #3b3b3b;
      }

      .current-month {
        font-family: 'TTCommons-Bold', sans-serif;
        font-size: 0.875rem;
        color: #959cb8;
      }
    }

    header {
      @media #{$navbreak} {
        margin-bottom: pxToRem(23);
      }
    }
  }

  &_error {
    margin: 0 32px;
  }

  .recommendation__footer {
    align-items: center;

    .recommendation__footer__border {
      border-top: 1px solid #e8e9ee;
      padding-top: 16px;
      margin-right: 16px;
      margin-left: 16px;
      width: 100%;
    }

    .custom-switch-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      padding-left: 0;

      @media #{$lg} {
        margin-bottom: 0;
        display: flex;
        justify-content: flex-end;
      }

      .switch-label {
        color: $txt-content;
        align-items: center;
        margin-left: pxToRem(10);

        @media #{$navbreak} {
          margin-top: pxToRem(2);
        }
      }
    }

    .credits {
      &__container {
        white-space: nowrap;

        @media #{$navbreak-max} {
          margin-top: pxToRem(5); //Just to verticaly center with a button
        }
      }

      &__value {
        font-size: pxToRem(23);
        line-height: pxToRem(26);
        color: #000;

        @media #{$navbreak} {
          font-size: pxToRem(35);
          line-height: pxToRem(40);
        }

        font-weight: 600;
      }

      &__label {
        margin-left: 0.5rem;
        color: $txt-content;
        font-size: 1rem;
        font-weight: 600;
      }
    }

    .set-up-date {
      color: $txt-gray-50;
    }

    .controls-container {
      border: 1px solid pink !important;
      text-align: right;
      min-width: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}

.recommendation__chevron {
  padding: 0.4rem;

  &:hover {
    cursor: pointer;
  }

  &::after {
    content: '';
    position: absolute;
    right: pxToRem(0);
    width: 0;
    height: 0;
    border: pxToRem(8) solid #c4c4c4;
    border-top: none;
    border-left-color: transparent;
    border-right-color: transparent;
    transform: translate(0, -50%) rotate(180deg);
    transition: 0.3s ease-in-out;
  }
}

.campaign.active {
  .recommendation__chevron::after {
    transform: translate(0, -50%) rotate(0);
  }
}

.recommendation__more-dots_wrapper {
  margin-right: 6px;
  border-right: 1px solid $border-color;
  padding-right: 12px;

  @media screen and (min-width: 991px) {
    margin-right: 6px;
  }

  &:hover {
    cursor: pointer;
  }
}

.recommendation__preview_ads {
  margin-right: 6px;
  padding-right: 14px;
  height: 24px;
  overflow: visible;
  border-right: 1px solid $border-color;
  display: flex;
  align-items: center;

  @media screen and (min-width: 991px) {
    margin-right: 4px;
  }
}

.event-header-goal {
  box-sizing: border-box;
  opacity: 0.5;
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 0.5em 8px;
  align-items: flex-end;
  color: $txt-content;
  border: 1px solid #bfc4d6;
  display: inline-flex;
  flex-wrap: nowrap;
  line-height: 17px;

  .custom-radio {
    height: 10px;
    width: 10px;
  }

  &:hover {
    cursor: pointer;
  }

  &_disabled {
    &:hover {
      cursor: initial;
    }
  }

  .title {
    color: $txt-content;
    margin: 0;
    padding: 0;
    line-height: 16px;
    margin-left: 6px;
    font-size: $fs-18;
    font-weight: 600;
    max-width: 105px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 0 1 content;
    overflow: hidden;
  }

  .expected-result {
    color: $txt-content;
    margin: 0;
    padding: 0;
    font-weight: 400;

    &-number {
      margin-left: auto;
      margin-top: 6px;
      flex: 1 0 content;
      box-sizing: border-box;
      min-width: 90px;
      max-width: 90px;
      font-weight: 400;
      width: 100%;
      font-size: $fs-16;
      line-height: 14px;
      text-align: right;
    }
  }

  &.active {
    box-shadow: 0px 1px 4px #00000055;
    opacity: 1;
    background: $orange;
    color: #fff;

    span,
    p,
    div {
      color: #fff;
    }

    .custom-radio__box {
      border: 1px solid $orange;
    }

    .custom-radio__second_box {
      background: white;
      border: 2px solid $orange;
    }

    .expected-result {
      transition-duration: 0.2s;

      &-number {
        line-height: 16px;
        font-size: $fs-18;
        font-weight: 700;
      }
    }
  }
}

.accordion {
  .card {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    @media #{$navbreak-max} {
      border: 1px solid #4c8fb8 !important;
    }

    @media #{$navbreak} {
      border: 1px solid #c1c5d3 !important;
    }
  }

  .card-header {
    border-bottom: none !important;
    background-color: transparent;
    padding-top: pxToRem(12);
    // position: relative;

    &.open {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
    }

    .btn-link {
      width: 100%;
      padding: 0;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &.active {
        &:hover,
        &:focus {
          text-decoration: none;
        }

        .recommendation__title {
          &::after {
            transform: translate(0, -50%) rotate(0);
          }
        }
      }
    }

    .btn {
      display: block;
    }

    @media #{$navbreak} {
      // As accordion header labels are currently absolute positioned this is needed to
      // make sure that recommendation's title does not go underneath the label/button
      .recommendation__title {
        width: 90%;
      }
    }

    .recommendation__title {
      width: 60%;
      display: flex;
      align-items: center;
      padding: 0;
      border: none;
      font-family: 'TTCommons', sans-serif;
      font-weight: 500;
      font-size: $fs-18;
      color: #000;
      text-align: left;

      .title {
        flex-grow: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .badge {
        position: absolute;
        cursor: pointer;
        top: 12px;
        right: 48px;
        font-size: $fs-18;
        font-weight: 500;
        color: #fff;
        min-width: 96px;
        padding-top: 8px;
        background: $orange;
        transition: 0.3s ease-in-out;

        &.active {
          background: $orange;
          right: unset;
          position: relative;
          left: 0;
        }

        &.ignored {
          background-color: $navy-60;
        }
      }
    }
  }

  .card-body {
    padding-top: 0;
  }

  .divider {
    height: 1px;
    margin: 0 auto 0.7rem;
    border: 1px;
    background-color: #e8e9ee;

    @media #{$navbreak} {
      margin: 0.5rem auto 1.5rem;
    }
  }
}

.creative-composer-section-wrapper {
  margin: 2em 0 1em 0;

  @media #{$navbreak} {
    margin: 0;
  }

  &__media p {
    padding-bottom: 1.2rem;
    border-bottom: 1px solid #e8e9ee;

    @media #{$navbreak} {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.creative-composer-controls-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.add-button-alignment-wrapper {
  display: flex;
  justify-content: flex-end;
}

.campaign-for-goals-budget-indicator {
  background: $background;
  padding: 12px 8px 8px 8px;
  color: $txt-header;
  border-radius: $border-radius;
}

.event-header {
  .row {
    width: 100%;
  }

  .autosave-error {
    font-size: 0.9rem;

    p {
      color: $red;
    }

    button {
      display: inline-block;
      background-color: transparent;
      border: none;
      color: $navy;
      text-decoration: underline;
      white-space: nowrap;

      &:hover {
        color: $navy-125;
      }
    }
  }

  .autosave-success {
    color: #818181;

    @media #{$navbreak-max} {
      margin-bottom: pxToRem(12);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    @media #{$navbreak} {
      margin-bottom: pxToRem(8);
    }
  }
}
