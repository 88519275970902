.searchable-dropdown {
  position: relative;
  
  &__searchbar-container {
    display: none;
    position: absolute;
    top: 42px;
    z-index: 2;
    width: 100%;
    flex-wrap: nowrap;
    padding: 6px;
    background-color: $white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid hsla(0, 0%, 69.8%, 0.5);
    border-bottom: none;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
  }

  .dropdown_menu_container {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    clip-path: inset(0px -10px -10px -10px); // hide top shadow
  }
  .dropdown_menu_container.open.bottom {
    top: 90px;
  }

  &--open {
    .searchable-dropdown__searchbar-container {
      display: flex;
    }
  }
}
