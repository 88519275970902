.recommendation__details__running {
  padding-left: 0 !important;
  &__section {
    margin-bottom: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__title {
      font-size: 1rem;
      color: $txt-content;
      margin-bottom: 1rem;
    }

    &__body {
      word-break: break-all;
      font-size: 1rem;
      color: black;
      margin-bottom: 1rem;
      border-bottom: 1px dashed black;
      display: inline-block;

      &.borderless {
        border-bottom: none;
      }
    }
  }

  &__media {
    margin-bottom: 1.8rem;

    .file-list {
      padding: 1rem;
      border-radius: 3px;
      border: 1px dashed $navy-75;
      &__itemBox {
        display: flex;
        margin-bottom: 10px;
      }

      &__thumb-container {
        padding: 4px;
        border: 1px solid #7195ad;
        border-radius: 2px;
        background-color: #fff;
        margin-right: 16px;

        &__thumb {
          width: 52px;
          height: 52px;
          background-position: center;
          background-size: cover;
          border-radius: 2px;
        }
      }
    }
  }

  &__section--targeting {
    .recommendation__details__running__section__body {
      display: flex;
      align-items: center;
      gap: 9px;
      flex-wrap: wrap;
    }
  }

  .postal-codes__country, .range-locations__country {
    margin-bottom: .8rem;
    &__container {
      padding: 12px 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: #F5F6FA;
      color: #504141;
      font-size: 1rem;
      height: 28px;

      &:hover {
        cursor: default;
      }
    }

    &__postal-codes__count, &__range-locations__count {
      text-transform: lowercase;
    }
  }
}
