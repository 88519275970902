.meta {
  &_dialog {
    border-radius: 8px;
    padding: 2rem 2.4rem;
    max-width: 440px;
    text-align: center;

    h3 {
      color: $navy-active;
      font-weight: 600;
      font-size: 1.5rem;
    }

    p {
      color: $navy;
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
    }
  }
}
