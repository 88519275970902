.event-row {
  &_outer-wrapper {
    width: calc(100%);
    transition-duration: 0;
    background-color: $white;

    &:first-of-type {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    &:last-of-type {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:hover {
      background: rgb(232, 240, 254);
      box-shadow:
        /* left "inner border" */ inset 3px 0 0 0 $navy-125;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      transition-duration: 0.2s;
    }

    @media screen and (min-width: 991px) {
      &.hover {
        &:hover {
          cursor: pointer;
        }
      }

      &.highlighted {
        box-shadow:
          /* left "inner border" */ inset 3px 0 0 0 $navy-125;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        transition-duration: 0.2s;
      }
    }
  }

  &_inner-wrapper {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 2rem;

    @media #{$navbreak-max} {
      padding: 20px;
    }

    @media screen and (min-width: 991px) {
      padding: 1.25em 2.25em;
    }

    @media screen and (min-width: 1400px) and (max-width: 1460px) {
      padding: 1.25em 1.25em;
    }
  }

  &_left-content {
    &_title_small {
      // takes element out of flex row
      position: absolute;
      margin-bottom: 60px;
      font-weight: 700;
      color: $txt-header-125;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: calc(80%);
      width: 100%;
      top: 20px;

      @media screen and (min-width: 550px) {
        display: none;
      }
    }

    &_wrapper {
      display: flex;
      position: relative;
      gap: 1em;
      width: calc(100%);

      @media screen and (min-width: 550px) {
        min-width: calc(80%);
        width: calc(80%);
        align-items: center;
      }

      @media screen and (min-width: 720px) {
        margin-right: 10px;

        &.dashboard {
          min-width: calc(55%);
        }
      }
    }

    &_img {
      border-radius: 50%;
      min-height: 50px;
      min-width: 50px;
      max-height: 50px;
      max-width: 50px;
      justify-content: center;
      align-items: center;
      background-color: rgba(168, 191, 203, 0.2);

      & i {
        font-size: 18px;
        color: $navy;
      }

      display: none;

      @media #{$navbreak} {
        display: flex;
      }
    }

    &_title-and-date {
      &_wrapper {
        margin-top: 1em;
        justify-content: center;
        align-items: baseline;
        width: calc(100%);
        max-width: calc(100%);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @media screen and (min-width: 550px) {
          margin-top: unset;
        }

        span {
          display: flex;
          align-items: baseline;
        }
      }

      &_title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: none;
        color: $txt-header;
        font-weight: 600;
        max-width: calc(100%);

        @media screen and (min-width: 550px) {
          display: block;
        }
      }

      &_date {
        &_wrapper {
          margin-top: 4px;
          display: flex;
          align-items: baseline;
          color: $txt-header-2;
          font-size: 16px;
          width: 100%;

          & i {
            font-size: 12px;
            color: $txt-header-2;
          }

          @media screen and (max-width: 840px) {
            flex-direction: column;
            margin-top: 8px;
          }
        }

        &_location {
          max-width: 100%;
          margin-right: 1em;

          @media #{$md-max} {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            word-break: break-word;
            white-space: break-spaces;
          }

          @media screen and (max-width: 681px) {
            max-width: calc(50vw);
          }

          img {
            width: 16px;
            height: 16px;
          }
        }

        &_time {
          white-space: nowrap;
          margin-right: 1em;

          img {
            width: 16px;
            height: 16px;
          }
        }

        &_active-campaign-indicator {
          &_wrapper {
            display: inline-flex;
            align-items: center;
            height: 100%;
            border-bottom-right-radius: $border-radius;
            // width: 120px;
            margin-top: 4px;

            @media screen and (min-width: 681px) {
              margin-top: unset;
            }

            &.hidden {
              display: none;
            }

            &.green {
              color: $green;

              svg {
                path {
                  stroke: $green;
                }
              }
            }

            &.red {
              color: $red;

              svg {
                path {
                  stroke: $red;
                }
              }
            }
          }

          &_text {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 100%;
            margin-left: 4px;
            font-size: pxToRem(16);
            font-weight: 500;
          }
        }
      }
    }
  }

  &_middle-content {
    &_wrapper {
      max-width: 300px;
      min-width: 125px;
      display: flex;

      @media screen and (min-width: 550px) {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }

  &_right-content {
    &_wrapper {
      display: none;
      width: 160px;

      @media screen and (min-width: 720px) {
        // margin-left: auto;
        max-width: 300px;
        // width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      @media screen and (min-width: 1200px) {
        // margin-left: auto;
        display: flex;
      }

      @media #{$lg-max} {
        button {
          padding: 0 !important;
          min-height: 2rem;
          min-width: 4rem;

          img {
            width: 18px !important;
          }

          i {
            font-size: 10px !important;
            margin-left: 12px !important;
          }
        }
      }
    }
  }

  &__tooltip {
    h5 {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      font-size: 1rem !important;
    }

    .tooltip-inner {
      padding: 8px 10px !important;
      padding-bottom: 3px !important;
      background-color: #f4f4f4 !important;
      border: 1px solid #e7624d;
    }

    &.infobox-tooltip-top {
      .arrow {
        &::after {
          border-color: #e7624d transparent transparent transparent;
        }
      }
    }

    &.infobox-tooltip-bottom {
      .arrow {
        &::before {
          border-color: transparent transparent #e7624d transparent;
        }
      }
    }
  }
}
