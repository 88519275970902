.notification-item {
  display: flex;
  align-items: center;
  position: relative;
  transition: background-color 0.3s ease;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 4px;

  @mixin topic {
    font-size: pxToRem(14);
    font-weight: 500;
    color: #fff;
    background-color: $navy-60;
    text-transform: capitalize;
    padding: 3px 8px 0;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
  }

  &_thumb {
    margin: 1.3rem;
    border-radius: 50%;
    min-height: 50px;
    min-width: 50px;
    max-height: 50px;
    max-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    background-color: rgba(168, 191, 203, 0.2);
    &.transparent {
      background-color: transparent;
    }

    & i {
      font-size: 18px;
      color: $navy;
    }

    @media #{$navbreak-max} {
      min-height: 34px;
      min-width: 34px;
      max-height: 34px;
      max-width: 34px;
      margin: 1.1rem 0.8rem;
      align-self: flex-start;

      & i {
        font-size: 14px;
      }
    }
  }

  &__content {
    padding: 1rem;
    padding-left: 0;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      word-wrap: break-word;
    }

    .notification-item__topic {
      display: none;
    }

    @media #{$navbreak} {
      .notification-item__topic {
        display: inline-block;
      }
    }

    @media #{$navbreak-max} {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      // padding: .8rem;
      padding-bottom: 0.4rem;

      .notification-item__meta {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
  }

  &__title {
    padding-right: 1rem;
    word-wrap: break-word;
    word-break: normal;
    color: $light-black;
    font-weight: 600;
    font-size: 1rem;
  }

  &__timestamp {
    display: flex;
    align-items: center;

    time {
      font-size: 1rem;
      color: #5c5c5c;
      padding-left: 4px;
      margin-top: 4px;
    }
  }

  &__topic {
    @include topic;
  }

  &__meta {
    min-width: 170px;
    .notification-item__topic {
      display: inline-block;
    }

    @media #{$navbreak} {
      .notification-item__topic {
        display: none;
      }
    }
  }

  .custom-check {
    margin-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    width: 24px;
    height: 24px;

    &__input,
    &__box {
      width: 24px;
      height: 24px;

      &::after {
        top: 0px;
        left: 5px;
      }
    }

    &__box::after {
      background-size: 14px auto;
    }
  }

  .read-check {
    position: relative;
    padding-top: 0px;
    margin-bottom: 0;
    margin-right: 1rem;
    height: 32px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;

      &.disabled {
        cursor: default;
      }
    }

    &__label {
      opacity: 0;
      margin-right: 3.2rem;
      color: $light-black;
      font-size: 1rem;
      margin-bottom: 0px;
    }

    &__input,
    &__box {
      position: absolute;
      top: 2px;
      right: 0;
      display: inline-block;
      width: 20px;
      height: 20px;

      &:hover {
        cursor: pointer;
      }
    }

    &__input {
      opacity: 0;
    }

    &__box {
      z-index: 2;
      border-radius: 50%;
      transition: 0.1s linear;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 26px;
      height: 26px;
      border: 1px solid transparent;

      &--inner {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: block;

        &--checked {
          background-color: $navy !important;
        }
      }
    }

    @media #{$navbreak-max} {
      margin-right: 0;

      &__label {
        margin-right: 1.4rem;
        opacity: 1;
      }

      &__box {
        margin-right: -0.5rem;
        top: 4px;
        width: 20px;
        height: 20px;
        background-color: rgb(232, 240, 254);
      }
    }
  }

  &:hover {
    background-color: rgb(232, 240, 254);
    cursor: pointer;

    box-shadow:
      /* top "inner border" */
      inset 0 0px 0 0 $background,
      /* left "inner border" */ inset 3px 0 0 0 $navy-125,
      /* bottom "inner border" */ inset 0 0px 0 0 #f5f6fa;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transition-duration: 0.2s;

    .read-check {
      &:not(.disabled) {
        .read-check__label {
          opacity: 1;
        }

        .read-check__box {
          background: rgba(242, 242, 242, 0.74);
          border-color: #e0e0e0;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  &.active {
    box-shadow:
      /* top "inner border" */
      inset 0 0px 0 0 $background,
      /* left "inner border" */ inset 3px 0 0 0 $navy-125,
      /* bottom "inner border" */ inset 0 0px 0 0 #f5f6fa;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transition-duration: 0.2s;
  }
}
