.events-insights-page {
  &_mobile-right-menu {
    cursor: pointer;

    svg {
      path {
        fill: $white;
      }
    }
  }

  .backdrop {
    z-index: 1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    opacity: 0.5;

    @media #{$navbreak} {
      display: none;
    }
  }

  .dropdown-list {
    position: fixed;
    background-color: $white;
    color: black;
    bottom: 50px;
    right: 0;
    left: 0;
    min-height: 150px;
    width: 100%;
    z-index: 1;
    border: 1px solid #dee2e6;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: none;
    box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.2);

    .close-mobile {
      position: absolute;
      z-index: 1;
      top: -40px;
      left: 50vw;
      cursor: pointer;
      transform: translate(-50%, 0);

      svg {
        filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.7));

        path {
          fill: white;
        }
      }

      &:hover {
        cursor: pointer;
        opacity: 1;
        filter: contrast(110%);
        transform: translate(-50%, 0) scale(1.1);
        transition-duration: 0.2s;
      }
    }

    &_item {
      color: $txt-content;
      position: relative;

      svg {
        path {
          fill: $txt-content;
        }
      }

      &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 4px 12px;
        height: 42px;
        gap: 1em;
        width: 100%;
        color: $txt-content;

        &_left {
          display: flex;
          gap: pxToRem(20);
        }
      }

      &_expanded {
        padding: 12px;
        padding-top: 0em;

        &_child {
          display: flex;
          padding: 0 42px;
          height: 42px;
          align-items: center;
          cursor: pointer;
        }
      }

      &_bg {
        &.selected {
          display: block;
          position: absolute;
          width: calc(100% - 8px);
          height: calc(100% - 4px);
          z-index: -1;
          background-color: #f5f6fa;
          margin: 0 4px;
          border-radius: 3px;
        }
      }
    }
  }

  .mobile-tabs-wrapper {
    @media #{$navbreak-max} {
      position: sticky;
      background-color: $white;
      // top: $mobile-top-bar-height;
      z-index: 10;
    }
  }

  .mobile-tabs {
    margin-top: 10px;

    .nav-item {
      &.disabled {
        a {
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }
  }

  .tab-pane-mobile {
    @media #{$navbreak-max} {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media #{$navbreak-max} {
    .card-default .card-header {
      padding-top: pxToRem(24);
      border-radius: 0;
      border-top: 1px solid $border;
    }
  }

  &_cta_row {
    margin-bottom: 1em;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    gap: 1em;

    @media #{$navbreak} {
      flex-direction: row;
      margin-bottom: 2em;
      margin-top: 1em;
    }
  }
}

.btn-back-to-events {
  color: $txt-content-2;

  img {
    display: inline-block;
  }

  margin-bottom: pxToRem(16);
}

.event-header {
  display: flex;
  justify-content: space-between;
  padding: 1em 0;

  .c-pointer {
    &:hover {
      cursor: pointer;
    }
  }

  &_recommendation-drawer-padding {
    padding: 32px 32px 0px 32px !important;
  }

  &_more-dots {
    &_wrapper {
      // margin-right: 45px;
      border-right: 1px solid $border-color;
      padding-right: 12px;
      margin-right: 1.5rem;

      @media screen and (min-width: 991px) {
        //   margin-right: 35px;
      }
    }
  }
}

.insights-header {
  padding: 0;
  margin-bottom: 0;

  @media #{$navbreak-max} {
    border-radius: 0;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  &.card .card-body {
    @media #{$navbreak-max} {
      padding-top: pxToRem(5);
      padding-bottom: 0;
    }

    @media #{$navbreak} {
      padding-left: pxToRem(25);
      padding-right: pxToRem(25);
    }

    @media screen and (min-width: 1199px) {
      padding-left: pxToRem(40);
      padding-right: pxToRem(40);
    }
  }

  .event-title {
    font-size: $fs-32;
    line-height: pxToRem(37);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__text {
    color: #818181;

    @media #{$navbreak-max} {
      margin-bottom: pxToRem(16);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      flex-direction: column;
    }

    @media #{$sm-max} {
      margin-bottom: pxToRem(24);
    }

    @media #{$navbreak} {
      margin-bottom: pxToRem(8);
    }

    span {
      display: flex;
      align-items: baseline;
    }

    svg {
      path {
        fill: #818181;
      }
    }
  }

  &__time {
    display: inline-block;
    width: pxToRem(95);
    padding: pxToRem(15) 0;
    text-align: center;
    font-size: $fs-14;
    font-weight: 600;
    line-height: pxToRem(16);
    color: #959cb8;
    border: 1px solid #bfc4d6;
    border-radius: pxToRem(6);
    margin-top: 1.5rem;

    &--lonely {
      margin-top: 4.5rem;
    }

    @media (max-width: 600px) {
      display: none;
    }

    strong {
      display: block;
      font-size: $fs-30;
      line-height: pxToRem(34);
      color: $txt-header;
      font-weight: 600;
    }
  }

  .bt {
    white-space: nowrap;
  }

  .events-benchmarks {
    flex-wrap: nowrap;

    @media #{$sm-max} {
      flex-wrap: wrap;

      .col:first-child {
        padding-right: 15px !important;
      }
    }

    @media #{$md-max} {
      .col:first-child {
        padding-right: 0;
      }
    }

    @media #{$ie-lg} {
      display: block !important;

      .prediction-box {
        width: 100% !important;
      }
    }

    @media #{$ie-xxl} {
      display: flex !important;

      .events-benchmarks__item {
        width: auto !important;
      }
    }
  }

  .prediction-box {
    // min-width: pxToRem(290);
    margin-bottom: pxToRem(30);

    @media #{$navbreak} {
      margin-bottom: pxToRem(22);
    }
  }
}

.card.card-default.card-ticket-categories {
  @media #{$navbreak-max} {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 0;

    .card-header {
      padding-bottom: pxToRem(36);
    }

    .card-title-default {
      margin-bottom: 0;
    }

    .card-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.ticket-categories-chart {
  &__bar {
    margin-bottom: 17px;
    max-height: 33px;
    font-weight: 600;
    position: relative;
  }

  &__line {
    display: inline-block;
    height: 33px;
    min-width: 50px;
    line-height: 38px;
    vertical-align: middle;
    padding: 0 12px;
    color: $white;
    border-radius: 8px;
    white-space: nowrap;
  }

  &__label {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    display: inline-block;
    padding: 4px 12px 0;
    white-space: nowrap;
    color: $txt-header;
    font-weight: 600;
    vertical-align: sub;

    &--overflow {
      color: $white;
      transform: translate(-100%, -50%);
    }
  }
}

.clusters {
  &__item {
    margin-bottom: pxToRem(24);

    &__title {
      font-size: 1rem;
      margin-bottom: 4px;
      font-weight: 400;
      color: $txt-header;
    }

    .progress-bar {
      .progress-bar__line {
        height: pxToRem(5);
        color: #4c8fb8;
      }
    }
  }
}
