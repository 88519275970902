.add-events-modal {
  &_wrapper {
    display: flex;
    flex-direction: column;
    min-width: 300px;

    @media screen and (min-width: 991px) {
      min-width: 650px;
    }
  }

  &_radio-buttons-wrap {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 991px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &_next-buttons {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 8px 2rem;
    transform: translate(-2em, 2em);
    background: white;
    width: calc(100% + 4em);
    z-index: 10000000;
    box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.5);
  }
}

.bulk-uploads {
  &_download-button {
    &_wrapper {
      margin-top: 2em;
      display: flex;
      align-items: baseline;
    }

    &_text {
      color: #fff;
    }
  }

  &_drop-zone {
    margin-top: 8px;
    border-radius: $border-radius;
    border: 1px dashed $border-event-search;
    padding: 1em;
    background-color: $white;
  }
}
