.dropdown {
  &_container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
  }

  &_input {
    padding: 9px 12px 7px 12px;
    color: $txt-header;
    display: flex;
    justify-content: space-between;
    background: #fff;
    align-items: center;
    border-radius: $border-radius;
    border: 1px solid rgba(178, 178, 178, 0.5);
    min-height: 37px;

    &.empty {
      color: $txt-content-2;

      .title {
        color: $txt-content-2;

        &.required {
          &:after {
            content: '*';
            color: red;
          }
        }
      }
    }

    &.disabled {
      border-color: #cdced0;
      color: #888585;
      background-color: #e1e8ec;

      &:hover {
        cursor: default;
      }
    }

    &.selected {
      color: $txt-header;
    }
  }

  &_menu {
    &_container {
      position: absolute;
      width: 100%;
      margin-top: 2px;
      top: 100%;
      left: 0;
      right: 0;
      background: #ffffff;
      box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      border: 1px solid hsla(0, 0%, 69.8%, 0.5);
      transition-duration: 0.5s;
      display: none;
      flex-direction: column;
      max-height: 200px;
      overflow-y: auto;

      @media screen and (min-width: 991px) {
        top: 40px;

        &.top {
          top: auto;
          bottom: 100%;
        }
      }

      &.top {
        top: auto;
        bottom: 120%;
      }

      &.open {
        // height: auto;
        display: flex;
        z-index: 9999;
      }
    }

    &_row {
      background: #fff;
      transition-duration: 0.2s;
      color: $txt-content;
      padding: 12px 12px 8px;

      &:hover {
        background: $background;
        cursor: pointer;
        color: $txt-header;
      }

      &.selected {
        background: $background;
      }
    }
  }
}
