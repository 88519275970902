.main-nav {
  position: fixed;
  display: none;

  width: pxToRem(240);
  min-width: pxToRem(240);
  max-width: pxToRem(240);

  &-collapsed {
    width: pxToRem(80);
    min-width: pxToRem(80);
    max-width: pxToRem(80);
  }

  &-expanded {
    .main-nav__link {
      padding-left: 1.9rem;
    }
  }

  @media #{$navbreak} {
    display: block;
  }

  top: 0;
  left: 0;
  z-index: 150;
  height: 100vh;
  background-color: $navy-active;
  text-align: center;

  ul {
    max-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__logo {
    margin-top: pxToRem(11);
    margin-bottom: pxToRem(34) !important;

    .main-nav__link {
      position: static;

      &:after {
        display: none;
      }

      &_expanded {
        text-align: left;
        padding-left: 0.8rem;
      }
    }
  }

  &__item {
    height: pxToRem(50);

    &:not(:last-child) {
      margin-bottom: pxToRem(24);
    }
  }

  &__link {
    color: $main-nav;
    display: flex;
    align-items: center;
    text-align: left;
    padding: pxToRem(16) 0;
    background-color: transparent;
    transition: 0.3s ease-in-out;
    transition-property: background-color;
    position: relative;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      height: pxToRem(20);
      width: 0;
      background-color: transparent;
      border-top-left-radius: pxToRem(12);
      border-bottom-left-radius: pxToRem(12);
      pointer-events: none;
      transition: 0.3s ease-in-out;
    }

    &__text {
      color: white;
      margin: 0;
      font-size: 1.1em;
      position: relative;
      top: 3px;
    }

    img {
      display: inline-block;
      justify-self: start;
    }

    i {
      display: inline-block;
      vertical-align: middle;
      font-size: $fs-20;

      &.icon-dashboard {
        font-size: pxToRem(22);
      }

      &.icon-pie-chart {
        font-size: pxToRem(22);
      }

      &.icon-events {
        font-size: $fs-18;
      }

      &.icon-simulation {
        font-size: pxToRem(23);
      }

      transition: 0.15 ease-in-out;
    }

    &:hover {
      color: $main-nav;
      background-color: #7195ad;

      &::after {
        background-color: lighten($color: #7195ad, $amount: 80);
      }
    }

    &--active {
      color: $navy;

      &:hover {
        color: $navy;
      }

      &::after {
        width: pxToRem(6);
        background-color: #7195ad;
      }
    }
  }

  .brand-logo {
    &:hover {
      color: $main-nav;
      background-color: transparent;
    }
  }
}
