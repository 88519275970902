.notificationDropdown {
  // * {border: 1px solid red;}
  position: relative;
  display: flex;
  justify-content: flex-end;
  @media #{$lg-max} {
    position: fixed;
    top: -40px;
    left: 0;
    right: 0;
  }
  &__body {
    position: absolute;
    top: 40px;
    right: -167px;
    z-index: 22;
    background-color: #fff;
    width: 450px;
    max-width: 100%;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: #3b3b3b;
    transform: scale(0);
    transition: transform 0.3s ease;
    transform-origin: top center;
    @media #{$lg-max} {
      width: 100%;
      right: 0;
      left: 0;
      top: 105px;
    }
  }
  &.active &__body {
    transform: scale(1);
    transition: transform 0.3s ease;
  }
  &__iconBox {
    color: #1f5274;
    position: relative;
    font-size: $fs-20;
    width: 50px;
    margin-right: -20px;
    margin-top: 3px;
    cursor: pointer;
    @media #{$lg-max} {
      margin-right: 45px;
      top: 65px;
    }
  }
  &__iconCounter {
    position: absolute;
    top: -7px;
    right: 2px;
    color: #fff;
    font-size: $fs-14;
    background-color: #f58562;
    position: absolute;
    border-radius: 40px;
    padding: 10px;
    z-index: 0;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    span {
      position: absolute;
      top: 55%;
      left: 50%;
      line-height: 0;
      transform: translate(-50%, -50%);
    }
  }
  &__headerBox {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 17px 30px;
    border-bottom: 1px solid #cacdd8;
  }
  &__header {
    display: block;
    text-align: left;
    font-weight: 500;
    font-size: $fs-18;
  }
  &__footer {
    padding: 11px;
    padding-bottom: 7px;
    &:hover {
      color: #616060;
    }
  }
  &__listBox {
    position: relative;
    &:after {
      display: block;
      content: '';
      height: 1px;
      width: calc(100% - 60px);
      margin-left: 30px;
      background-color: #ebebeb;
      margin-top: 20px;
    }
    &:last-child {
      &:after {
        width: 100%;
        margin-left: 0;
      }
    }
    .notificationDropdown__header {
      padding-left: 30px;
      padding-top: 20px;
    }
  }
  &__item {
    display: flex;
    padding: 8px 30px;
    text-align: left;
    font-size: $fs-16;
    line-height: pxToRem(18);
    color: #3b3b3b;
    &:hover {
      background-color: #f7f7fb;
    }
  }
  &__itemAvatar {
    border-radius: 50px;
    background-color: #d9efff;
    color: #1f5274;
    line-height: 0;
    padding: 14px;
    padding-top: 20px;
    margin-right: 16px;
  }
  &__itemAvatar--v1 {
    background-color: #d9efff;
    color: #1f5274;
  }
  &__itemAvatar--v2 {
    background-color: #dcdbff;
    color: #5553c0;
  }
  &__itemAvatar--v3 {
    background-color: #ffeded;
    color: #d04545;
  }
  &__itemTitle {
    display: inline-block;
  }
  &__itemCategory {
    display: inline-block;
    padding-left: 5px;
    font-weight: 600;
  }
  &__itemTime {
    display: inline-block;
    font-weight: 300;
    padding-left: 5px;
  }
  &__link {
    color: #1f5274;
    &:hover {
      color: #616060;
    }
  }
}
