.media-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  cursor: pointer;

  &_text {
    text-align: center;
    position: relative;
    top: 3px;

    span {
      color: blue;
    }
  }

  &.files-added {
    border-left: 1px dashed $border-event-search;

    &.mobile-view {
      width: 100%;
      border-left: none;
      border-bottom: 1px dashed $border-event-search;
    }
  }

  svg {
    path {
      fill: $navy-60;
    }
  }

  &.disabled {
    color: rgba(113, 149, 173, 0.3333333333);
    cursor: auto;

    span {
      color: rgba(113, 149, 173, 0.3333333333);
    }

    svg {
      path {
        fill: rgba(113, 149, 173, 0.3333333333);
      }
    }
  }
}

.media-uploads {
  border-radius: $border-radius;
  border: 1px dashed $border-event-search;
  padding: 2px;
  display: flex;
  flex-direction: row-reverse;
  &.media-uploads-error {
    border: 1px dashed #f26666;
  }

  &.mobile-view,
  &.readonly {
    flex-direction: column;
  }

  .media-files {
    display: flex;
    flex-wrap: wrap;
    padding-left: 1em;
    padding-right: 1em;
    align-items: flex-start;

    &.mobile-view {
      width: 100%;
    }

    .accepted-file {
      &__itemBox {
        border: 1px solid $navy-60;
        padding: 4px;
        border-radius: 2px;
        width: 60px;
        height: 70px;
        margin-top: 18px;
        margin-bottom: 18px;
        margin-right: 18px;
        position: relative;
        color: $navy-60;
        font-weight: 600;
        font-size: 15px;
        z-index: 150;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5em;

        svg {
          path {
            fill: $navy-60;
          }
        }

        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }

        &:hover .icons_replace-icon {
          opacity: 1;
          filter: contrast(120%);
          transform: scale(1.2);
          transition-duration: 0.2s;
        }

        &:last-child {
          margin-right: 0;
        }

        .icons {
          position: relative;
          width: 40px;
          padding-right: 3em;

          svg {
            path {
              fill: $txt-header-2;
            }
          }

          &_remove-icon {
            position: absolute;
            top: pxToRem(-10);
            left: pxToRem(50);

            background-color: white;
            border-radius: 50%;
            overflow: hidden;
            transition-duration: 0.2s;

            &:hover {
              cursor: pointer;
              opacity: 1;
              filter: contrast(120%);
              transform: scale(1.2);
              transition-duration: 0.2s;
            }
          }

          &_replace-icon {
            opacity: 0;
            position: absolute;
            top: 34%;
            left: 34%;
            height: 20px;
            width: 20px;
            background-color: $navy-60;
            border-radius: 50%;
            overflow: hidden;
            transition-duration: 0.2s;

            svg {
              margin: 2px 4px;

              path {
                fill: white;
              }
            }
          }

          &_error-icon {
            svg {
              path {
                fill: $orange;
              }
            }
          }
        }
      }

      &__thumb {
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        aspect-ratio: 0.8;

        video {
          height: 100%;
          width: 100%;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &_preview {
    width: 80vw;
    min-height: 90vh;
    padding: 1em;

    @media #{$sm-max} {
      width: unset;
      min-height: unset;
    }

    &_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1em;
    }

    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1em;

      &_title {
        line-height: 25px;
        font-weight: 500;
        font-size: 22px;
        margin-bottom: 0.25em;
        overflow-wrap: anywhere;
        white-space: wrap;
      }

      &_close-btn {
        background: #e8e9ee;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition-duration: 0.2s;
        opacity: 0.7;
        padding: 5px;
        max-height: 34px;
        max-width: 40px;
        min-height: 34px;
        min-width: 40px;
        border-radius: 6px;

        &:hover {
          opacity: 1;
          transition-duration: 0.2s;
        }

        &_icon {
          color: $txt-content;
          width: 16px;
        }
      }
    }

    &_image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 12em);

      img,
      video {
        object-position: center;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &_navigation {
      display: flex;
      justify-content: center;
      background-color: $white;

      svg {
        cursor: pointer;
        width: 25px;
        height: 25px;

        path {
          fill: $arrow;
        }
      }

      .disabled {
        cursor: not-allowed;
        opacity: 0.2;
      }

      .left {
        margin-right: 1em;
      }

      .right {
        margin-left: 1em;
      }
    }
  }
}
