.input-cell--date input {
  @extend .form-control;
}

.input-cell--date input,
.form-control.s-40 {
  padding-top: 0.9rem;
  height: pxToRem(40);
}

.input-cell--select .input-select__control {
  padding-top: 0.9rem;
  min-height: pxToRem(40);
}

.form-control.s-50 {
  padding-top: 0.9rem;
  height: pxToRem(50);
}

.form-control,
.input-cell--date input,
.input-cell--select .input-select__control {
  font-family: $font-family;
  box-shadow: none;
  width: 100%;

  background-color: #ffffff;
  color: #504141;
  border: 1px solid rgba(178, 178, 178, 0.5);
  border-radius: 4px;

  @include placeholder() {
    color: #888585;
  }

  &:required {
    &::placeholder {
      &::before {
        content: '*';
        color: #d15c37;
      }
    }
  }

  &:hover {
    border-color: rgba(178, 178, 178, 0.5);
  }

  // &:invalid,
  &--error {
    border-color: #db3737 !important;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.3);
  }

  &[required] {
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0);
    // border-color: rgba(19, 124, 189, 0.5) !important;
  }

  &--is-focused,
  &:focus {
    border-color: #137cbd !important;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.3);
  }

  &[disabled] {
    border-color: #cdced0;
    color: #888585;
    background-color: #e1e8ec;
  }
}

.input-cell {
  position: relative;

  &--showMax,
  &--showSpinner {
    input {
      padding-right: pxToRem(50);
    }

    input,
    textarea {
      &:focus + .input-cell__max {
        color: #137cbd;

        &--warning {
          color: $orange;
        }
      }
    }
  }

  .input-cell_info {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 5;
    font-size: pxToRem(12);
    line-height: pxToRem(14);
    color: #a8a2a2;
    display: flex;
    gap: 4px;
    align-items: center;

    &__valid,
    &__invalid {
      display: none;
      width: 22px;
      height: 22px;
      margin: 0;
      margin-left: 0.5rem;
      margin-right: 0.1rem;

      &.visible {
        display: inline;
      }
    }
  }

  .input-cell__spinner {
    position: absolute;
    top: 5px;
    bottom: 5px;
    right: 10px;
    z-index: 5;
    font-size: pxToRem(12);
    line-height: pxToRem(14);
    color: #a8a2a2;
  }

  &--textarea {
    &::after {
      content: '';
      position: absolute;
      bottom: 2px;
      right: 2px;
      width: 20px;
      height: 20px;
      z-index: 1;
      background-color: white;
      pointer-events: none;
    }
  }

  textarea {
    min-height: 80px;
    resize: vertical;

    &::webkit-resizer {
      display: none;
    }

    &::resizer {
      display: none;
    }
  }

  &--date {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
}

.input-cell--date {
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled {
    cursor: default;
    color: #ccc;
  }

  .react-datepicker-popper {
    .react-datepicker__triangle {
      border-bottom-color: white !important;

      &::before {
        border-top-color: $border-color !important;
        border-bottom-color: $border-color !important;
      }
    }

    .react-datepicker {
      font-family: $font-family;
      padding: 6px 5px 12px;
      border-color: $border-color;
      border-radius: 3px;
      box-shadow: 0 12px 10px -10px rgba(black, 0.3);

      .react-datepicker__header {
        padding-top: 0;
        background-color: white;
        border-bottom: 1px dashed $border-color !important;
      }
    }

    .react-datepicker__time-list-item {
      &--selected {
        background-color: #137cbd !important;
      }
    }

    .react-datepicker__navigation {
      top: 15px;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;

      &.react-datepicker__navigation--previous {
        left: 15px;
        border-right: 8px solid #137cbd;
      }

      &.react-datepicker__navigation--next {
        right: 15px;
        border-left: 8px solid #137cbd;
      }
    }

    .react-datepicker__current-month {
      text-transform: capitalize;
      font-size: 15px;
      margin-top: 5px;
      margin-bottom: 8px;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }

    .react-datepicker__day-names {
      .react-datepicker__day-name {
        font-size: 14px !important;
        color: rgba(32, 32, 32, 0.5);
        font-weight: 500;
      }
    }

    .react-datepicker__month {
      .react-datepicker__day {
        &.react-datepicker__day--disabled {
          cursor: default;
          color: #ccc;
        }

        border-radius: 4px;
        font-size: 0.9rem;
        color: $black;
        background-color: transparent;
        font-weight: 500;
      }

      .react-datepicker__day--sat,
      .react-datepicker__day--sun {
        color: rgba(32, 32, 32, 0.5);
      }

      .react-datepicker__day--outside-month {
        opacity: 0.4;
        font-size: 10px;
      }

      .react-datepicker__day--selected {
        color: white !important;
        background-color: #137cbd !important;
        border: none;
      }
    }
  }

  .react-datepicker__time-box {
    border-radius: 0;
  }

  .react-datepicker-popper {
    &[data-placement='top-start'] {
      transform-origin: 50% 100%;
    }

    &[data-placement='bottom-start'] {
      transform-origin: 50% 0%;
    }
  }

  .react-datepicker__time-list {
    li {
      border-radius: 0 !important;
    }
  }
}

.input-cell--select {
  .input-select__control {
    padding-top: 3px;
  }

  .input-select__menu-list {
    padding: 0;
    border-color: 1px solid $border;
  }

  &--required {
    .input-select__control:not(.input-select__control--is-focused) {
      border-color: rgba(19, 124, 189, 0.5) !important;
    }
  }

  .input-select__option {
    border-radius: 5px;
    transition: 0.15s;

    &--is-focused {
      background-color: $background !important;
    }

    &--is-selected {
      background-color: #137cbd !important;
    }
  }

  .input-select__multi-value {
    background-color: $background !important;

    &__remove {
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $background !important;
        color: #f17852;
      }

      &:active {
        background-color: $background !important;
        color: #d15c37;
      }
    }
  }
}
