.session-timer {
  &.card {
    .card-header {
      border: none;
    }
  }

  &_time {
    color: $navy;
    font-weight: 600;
    white-space: nowrap;

    &.fadein {
      animation: fadein 3s ease-in-out;
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
