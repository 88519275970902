.uploads {
  color: $txt-header;
}

.upload-dropzone {
  margin-top: 8px;
  border-radius: $border-radius;
  border: 1px dashed $border-event-search;
  padding: 1em;
  background-color: $white;
  cursor: pointer;

  &_text {
    position: relative;
    top: 3px;
  }

  &.files-added {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  span {
    color: blue;
  }

  svg {
    path {
      fill: $navy-60;
    }
  }
}

.files {
  border-radius: $border-radius;
  border: 1px dashed $border-event-search;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: none;
  padding: 2px;

  .accepted-file {
    background-color: white;
    margin-bottom: 3px;

    &:first-child {
      border-top-right-radius: $border-radius;
      border-top-left-radius: $border-radius;
    }

    &:last-child {
      margin: 0;
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media #{$navbreak-max} {
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5em;
      }
    }

    &_preview {
      display: flex;
      align-items: center;
      flex: 0 0 55%;
      max-width: 55%;
      padding-left: 15px;
      padding-right: 15px;

      @media #{$navbreak-max} {
        max-width: 100%;
      }

      .icons {
        position: relative;
        width: 40px;
        padding-right: 3em;

        svg {
          path {
            fill: $txt-header-2;
          }
        }

        &_remove-icon {
          position: absolute;
          top: pxToRem(-10);
          left: pxToRem(20);

          opacity: 0.9;
          overflow: hidden;
          transition-duration: 0.2s;

          &:hover {
            cursor: pointer;
            opacity: 1;
            filter: contrast(120%);
            transform: scale(1.2);
            transition-duration: 0.2s;
          }
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        text-align: left;
        align-self: center;
        min-width: 87%;
      }
    }

    &_dropdown {
      text-align: left;
      flex: 0 0 44%;
      max-width: 45%;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 1.5em;

      @media screen {
        max-width: 100%;
      }
    }

    &_status {
      display: flex;
      align-items: center;

      .circular-progress {
        position: relative;
        font-size: $fs-14;
        color: $txt-content-2;
        gap: 0.25rem;
        top: 2px;

        svg {
          width: 24px !important;
        }
      }
    }
  }

  .success {
    svg {
      path {
        fill: #219653;
      }
    }
  }

  .warning {
    svg {
      path {
        fill: red;
      }
    }
  }
}
