.tabs-component {
  padding: 18px 32px;

  @media #{$navbreak-max} {
    padding: 0;
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
  }

  &_pills {
    &_wrapper {
      display: flex;
      justify-content: space-evenly;
      // padding-bottom: 4px;
      border-bottom: 1px solid $border-color;
    }

    &_each {
      transition: border 0.2s;
      color: $txt-content-2;
      border-bottom: 2px solid transparent;
      font-size: $fs-14;
      padding: 2px 2px 8px 2px;

      @media #{$navbreak} {
        font-size: $fs-18;
      }

      &:hover {
        cursor: pointer;
      }

      &.disabled {
        cursor: not-allowed;
      }

      &.active {
        color: $txt-content;
        font-weight: 600;
        border-bottom: 2px solid $border-event-search;
      }
    }
  }

  &_main-content {
    width: 100%;

    &_section {
      display: flex;
      flex-direction: column;
      gap: 1em;
      border: 1px solid $input-border;
      border-radius: 6px;
      padding: 1.5em;
      padding-bottom: 1em;
      margin-bottom: 2em;

      @media #{$navbreak-max} {
        margin: 1.25em;
        margin-bottom: 2em;

        &:last-of-type {
          margin-bottom: 60px;
        }
      }
    }
  }
}
