.messageDetalis {
  font-family: 'TTCommons', sans-serif;
  font-size: 1rem;
  padding-bottom: 2rem;
  position: relative;

  margin: 0 2rem;

  &__header {
    border-bottom: 1px solid #94c7e7;
    position: sticky;
    top: 0;
    z-index: 5;
    padding: 1rem 0;
    color: $txt-header-2;
  }

  &__title {
    color: $light-black;
    font-weight: 600;
    padding: 1rem 0;
    border-bottom: 1px solid #94c7e7;
  }

  &__body {
    font-size: 1rem;
    line-height: 1.125rem;
    color: $txt-content-2;
    padding: 2rem 0;
  }

  &__nav {
    text-align: center;
    .bt {
      margin: 0.5rem;
      width: 100%;
      max-width: 250px;
    }
  }
}
