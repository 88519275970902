.custom-radio {
  cursor: pointer;
  position: relative;
  padding-left: pxToRem(16);
  &__input,
  &__box {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 16px;
    height: 16px;
  }
  &__input {
    opacity: 0;
    &:checked {
      + .custom-radio__box {
        background-color: $orange;
        border-color: $orange;
        background-image: none;
        box-shadow: inset 0px -1px 1px rgba(16, 22, 26, 0.2);

        &::after {
          opacity: 1;
        }
      }

      + .custom-radio__box .custom-radio__second_box {
        opacity: 1;
      }
    }
    &[disabled]:not(:checked) {
      + .custom-radio__box {
        border-color: #cdced0;
        color: #888585;
        background-color: #e1e8ec;
        background-image: linear-gradient(180deg, transparent 0%, transparent 100%);
        &::after {
          background-color: #888585;
        }
      }
    }
  }
  &__box {
    z-index: 2;
    background-image: linear-gradient(180deg, #ffffff 0%, #fbfafa 100%);
    border: 1px solid #cccecf;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.05s ease-in-out;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 13px;
      height: 13px;
      border-radius: 50%;
      content: '';
      background-color: white;
      opacity: 0;
      transition: 0.05 ease-in-out;
    }
  }
  &__second_box {
    position: absolute;
    height: 8px;
    width: 8px;
    z-index: 3;
    background-image: linear-gradient(180deg, $orange 0%, $orange 100%);
    border: 1px solid $orange;
    border-radius: 50%;
    transition: 0.05s ease-in-out;
    opacity: 0;
  }
  &__label {
    color: #504141;
  }
}

// styles for bootstrap's custom radio button
.custom-radio.custom-control {
  min-height: 1rem;
  margin-left: 0 !important;
  padding-left: 0;
  .custom-control-label {
    margin-left: .5rem;
    user-select: none;
    &:hover {
      cursor: pointer;
    }

    &::before {
      content: none;
    }

    &::after {
      content: none;
    }
  }

  .custom-control-input {
    appearance: none;
    width: 14px;
    height: 14px;
    background: $white !important;
    border: 1px solid $navy;
    outline: transparent solid 1px;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    position: relative;
    opacity: 1;
    box-sizing: border-box;

    &:checked {
      background: $navy !important;
      border: 2px solid $white;
      outline: $navy solid 1px;
      box-shadow: none;
    }

    &:focus {
      ~ .custom-control-label::before {
        box-shadow: none;
      }
    }
  }
}
