.inline-text_copy,
.inline-text_input {
  font: inherit;
  color: inherit;
  text-align: inherit;
  padding: 0;
  background: none;
  border: none;
  margin-bottom: 0.5rem;
}

.inline-text_copy--editable {
  cursor: pointer;
  border-bottom: 1px dashed #666666;
}

.inline-text_copy--active {
  font-weight: 500;
  color: #504141;
  line-height: pxToRem(18);

  a {
    color: $navy;
  }
}

.inline-text_copy--hidden,
.inline-text_input--hidden {
  display: none;
}

.inline-text_input--active {
  border-bottom: none;
  text-align: left;
}

.recommendation__details__published__section {
  margin-bottom: pxToRem(30);

  &__title {
    color: $txt-content;
  }
  p {
    color: #504141;
    font-weight: 500;
  }
}

.recommendation__details__published__section__button {
  min-width: 5rem;

  i {
    margin-top: -3px;
  }
}
