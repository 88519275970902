.fd-datepicker {
  position: relative;
  @extend .input-cell--date;

  .react-datepicker-wrapper, .react-datepicker__input-container {
    background-color: transparent;
  }

  input {
    z-index: 1;
    position: relative;
    background-color: transparent !important;

    &:disabled {
      background-color: #e1e8ec !important;
    }
  }

  &__icon {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 15px;
    height: 15px;
    z-index: 0;
  }

  input {
    border: 1px solid rgba(178, 178, 178, 0.5);
  }
}

.react-datepicker {
  border-color: $border;
}
