.events-insights-page {
  &_header_status_menu {
    text-align: left;
    width: 100%;

    @media screen and (min-width: 991px) {
      width: 265px;
      text-align: end;
    }
  }

  .event-title {
    font-size: $fs-32;

    @media #{$navbreak-max} {
      font-size: $fs-30;
      font-weight: 600;
      line-height: pxToRem(34);
      margin-bottom: pxToRem(14);
      color: #1f5a7e;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .no-results {
    opacity: 0.4;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  &_page_stats {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 992px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 2em;
    }
  }

  &_dash_small_card {
    width: 100%;
    gap: 1em;

    &.card.card-default .card-header {
      @media #{$navbreak-max} {
        padding-top: 0;
      }
    }

    @media only screen and (min-width: 992px) {
      min-width: 280px;
      width: 50%;

      &.card.card-default {
        padding-bottom: 2em;
      }
    }
  }

  &__status_menu {
    line-height: 18px;
    color: #616060;
    // min-width: 190px;
    width: 100%;
    min-width: 240px;
    transition-duration: 0.2s;
    position: relative;

    @media screen and (min-width: 992px) {
      display: block;
      width: 265px;
      margin-bottom: 1em;
    }

    @media #{$sm-max} {
      font-size: 0.9em;
    }

    &_clear {
      transition-duration: 0.2s;

      &:hover {
        cursor: pointer;
        opacity: 1;
        filter: contrast(120%);
        transform: scale(1.2);
        transition-duration: 0.2s;
      }

      svg path {
        fill: #c4c4c4;
      }
    }

    &_container {
      border: 1px solid #bfc4d6;
      border-radius: 4px;
      width: 100%;
      display: flex;
      align-items: center;
      min-height: 37px;
      text-align: center;
      // trbl
      padding: 9px 12px 7px 12px;

      &.orange > span {
        font-weight: 700;
        font-size: 16px;
        color: #f58562;
        padding-top: 1px;

        svg {
          path {
            fill: #f58562;
          }
        }
      }

      &:hover {
        cursor: pointer;
      }

      @media #{$sm-max} {
        padding: 12px 12px 10px 12px;
        height: 36px;

        &.orange > span {
          font-size: 16px;
        }
      }
    }

    &_icon {
      &.orange {
        color: #f58562;

        svg {
          path,
          stroke,
          rect {
            fill: #f58562;
          }
        }
      }
    }

    &_selected_value {
      border-radius: 4px;
      line-height: 18px;
    }

    &_arrow {
      width: 0px;
      height: 0px;
      margin-top: -2px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid $arrow;

      &.red {
        border-top: 7px solid #f12929;
      }

      &.green {
        border-top: 7px solid #60c640;
      }
    }

    &_dropdown-container {
      position: absolute;
      display: none;
      z-index: 2000;
      min-width: 100%;
      right: 0;
      top: 100%;
      z-index: 1299999999;
      background: #fff;
      border: 1px solid #bfc4d6;
      border-radius: 4px;
      margin-top: 2px;

      // z-index: 200;
      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      @media screen and (min-width: 991px) {
        max-width: 180px;
      }

      &.open {
        background: #fff;
        display: block;
      }
    }

    &_dropdown-item {
      background: transparent;
      display: flex;
      width: 100%;
      padding: 10px 8px 6px 16px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      @media #{$sm-max} {
        padding: 10px 8px 6px 20px;
      }

      & span {
        text-align: left;
      }

      &.selected {
        background-color: $background;
      }

      & img {
        margin-left: 12px;
        margin-right: auto;
      }

      &:hover {
        cursor: pointer;
        background: rgba(241, 242, 248, 1);

        &.events-insights-page__status_menu_dropdown-item img {
          fill: rgba(241, 242, 248, 1);
        }

        &.loading {
          background: none;
        }
      }
    }

    &_spinner-container {
      left: 0;
      right: 0;
      top: 0;
      width: 100px;
      height: 100%;
      padding-top: 40px;
      width: 100%;
      margin: auto;
      background: rgba(241, 242, 248, 0.5);
    }

    &_black-bg {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.2);
      z-index: 190000;
    }
  }

  &_statistics {
    &_container {
      width: 100%;
      padding-bottom: 2em;
      display: flex;
      flex-direction: column;

      &.lg {
        min-height: 801px !important;
        height: 801px !important;
      }

      @media screen and (min-width: 992px) {
        padding-bottom: 0;
        min-height: 374px;
        min-width: 320px;
      }

      @media screen and (min-width: 1050px) {
        min-width: 380px;
      }
    }
  }

  &_statistics_row {
    &_wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 1.5em;
    }

    &_icon {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(168, 191, 203, 0.2);
      transition: 0.3s ease-in-out;
      max-width: 50px;
      max-height: 50px;
      min-width: 50px;
      min-height: 50px;

      & i {
        color: $navy-active;
      }

      &:hover {
        color: darken($navy-active, 5);
        background-color: rgba(168, 191, 203, 0.3);
      }

      &.red {
        background-color: rgba(242, 161, 147, 0.2);

        & i {
          color: $red;
        }

        &:hover {
          color: darken($red, 5);
          background-color: rgba(242, 161, 147, 0.3);
        }
      }
    }

    &_text {
      &_wrapper {
        padding-top: 6px;
        margin-left: 18px;
      }

      &_value {
        font-size: $fs-20;
        margin-top: 0px;
      }

      &_title {
        margin-top: 2px;
      }
    }
  }
}
