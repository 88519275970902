.authentication-page {
  .page-header {
    z-index: 1;
    margin-bottom: 80px;
    position: relative;

    @include media-breakpoint-down(md) {
      position: relative;
    }

    @include media-breakpoint-up(lg) {
      @include absolute(top 0 left 0);
      width: 100%;
    }

    .btn-back {
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 14px;
        padding: 0 1.25rem;
      }

      @include media-breakpoint-up(lg) {
        @include flex-parent($jc: center, $ai: center);
        width: 38px;
        height: 38px;
        margin: 1.875rem 0 0 1.25rem;
        box-shadow: 0 5px 11px rgba(210, 210, 210, 0.32);
        border-radius: 19px;
        border: 1px solid #dcdcdc;
        background-color: #ffffff;
      }
    }

    .brand-logo {
      @media #{$navbreak-max} {
        display: flex;
        justify-content: center;
        padding: 0 8em;
      }

      img {
        height: auto;
        max-width: 150px;
      }
    }
  }

  main {
    .brand-logo {
      @media #{$navbreak} {
        margin-bottom: 80px;
      }

      img {
        height: auto;
        width: pxToRem(220);
      }
    }
  }

  .alert {
    text-align: center;
  }

  .alert-danger {
    font-size: 13px;
    line-height: 14px;
  }

  h1 {
    color: #2f2f2f;
    font-family: 'TTCommons', sans-serif;
    font-size: 1.6875rem;
    text-align: center;
  }

  h2 {
    color: #7f7e7e;
    font-family: 'TTCommons', sans-serif;
    font-size: 0.875rem;
    text-align: center;
    margin-bottom: 2.3125rem;
  }

  form {
    width: 270px;
  }

  button {
    display: block;
    margin: 1rem auto 0;
    border: 0 solid #1f5274;
    color: #ffffff;
    font-family: 'TTCommons', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 1px;
    cursor: pointer;
  }
}
