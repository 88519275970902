.active-campaigns-accordion {
  margin-bottom: pxToRem(22);
  border-radius: $card-border-radius;
  overflow: hidden;

  .card-header i {
    @include absolute(top 50% right 8%);
    @include transform(translate(0, -50%) rotate(-90deg));
    width: 0px;
    height: 0px;
    border: 7px solid #4c8fb8;
    border-left: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }
}

.active-campaigns {
  &__item {
    margin-bottom: pxToRem(44);

    &__icon {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      height: pxToRem(48);
      width: pxToRem(48);
      border-radius: 50%;
      line-height: pxToRem(48);
      margin-right: pxToRem(16);
      position: relative;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .icon-drawer {
        font-size: pxToRem(19);
      }

      .icon-campaing {
        font-size: $fs-18;
      }

      .icon-debitcard {
        font-size: pxToRem(21);
      }

      .icon-cash {
        font-size: $fs-14;
      }

      .icon-coin {
        font-size: $fs-18;
      }

      .icon-bar-chart {
        font-size: $fs-14;
      }

      background-color: rgba(168, 191, 203, 0.2);
      color: $navy-active;
      transition: 0.3s ease-in-out;

      &:hover {
        color: darken($navy-active, 5);
        background-color: rgba(168, 191, 203, 0.3);
      }
    }

    &__content {
      position: relative;
      top: 5px;
      display: inline-block;
      vertical-align: bottom;
      font-size: $fs-16;
      line-height: pxToRem(18);
      color: $txt-content;

      strong {
        display: block;
        color: $black;
        font-weight: 600;
        font-size: pxToRem(21);
        line-height: pxToRem(24);
        margin-bottom: pxToRem(4);
      }
    }
  }
}
