.stepper {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;

  &:first-child {
    .circle, .step-title {
      align-self: flex-start;
    }

    .step-title {
      margin-left: 8px;
    }
  }

  &:last-child {
    .circle, .step-title {
      align-self: flex-end;
    }

    .step-title {
      margin-right: 8px;
    }
  }
}

.step .circle {
  border-radius: 50%;
  background-color: $white;
  
  &_outer {
    width: 28px;
    height: 28px;
    border: 2px solid $white;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  &_inner {
    width: 24px;
    height: 24px;
    border: 2px solid #3B3B3B;
    box-shadow: 1px 3px 5px #1F5274AA;
  }
}

.step-title {
  text-align: center;
  margin-bottom: 5px;
  color: #575454;
  font-weight: 700;
  font-size: 1rem;
}

.step-description {
  max-width: 250px;
  color: #5c5c5c;
  font-size: 1rem;
  text-align: left;

  @media screen and (min-width: 1101px) {
    max-width: 300px;
  }

  @media screen and (min-width: 1412px) {
    max-width: 350px;
  }
}

/* horizontal line behind steps */
.stepper::before {
  content: "";
  position: absolute;
  top: 38px;
  left: 10px;
  right: 10px;
  height: 2px;
  background-color: #3B3B3B;
  z-index: 0;
}

/* remove line from last step (for horizontal layout) */
.step:last-child::before {
  display: none;
}

/* switch to vertical layout on smaller screens */
@media (max-width: 870px) {
  .stepper {
    flex-direction: column;
    align-items: flex-start;
  }

  .step {
    margin-bottom: 30px;
    padding-left: 32px;

    &-title {
      margin-top: 5px;
      margin-left: 0 !important;
      margin-bottom: 1rem;
      align-self: flex-start;
    }

    &-description {
      max-width: 100%;
      width: 100%;
      align-self: flex-start;
    }

    .circle {
      position: absolute;
      left: -3px;
    }

    &:last-child, &:first-child {
      .step-title, .step-description {
        align-self: flex-start !important;
      }
    }
  }

  /* vertical line between steps */
  .step::before {
    content: "";
    position: absolute;
    left: 7px;
    top: 20px;
    bottom: -30px;
    width: 2px;
    background-color: #ddd;
  }

  /* remove vertical line from last step */
  .step:last-child::before {
    display: none;
  }

  /* circle and text alignment for vertical layout */
  .step .circle {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .step-title, .step-description {
    text-align: left;
  }

  .stepper::before {
    content: none;
  }
}