.merged-badge {
  background: $red;
  color: white;
  font-size: 14px;
  padding: 6px 10px 4px 10px;
  height: 25px;
  border-radius: $border-radius;
  font-weight: 600;
}

.expired-badge {
  background: $orange;
  color: white;
  font-size: 14px;
  padding: 6px 10px 4px 10px;
  height: 25px;
  border-radius: $border-radius;
  font-weight: 600;
}

.preview-badge {
  background: $orange-50;
  color: $orange;
  border: 1px solid $orange;
  font-size: 14px;
  padding: 4px 10px 4px 10px;
  height: 25px;
  border-radius: $border-radius;
  font-weight: 600;
  margin-top: 0.4em;
}

.campaign-active-badge {
  background: $orange;
  padding: 1px 6px 0px 6px;
  color: white;
  border-radius: $border-radius;
  font-size: 14px;
}
