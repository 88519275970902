#campaigns-container {
  display: flex;
  flex-direction: column;

  .accordion .card .collapse .card-body .accordion:last-child {
    .campaign {
      margin-bottom: 0;
    }
  }

  .campaign {
    border: 1px solid #c1c5d3;
    overflow: inherit;
    padding: 0px !important;

    &--bodiless {
      .campaign-header_toggle:hover {
        cursor: auto;
      }
    }

    .card-header {
      background-color: transparent;
      border-bottom: none;
    }

    &.card {
      border-radius: 8px !important;
    }
  }

  .goals-card {
    background-color: $background;
    border: none !important;
    overflow: inherit;

    &_header {
      font-weight: 500;
      font-size: $fs-20;
      text-align: start;
      display: flex;
      border: none;

      &.open {
        border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
      }

      .whitespace {
        white-space: wrap !important;
      }
    }

    .accordion {
      .card {
        border: 1px solid rgba(0, 0, 0, 0.08) !important;
      }
    }

    .card-body {
      padding: 1.25rem;
    }

    .accordion-arrow {
      width: 0px;
      height: 0px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid $arrow;
      transition-duration: 0.2s;
      margin-left: auto;
      transform: rotate(0);

      &.open {
        transform: rotate(180deg);
      }

      &:hover {
        cursor: pointer;
      }
    }

    .budget {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &_input {
        padding: 0.375rem 0.375rem 0.2rem 0.375rem;
        border: 1px solid $input-border;
        border-radius: $tooltip-border-radius;
        width: 25%;
      }

      &_slider {
        &__inner {
          position: relative;
          padding-top: 1.5rem;
          width: 100%;
          display: flex;
          align-items: center;
          gap: 1px;
        }

        &__bound {
          width: 40px;
          height: 7px;
          border-radius: 5px;
          background: $orange-75;
          position: relative;

          &.active {
            // thumb
            &::after {
              content: '';
              width: 16px;
              height: 16px;
              background: $orange;
              border-radius: 50%;
              box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
              position: absolute;
              top: -6px;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          &.animated {
            animation: pulse 1s;
          }
        }

        &__input {
          width: calc(100% - 80px);

          &--filled {
            background: $orange !important;
          }

          &--out-of-bounds {
            &::-webkit-slider-thumb {
              // can't use display: none as this would disable the slider
              background-color: transparent !important;
              border: none !important;
              box-shadow: none !important;
              cursor: default !important;
            }

            &::-moz-range-thumb {
              // can't use display: none as this would disable the slider
              background-color: transparent !important;
              border: none !important;
              box-shadow: none !important;
              cursor: default !important;
            }

            &::-ms-thumb {
              // can't use display: none as this would disable the slider
              background-color: transparent !important;
              border: none !important;
              box-shadow: none !important;
              cursor: default !important;
            }
          }
        }

        &_text-track {
          position: relative;
          width: calc(100% - 80px);
        }

        &_text {
          width: 110px;
          text-align: center;
          font-weight: 500;
          position: absolute;
          font-size: 18px;
          color: #000;
          top: -1px;
          text-wrap: nowrap;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          &--fixed-left {
            justify-content: flex-start;
            left: -48px !important;
          }

          &--fixed-right {
            justify-content: flex-end;
          }

          &__value {
            display: inline-block;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &__ticks {
          display: flex;
          justify-content: space-between;
          width: calc(100% - 42px);
          margin-left: auto;
          margin-right: auto;
          @media (max-width: 480px) {
            .padding-top-mobile {
              padding-top: 15px;
            }
          }
        }
      }

      &_textual {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1rem;
        position: relative;

        .notice {
          width: 100%;
        }

        @media #{$sm} {
          flex-direction: row;
          gap: 1rem;
        }

        @media #{$navbreak} {
          display: flex;
          flex-direction: column;
          gap: 0;

          .notice {
            margin: 0.5rem 0;
          }
        }

        @media screen and (min-width: 1601px) {
          flex-direction: row;
          gap: 1;

          .notice {
            margin: 0;
          }
        }

        &__input {
          display: flex;
          align-items: center;
          gap: 1rem;

          input {
            min-width: 120px;
          }
        }
      }

      &[disabled] {
        opacity: 0.5;
      }
    }

    .campaign-goals {
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.08);

      &.disabled {
        opacity: 0.5;
      }
    }

    .runtime {
      background-color: #fff;
      border: 1px solid $border;
      border-radius: $card-border-radius;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      padding: 1rem;

      @media #{$md} {
        flex-direction: row;
      }

      &_inputs {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        label {
          height: 50%;
          display: flex;
          align-items: center;
          margin: 0;
        }

        &_col {
          h6 {
            white-space: nowrap;
          }

          input {
            min-width: 150px;
          }

          .form-group {
            margin: 0;
          }

          @media #{$navbreak} {
            min-width: auto;
          }
        }

        @media #{$sm} {
          flex-direction: row;
        }
      }
    }

    .disabled-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto auto;
      width: 80%;
      height: max-content;
      background: white;
      padding: 20px;
      box-shadow: 0px 2px 4px #00000055;
      border-radius: $tooltip-border-radius;
      font-family: 'TTCommons';
      font-size: $fs-14;
      line-height: 20px;
      font-weight: 400;
      color: #505050;
    }
  }
}

.campaign-drawer {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

@keyframes pulse {
  0%,
  100% {
    background-color: $orange-75;
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2px);
    background-color: $orange;
  }
  50% {
    transform: translateX(2px);
    background-color: $orange-75;
  }
  75% {
    transform: translateX(-2px);
    background-color: $orange;
  }
  100% {
    background-color: $orange-75;
  }
}
