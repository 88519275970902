.fd-select {
  border-color: $border;

  &__control {
    &--is-focused {
      box-shadow: none !important;
      border-color: $border !important; 
    }

    &:hover {
      border-color: $border;
    }
  }

  &__input-container {
    width: unset !important;
  }

  &__multi-value {
    background-color: $background !important;
    border-radius: 4px !important;
    padding: 4px 8px;
    height: 28px;
    min-width: 32px !important;
    gap: .5rem;
    text-align: center;
    justify-content: center;
    align-items: center;

    &-label {
      color: #504141 !important;
      font-size: 1rem !important;
    }
    &__label {
      color: #504141 !important;
      font-size: 1rem !important;
      padding-left: 2px !important;
      padding-bottom: 0 !important;
    }

    &--is-disabled {
      opacity: 0.6;
    }
  }

  &__option {
    font-size: 1rem;
    color: $black !important;

    &--is-focused {
      background-color: $background !important;
    }
    &--is-selected {
      background-color: $white !important;
      color: $black !important;
    }
  }

  .dropdown-indicator {
    width: 0px;
    height: 0px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $arrow;
    transition-duration: 0.2s;
    transform: rotate(0);

    &:hover {
      border-top: 9px solid $txt-content;
      cursor: pointer;
    }

    &--open {
      transform: rotate(180deg);
    }
  }

  .multi-value-remove, &__multi-value__remove {
    transition-duration: .2s;

    svg path {
      fill: $arrow;
    }

    &:hover {
      color: $txt-content !important;
      background-color: transparent !important;
      cursor: pointer;
      opacity: 1;
      filter: contrast(120%);
      transform: scale(1.1);
    }
  }

  .option-with-checkbox {
    display: flex;
    align-items: center;
    gap: 0rem;
    padding-left: 1rem;
    height: 40px;

    &:hover {
      background-color: $background;
      cursor: pointer;
    }

    .custom-check {
      display: flex;
      align-items: center;
      height: 20px;

      &__label {
        margin-top: 2px;
      }
    }

    .fd-select__option {
      padding-left: 0;
      &--is-focused {
        background-color: transparent !important;
        cursor: pointer;
      }
    }
  }
}
