.autofill {
  hr {
    display: none;
  }

  &.with-border {
    background: rgb(245, 246, 250);
    display: block;
    width: 100%;

    hr {
      display: block;
    }
  }

  &_warning-header {
    font-size: 1.3rem;
    font-weight: 600;
    color: $navy;
    margin-bottom: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &_warning-msg {
    text-align: center;
    color: $navy;
    margin-bottom: 1rem;
  }

  &_card {
    background-color: rgb(245, 246, 250);
    padding: 1em 0.85em;
    margin-bottom: 1.5rem;
    color: #616060;
    border-radius: 0.25rem;

    &__inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      margin-top: 0.8rem;

      @media #{$navbreak} {
        flex-direction: row;
      }

      &__input-wrapper {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;

        @media #{$navbreak} {
          width: unset;
        }

        .dropdown {
          &_container {
            flex: 1;
            width: 100%;

            @media #{$navbreak} {
              width: 200px;
            }

            .dropdown_menu_container {
              max-height: 214px;
            }
          }

          &_input,
          &_container {
            height: 32px;
            min-height: 32px;
          }
        }
      }

      .autofill_btn {
        margin-right: auto;

        @media #{$navbreak} {
          margin-right: 0;
        }
      }
    }

    &.with-border {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    @media #{$sm-max} {
      flex-direction: column;
      align-items: start;
      gap: 1em;
    }
  }

  &_btn {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    border: 3px solid #d15c37;
    color: #d15c37;
    background-color: transparent;
    border-radius: 4px;
    font-size: pxToRem(14);
    font-weight: 500;
    padding: 12px 15px;
    line-height: 0.8rem;
    height: 27px;
    transition: all 0.2s;

    span {
      position: relative;
      top: 1px;
    }

    &:hover,
    &:active {
      background-color: #d15c37;
      color: $white;

      svg {
        path {
          fill: $white;
        }
      }
    }

    svg {
      path {
        fill: #d15c37;
      }
    }

    &.small {
      padding: 10px;
    }
  }

  &_proceed-btn {
    padding: 4px 16px !important;
    font-size: pxToRem(14px) !important;
    font-weight: 500 !important;
  }

  &_falling-dots {
    margin: 0 !important;
    padding: 0 !important;
  }
}
