.sentinel {
  &_container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }

  .export-results,
  .saved-questions {
    min-height: 38px;
    height: 38px;
    max-width: 200px;
  }
  &_dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.2);
    border: 1px solid #dee2e6;
    width: 100%;
    min-width: unset;

    &_item {
      position: relative;
      color: $txt-content;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 4px 12px;
      height: 42px;
      text-align: center;
      width: 100%;
      font-size: medium;

      &:hover {
        background-color: #f5f6fa;
        color: $txt-content;
      }

      &:first-of-type {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-of-type {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  &_mobile_topbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .export-results,
    .saved-questions {
      max-width: unset;
      width: 100%;
      position: relative;
    }
    &_dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 1;
    }

    @media screen and (min-width: 400px) {
      margin-top: 2rem;
      flex-direction: row;
      justify-content: center;

      .export-results,
      .saved-questions {
        width: 100%;
      }
    }

    @media screen and (max-width: 399px) {
      margin-top: 2rem;
      align-items: normal;
    }

    > * {
      flex-grow: 1;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &_tables {
    flex-grow: 1;

    .dropdown_input {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &_select__btn {
    min-height: 36px !important;
  }

  &_guide {
    text-align: center;
    font-size: pxToRem(17);
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &_error {
    background-color: $white;
    padding: 12px 1.4rem;
    border-radius: 6px;
    color: $red;
  }
}
