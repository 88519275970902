.notification-details {
  width: 100%;

  background-color: #fff;
  margin-bottom: 4px;

  @media #{$lg-max} {
    padding-top: 1rem;
  }

  &_thumb {
    margin: 1.3rem;
    border-radius: 50%;
    min-height: 50px;
    min-width: 50px;
    max-height: 50px;
    max-width: 50px;
    justify-content: center;
    align-items: center;
    display: none;
    background-color: #edf0f4;
    &.transparent {
      background-color: transparent;
    }

    & i {
      font-size: 18px;
      color: $navy;
    }
    @media screen and (min-width: 550px) {
      display: flex;
    }
  }

  &_title {
    color: $navy;
    padding-top: 0.5em;
    margin-bottom: 0.75rem;

    line-height: 2.875rem;
    font-weight: 600;
    font-size: pxToRem(32);
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 2;
  }

  &_topic {
    font-size: pxToRem(18);
    font-weight: 500;
    color: #fff;
    background-color: $navy-60;
    text-transform: capitalize;
    padding: 2px 7px 0;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
  }

  &_time {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    time {
      font-size: pxToRem(16);
      color: #5c5c5c;
      padding-left: 4px;
      margin-top: 4px;
    }
  }

  &_body {
    font-size: pxToRem(17);
    color: #333;
    margin-top: 2rem;
    margin-bottom: 1rem;
    line-height: 1.5;
    padding: 1rem;
    border-radius: 7px;
    border: 1px solid #e8e9ee;
    width: 100%;

    ul {
      list-style: initial !important;
      padding-left: 1.5rem;
    }
  }

  &_action-button {
    button.bt.w-100 {
      font-size: pxToRem(18) !important;
    }

    span {
      @media #{$navbreak-max} {
        font-size: pxToRem(18) !important;
      }
    }
  }
}
