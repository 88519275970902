// .react-datepicker__day--disabled,
// .react-datepicker__month-text--disabled,
// .react-datepicker__quarter-text--disabled,
// .react-datepicker__year-text--disabled {
//   cursor: default;
//   color: #ccc;
// }
.simulation-page {
  .card-header,
  .card-body {
    padding-left: pxToRem(30);
    padding-right: pxToRem(30);

    .minus-icon {
      padding-left: 1rem;

      img {
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
        display: inline;
        cursor: pointer;
      }
    }
  }

  .separatePlaceholderBox {
    position: relative;

    ::-webkit-input-placeholder {
      /* Edge */
      color: transparent;
      visibility: hidden;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: transparent;
      visibility: hidden;
    }

    ::-moz-placeholder {
      color: transparent;
    }

    /* FF 19+ */
    :placeholder {
      /* Internet Explorer 10-11 */
      color: transparent;
      visibility: hidden;
    }

    .separatePlaceholder {
      position: absolute;
      top: 14px;
      left: 14px;
      z-index: 0;
      color: #888585;

      &.required::after {
        content: '*';
        color: red;
        display: inline-block;
      }

      &.hidden {
        opacity: 0;
      }
    }

    input {
      background-color: transparent;
      position: relative;
      z-index: 1;
    }

    input:focus + .separatePlaceholder,
    input[value]:not([value='']) + .separatePlaceholder {
      opacity: 0;
    }

    .react-datepicker__tab-loop ~ .separatePlaceholder {
      opacity: 0;
    }
  }

  .input-cell--select--required .input-select__placeholder::after {
    content: '*';
    color: red;
  }

  .input-select__menu,
  .react-datepicker-popper {
    z-index: 2;
  }

  .simulation-form .lineup-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .lineup {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      @media #{$md-max} {
        flex-direction: column;
        gap: 1em;
      }
    }
  }

  .simulation-form .lineup {
    &__add {
      font-weight: 500;
      font-size: $fs-16;
      line-height: pxToRem(18);
      color: #7195ad;
      margin-left: auto;
      margin-right: 0;
      padding-top: 13px;
      padding-bottom: 13px;

      @media #{$md-max} {
        background: rgba(113, 149, 173, 0.1);
        border-radius: 4px;
        color: #7195ad;
        font-size: $fs-16;
        line-height: pxToRem(18);
        width: 100%;
        padding: 13px;
        padding-bottom: 9px;

        span {
          margin-right: 5px;
        }
      }
    }

    &__addTrack {
      border-radius: 4px;
      width: 100%;
      padding-top: 13px;
      padding-bottom: 9px;
      margin-bottom: 16px;
      text-align: center;
      border: 1px dashed #7195ad;
      color: #6790a6;

      span {
        margin-right: 5px;
      }

      @media #{$md-max} {
        border: 0;
        text-align: left;
        font-weight: 500;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      color: #3b3b3b;
    }

    &__title {
      font-weight: 500;
      margin: 0;
    }

    &__removeButton {
      font-weight: 500;
      color: #d8d8d8;
      cursor: pointer;

      &:hover {
        color: #3b3b3b;
      }
    }
  }

  .simulation-form {
    display: flex;
    flex-direction: column;
    gap: 22px;

    &_download-area {
      display: flex;
      align-items: center;
      gap: 22px;

      &_text {
        font-size: 1rem;
        color: $txt-content;

        .red {
          color: red;
        }
      }

      .dropdown_container {
        width: 200px;
      }

      a {
        font-size: pxToRem(17);
        min-height: 36px;
        height: 36px;
        margin-top: -1px;
      }
    }

    .submit-btn {
      padding: 1.875rem;
      padding-top: 0;

      @media #{$navbreak} {
        padding: 0;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      @media #{$md-max} {
        flex-direction: column;
      }
    }

    &_header-wrap {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 991px) {
        flex-direction: row;
      }
    }

    &_sim-name-wrap {
      width: 100%;
      overflow: hidden;

      @media screen and (min-width: 991px) {
        max-width: 60%;
        width: 100%;
      }
    }

    .requiredInputBox.category {
      width: 100%;
      max-width: 800px;
    }

    &_sim-category {
      &-wrap {
        width: 100%;

        // overflow: hidden;
        @media screen and (min-width: 991px) {
          max-width: 40%;
          margin-left: 1rem;
        }
      }

      &-dropdown {
        .dropdown_input {
          height: 40px;
        }
      }
    }

    @media #{$md-max} {
      padding-left: 0;
      padding-right: 0;
    }

    input {
      border: 1px solid rgba(178, 178, 178, 0.5);
    }

    &__list {
      list-style: number;
      padding-left: 1.875rem;
      padding-right: 1.875rem;
      padding-bottom: 1.25rem;
      margin-left: 14px;
    }

    .searchbar-comp_inner-input {
      border: none !important;
    }

    .input-select input {
      border: 0px !important;
    }

    ::placeholder::after {
      width: 30px;
      height: 40px;
      border: 1px solid red;
      display: block;
      content: '*';
      color: red;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .input-select__dropdown-indicator {
      width: 0;
      height: 0;
      padding: 0;
      margin-right: 16px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid #c4c4c4;

      @media #{$md-max} {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #c4c4c4;
      }

      svg {
        display: none;
      }
    }

    .input-cell--date {
      position: relative;

      .calenderIcon {
        position: absolute;
        right: 12px;
        top: 12px;
        width: 15px;
        height: 15px;
      }
    }

    .card-header {
      font-size: $fs-18;
      line-height: pxToRem(21);
      padding-bottom: pxToRem(7);
      padding-top: 0;
      background-color: transparent;
    }

    &__headerQuestion {
      font-size: $fs-16;
      line-height: pxToRem(25);
      font-weight: 500;
      padding-top: 5px;
      padding-left: pxToRem(15);
      padding-right: pxToRem(15);
      color: #3b3b3b;

      @media #{$lg-max} {
        font-weight: inherit;
        padding-top: 10px;
      }
    }

    &__headerInfo {
      font-size: $fs-16;
      line-height: pxToRem(22);
      margin: 20px 0px;
      padding-left: pxToRem(15);
      padding-right: pxToRem(15);
      color: #505050;

      span {
        color: red;
      }

      @media #{$lg-max} {
        margin-bottom: 0;
      }
    }
  }

  .simulationProgressbar {
    width: 180px;
    position: relative;
    height: 90px;

    @media #{$md-max} {
      margin-top: 2px;
    }

    @media (max-width: 340px) {
      margin-top: 15px;
    }

    &__graph {
      position: absolute;
      bottom: -1px;
      width: 180px;
      height: auto;
    }

    &__pointer {
      height: 80px;
      width: 0px;
      border: 0px;
      background-color: blue;
      position: absolute;
      bottom: 0;
      left: 50%;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
      // transform: rotate(35deg);
      transform-origin: bottom left;

      &::after {
        display: block;
        content: '';
        background-color: #fff;
        width: 18px;
        height: 18px;
        border: 3px solid #d63c24;
        border-radius: 18px;
        position: absolute;
        top: -15px;
        left: -9px;
      }

      &.v2::after {
        border-color: #f58562;
      }

      &.v3::after {
        border-color: #6cbda2;
      }
    }

    &__value {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: $fs-40;
      line-height: pxToRem(46);
      font-weight: 500;
    }
  }
}
