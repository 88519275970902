.transactions-chart {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .highcharts-legend-item,
  .highcharts-axis-title,
  .highcharts-axis-labels {
    font-family: 'TTCommons';

    text {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 13px !important;
    }
  }

  .highcharts-custom-label {
    color: #555;
    & > span {
        font-size: 12px;
        background-color: #f5f5f5 !important;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
        padding: 18px;
        border: 1px solid #1f5274;
        border-radius: 3px;
        z-index: 9999;
    }
  }

  .highcharts-label.highcharts-custom-label {
    .custom-label-content::before {
        content: '';
        position: absolute;
        bottom: 100%; /* Position it above the container */
        left: 50%;
        margin-left: -7px;
        border-width: 0 7px 7px 7px; /* Adjust border-width to create an upward arrow */
        border-style: solid;
        border-color: transparent transparent #032338 transparent; /* Adjust border-color to match your design */
    }

    .custom-label-content::after {
        content: '';
        position: absolute;
        bottom: 100%; /* Position it above the container */
        left: 50%;
        margin-left: -6px;
        border-width: 0 6px 6px 6px; /* Adjust border-width to create an upward arrow */
        border-style: solid;
        border-color: transparent transparent #f5f5f5 transparent; /* Adjust border-color to match your design */
    }
  }

  .highcharts-label.highcharts-custom-label-right {
    .custom-label-content {
        &:before, &:after {
            left: 92%;
        }
    }
  }

  .highcharts-label.highcharts-custom-label-left {
      .custom-label-content {
          &:before, &:after {
              left: 8%;
          }
      }
  }

  .highcharts-popup-content, .highcharts-custom-label {
    padding: 12px 15px 5px;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 13px !important;

    h5 {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 15px !important;
    }

    p {
      text-align: right;

      .date {
        color: #919191;
        font-size: 1rem;
      }
    }
  }

  .card-title-default {
    color: #3b3b3b;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    font-weight: 600;
  }

  &_filters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 2.5em;
    gap: 0.5em;

    @media #{$sm} {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1em;
      width: 100%;
    }

    &_items {
      display: flex;
      width: fit-content;

      div {
        border: 1px solid $border;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 4px 0.75rem 0 0.75rem;
        min-width: 86px;
        color: $txt-header-2;
        font-size: 1rem;
        line-height: 1.125rem;
        font-weight: 500;
        cursor: pointer;
        text-align: center;
        white-space: nowrap;

        &.selected {
          background-color: $navy;
          color: $white;
        }

        &:first-of-type {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:last-of-type {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &.middle {
          border-radius: 0;
          border-right: none;
        }

        &:hover {
          background-color: $navy;
          color: $white;
        }
      }
    }
  }
}
