.user-status-bar-wrapper {
  height: pxToRem(40);
  margin-bottom: pxToRem(42);
  &.half-fluid {
    padding-right: 0;
    .user-status-bar {
      padding-right: pxToRem(15);
    }
  }
}
.user-status-bar {
  height: 100%;
  padding: pxToRem(8) 0;
  font-size: $fs-16;
  color: $txt-header-125;
  border-bottom: 1px solid $border;
  .icon {
    display: inline-block;
  }
  .icon-circle {
    margin-right: pxToRem(6);
    height: pxToRem(13);
    width: pxToRem(13);
    border: 2px solid #ff6868;
    border-radius: 50%;
  }
  .icon-user {
    display: inline-block;
    color: $navy;
    vertical-align: bottom;
    font-size: pxToRem(22);
    margin-right: pxToRem(10);
  }
  &__summary-icon,
  &__summary {
    display: inline-block;
  }
  &__summary-icon {
    vertical-align: text-bottom;
  }
  &__summary {
    vertical-align: sub;
  }
  &__notifications {
    background-color: $orange-50;
    color: $orange;
    font-weight: 500;
    padding: 4px 15px 0px 15px;
    border-radius: 4px;
    display: inline-block;
    &:hover {
      color: $orange-third;
    }
  }
}
