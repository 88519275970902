.app-badge {
  position: relative;
  padding: 6px 0;
  width: 22px;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-badge__text {
  margin-left: 11px;
  z-index: 1;
  padding: 2px 3px 0;
  background-color: $orange;
  color: #fff !important;
  font-size: 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 1;

  position: absolute;
  top: 4px;
  left: 0px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 12px;
  min-height: 8px;
}
