.sentinel {
  &_more__btn {
    width: 48px !important;
    min-height: 36px !important;
    padding: 0.1875rem .2rem !important;

    svg {
      -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));
      filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));

      path {
        fill: $white;
      }
    }
  }

  &_menu__wrapper {
    top: 45px;
  }
}
