.past-events-filter {
  @media #{$navbreak-max} {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &_switch {
    padding: 0;

    @media #{$navbreak-max} {
      justify-content: space-between;
      width: 100%;
    }

    .switch_label {
      overflow: hidden;
      text-overflow: ellipsis;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: $light-black;
      text-align: left;
      font-weight: 500;
      font-size: pxToRem(18);
    }
  }
}
