.custom-switch {
  padding: 0 !important;
  vertical-align: middle !important;
  .react-switch-handle {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
  }
  &--disabled {
    opacity: 1 !important;
  }
  &--checked:hover {
    .react-switch-bg {
      background-color: #104466 !important;
    }
  }
}
