$TTCommons-Black: './../../fonts/TTCommons/TTCommons-Black';
$TTCommons-Bold: './../../fonts/TTCommons/TTCommons-Bold';
$TTCommons-DemiBold: './../../fonts/TTCommons/TTCommons-DemiBold';
$TTCommons-Medium: './../../fonts/TTCommons/TTCommons-Medium';
$TTCommons-Regular: './../../fonts/TTCommons/TTCommons-Regular';
$TTCommons-Light: './../../fonts/TTCommons/TTCommons-Light';
$TTCommons-Thin: './../../fonts/TTCommons/TTCommons-Thin';

@font-face {
  font-family: 'TTCommons';
  font-style: normal;
  font-weight: 900;
  src:
    local('TTCommons'),
    url('#{$TTCommons-Black}.woff2') format('woff2'),
    url('#{$TTCommons-Black}.woff') format('woff'),
    url('#{$TTCommons-Black}.ttf') format('truetype');
}

@font-face {
  font-family: 'TTCommons';
  font-style: normal;
  font-weight: 700;
  src:
    local('TTCommons'),
    url('#{$TTCommons-Bold}.woff2') format('woff2'),
    url('#{$TTCommons-Bold}.woff') format('woff'),
    url('#{$TTCommons-Bold}.ttf') format('truetype');
}

@font-face {
  font-family: 'TTCommons';
  font-style: normal;
  font-weight: 600;
  src:
    local('TTCommons'),
    url('#{$TTCommons-DemiBold}.woff') format('woff'),
    url('#{$TTCommons-DemiBold}.ttf') format('truetype');
}

@font-face {
  font-family: 'TTCommons';
  font-style: normal;
  font-weight: 500;
  src:
    local('TTCommons'),
    url('#{$TTCommons-Medium}.woff2') format('woff2'),
    url('#{$TTCommons-Medium}.woff') format('woff'),
    url('#{$TTCommons-Medium}.ttf') format('truetype');
}

@font-face {
  font-family: 'TTCommons';
  font-style: normal;
  font-weight: 400;
  src:
    local('TTCommons'),
    url('#{$TTCommons-Regular}.woff2') format('woff2'),
    url('#{$TTCommons-Regular}.woff') format('woff'),
    url('#{$TTCommons-Regular}.ttf') format('truetype');
}

@font-face {
  font-family: 'TTCommons';
  font-style: normal;
  font-weight: 300;
  src:
    local('TTCommons'),
    url('#{$TTCommons-Light}.woff2') format('woff2'),
    url('#{$TTCommons-Light}.woff') format('woff'),
    url('#{$TTCommons-Light}.ttf') format('truetype');
}

@font-face {
  font-family: 'TTCommons';
  font-style: normal;
  font-weight: 100;
  src:
    local('TTCommons'),
    url('#{$TTCommons-Thin}.woff2') format('woff2'),
    // Chrome 26+, Opera 23+, Firefox 39+
    url('#{$TTCommons-Thin}.woff') format('woff'),
    url('#{$TTCommons-Thin}.ttf') format('truetype');
}
