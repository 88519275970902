.sales-edit {
  &_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
    gap: 1em;
    // & > section {
    //   margin-top: 1em;
    // }

    @media #{$navbreak} {
      padding: 1.125rem 2rem;
    }

    .how-it-works {
      .card {
        @media #{$navbreak} {
          background: #f5f6fa;
        }
      }
    }

    &_event-title {
      color: #1f5274;
      font-size: 1.5rem;
      font-weight: 600;
      padding-bottom: 0.75rem;
    }

    &_event-date-venue {
      display: flex;
      gap: 0.5em;
      color: #575454;
    }
  }

  &_page-title {
    color: $txt-header-2;
    font-weight: 700;
  }

  &_divider {
    border-bottom: 1px solid $border-color;
    width: 100%;
    height: 1px;
    margin: 1em 0 1.5em 0;
  }

  &_statistics-box {
    &_wrapper {
      display: flex;
      padding: 1em;
      border-radius: $border-radius;
      background: $background;
    }

    &_each-item {
      &_wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
      }

      &_icon {
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background: rgba(168, 191, 203, 0.2);

        &.red {
          background: rgba(242, 161, 147, 0.2);
        }
      }

      &_text {
        margin-left: 12px;
      }

      &_text strong {
        font-size: 18px;
      }
    }
  }

  &_selector {
    background-color: white !important;
    padding: 1em;
    border-radius: 8px;

    @media #{$navbreak} {
      padding: 1em 0em;
    }

    .infobox {
      h4 {
        font-size: pxToRem(18);
      }
    }

    .date-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1em;

      @media #{$navbreak} {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .date-col {
      width: 100%;

      .form-group {
        margin: 0;
      }
    }

    .input-cell--date {
      position: relative;

      .calenderIcon {
        position: absolute;
        right: 12px;
        top: 12px;
        width: 15px;
        height: 15px;
      }
    }
  }

  &_input-row {
    &_wrapper {
      display: flex;
      padding-bottom: 1.5rem;
      width: 100%;
      align-items: center;
      justify-content: center;

      &_first {
        padding-right: calc(1em + 18px);
      }

      @media #{$navbreak-max} {
        padding-bottom: 2.5rem;
      }

      .fields {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        gap: 1em;

        @media #{$navbreak-max} {
          flex-direction: column;
        }
      }

      .minus-icon {
        padding-left: 1em;

        img {
          height: 20px;
          width: 20px;
          min-height: 20px;
          min-width: 20px;
          display: inline;
          cursor: pointer;
        }
      }

      input {
        &:focus {
          border: 1px solid rgba(178, 178, 178, 0.5) !important;
        }
      }
    }

    &_select-wrapper {
      width: 100%;
    }

    &_input-wrapper {
      margin-left: 12px;
      width: 100%;

      .separatePlaceholderBox {
        position: relative;

        ::-webkit-input-placeholder {
          /* Edge */
          color: transparent;
          visibility: hidden;
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: transparent;
          visibility: hidden;
        }

        ::-moz-placeholder {
          color: transparent;
        }

        /* FF 19+ */
        :placeholder {
          /* Internet Explorer 10-11 */
          color: transparent;
          visibility: hidden;
        }

        .separatePlaceholder {
          position: absolute;
          top: 14px;
          left: 14px;
          z-index: 0;
          color: #888585;

          &.required::after {
            content: '*';
            color: red;
            display: inline-block;
          }

          &.hidden {
            opacity: 0;
          }
        }

        input {
          background-color: transparent;
          position: relative;
          z-index: 1;
        }

        input:focus + .separatePlaceholder,
        input[value]:not([value='']) + .separatePlaceholder {
          opacity: 0;
        }

        .react-datepicker__tab-loop ~ .separatePlaceholder {
          opacity: 0;
        }
      }
    }
  }

  &_footer {
    &_wrapper {
      margin-top: auto;
    }

    &_buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &_add-ticket {
    text-align: right;
    color: #137cbd;
    cursor: pointer;

    @media #{$navbreak-max} {
      background: rgba(113, 149, 173, 0.1);
      border-radius: 4px;
      font-size: $fs-16;
      line-height: pxToRem(18);
      padding: 13px;
      padding-bottom: 9px;
      text-align: center;
    }
  }
}
