.sentinel_input {
  padding: 18px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  border-radius: 6px;
  border: 1px solid #c1c5d3;
  background-color: $white;

  &__container {
    position: relative;
  }

  &__placeholder {
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none; /* Ensures the placeholder is not clickable */

    .placeholder {
      margin-bottom: 0.4rem;
    }

    .placeholder,
    .tip,
    .description {
      color: #888585;
      font-size: 1rem;
    }

    .description {
      font-size: pxToRem(14);
      margin-bottom: 0.2rem;
    }
    .tip {
      font-size: pxToRem(14);
      margin-top: 0.4rem;
    }
  }

  textarea {
    border: none;
    height: 22rem;
    font-size: 1.125rem;
    @media #{$navbreak-small} {
      height: 15rem;
    }
    @media #{$navbreak} {
      height: 10rem;
    }
  }

  &__options {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;

    @media #{$navbreak} {
      flex-wrap: initial;
      justify-content: initial;
    }

    &__outcomes {
      flex-grow: 1;

      &:hover {
        cursor: pointer;
      }

      @media #{$navbreak} {
        width: 260px;
        flex-grow: initial;
      }
    }

    &__ask {
      min-height: 37px !important;
      height: 37px;
    }
    &__ask + .infobox__icon {
      margin-left: 0.8rem !important;
    }

    &__star {
      margin-left: auto;

      svg {
        width: 24px;
        height: 24px;
      }

      &-container {
        margin-left: auto;
      }

      &--disabled {
        opacity: 0.5 !important;
        cursor: initial !important;
      }
    }
  }
}
