.layout {
  &__wrapper {
    padding-left: 0;

    @media #{$navbreak} {
      padding-left: 5rem;
    }

    &-expanded {
      padding-left: 13rem;

      @media #{$lg-max} {
        padding-left: 0rem !important;
      }

      @media screen and (min-width: 1400px) and (max-width: 1430px) {
        .container-fluid {
          padding-left: 4.25rem !important;
          padding-right: 4.25rem !important;
        }
      }
    }
  }
}
