.flex-wrap-at-mobile {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 767px) {
    flex-direction: row;
  }
}

.event-editor {
  &_form {
    .modal_inner_wrapper {
      border: none;
    }
  }
  &_modal-content {
    &_wrapper {
      display: flex;
      flex-direction: column;
      background-color: #f5f6fa;
      min-width: 300px;
      max-height: 80vh;
      overflow-y: auto;
      @media screen and (min-width: 425px) {
        width: 400px;
        min-height: 80vh;
      }

      @media screen and (min-width: 568px) {
        min-width: 100%;
      }

      @media screen and (min-width: 768px) {
        width: 700px;
        min-width: 100%;
      }

      @media screen and (min-width: 1024px) {
        min-width: 100%;
      }

      .error-message-wrapper {
        padding-left: 2em;
        padding-right: 2em;
        .error-message {
          width: 100%;
          text-align: center;
          padding: 1em;
          box-sizing: border-box;
        }
      }
    }
    &_btn {
      &_wrapper {
        background: $border;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 34px;
        max-width: 40px;
        min-height: 34px;
        min-width: 40px;
        border-radius: 6px;
        transition-duration: 0.2s;
        opacity: 0.7;

        &:hover {
          transition-duration: 0.2s;
          opacity: 1;
        }

        svg {
          path {
            stroke: $txt-content;
          }
        }
      }
    }
  }
  &_copy-dialog {
    @media screen and (min-width: 700px) {
      min-width: 800px;
    }
    .modal_inner_content_wrapper {
      padding: 0;
    }
  }

  &_search-view {
    &_wrapper {
      padding: 2em 2em 0 2em;
    }

    &_title {
      color: $navy;
      font-weight: 600;
      text-align: start;
      font-size: 1.3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: flex;
      align-items: center;
    }

    &_sub-title {
      color: $txt-content;
      font-weight: 600;
      font-size: pxToRem(18);
    }

    &_section {
      padding: 1em;
      background: white;
    }
  }

  &_select-lineups-setlists {
    &_wrapper {
      padding: 2em;
      display: flex;
      flex-direction: column;
      gap: 1em;

      article {
        display: flex;
        flex-direction: column;
        gap: 1em;
      }
    }

    &_button-section {
      display: flex;
      justify-content: space-between;
      gap: 2em;
    }

    &_divider {
      width: 100%;
      height: 1px;
      background-color: transparent;
    }

    &_section {
      display: flex;
      flex-direction: column;
      background: white;
      padding: 1em;

      &.top {
        padding: 2em 1em 1.5em 1em;
        flex-direction: row;
        align-items: baseline;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.bottom {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &_title {
      color: $navy;
      font-weight: 600;
    }

    &_checkbox_row {
      display: flex;
      margin-top: 8px;
      width: 100%;
    }

    &_custom-checkbox {
      position: relative;
      display: block;
      margin-top: 4px;
      margin-bottom: 4px;
      line-height: 20px;

      & > * {
        border-radius: 4px;
      }

      &:hover {
        cursor: pointer;

        & span {
          color: #464646;
        }
      }

      .label {
        color: $txt-content-2;
      }

      & span {
        display: block;
        margin-left: 20px;
        padding-left: 12px;
        padding-top: 2px;
        line-height: 20px;
        text-align: left;
        text-transform: capitalize;
      }

      & span::before {
        content: '';
        display: block;
        position: absolute;
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
        top: 0;
        left: 0;
        background: transparent;
        border: 1px solid #d4d4d4;
        border-radius: 4px;
      }

      & span::after {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
      }

      & input[type='checkbox'] {
        opacity: 0;
        z-index: -1;
        position: absolute;
      }

      & input[type='checkbox']:checked + span::after {
        font-family: 'FontAwesome';
        padding-top: 1px;
        padding-left: 1px;
        border-radius: 4px;
        content: '✓';
        background: $navy;
        color: #fff;
      }
    }
  }

  &_event-row {
    &_wrapper {
      display: flex;
      align-items: center;
      gap: 1em;
      width: calc(100%);
      margin-right: 10px;
      background: white;
      padding: 1.3em;
      margin-top: 4px;

      &:first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &_img {
      border-radius: 50%;
      min-height: 50px;
      min-width: 50px;
      max-height: 50px;
      max-width: 50px;
      justify-content: center;
      align-items: center;
      background-color: rgba(168, 191, 203, 0.2);

      & i {
        font-size: 18px;
        color: $navy;
      }

      display: none;

      @media screen and (min-width: 550px) {
        display: flex;
      }
    }

    &_title-and-date {
      &_wrapper {
        // margin-top: 1.2em;
        // justify-content: center;
        // align-items: baseline;
        display: flex;
        flex-direction: column;
        width: calc(100%);
        max-width: calc(100%);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @media screen and (min-width: 550px) {
          margin-top: unset;
        }
      }

      &_title {
        text-overflow: ellipsis;
        white-space: wrap;
        overflow: hidden;
        display: block;
        color: $txt-header;
        font-weight: 600;
        max-width: calc(100%);
      }

      &_date {
        &_wrapper {
          margin-top: 4px;
          display: flex;
          align-items: baseline;
          color: $txt-header-2;
          font-size: 16px;
          width: 100%;

          & i {
            font-size: 12px;
            color: $txt-header-2;
          }

          @media screen and (max-width: 681px) {
            flex-direction: column;
            margin-top: 8px;
          }
        }

        &_location {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: calc(100%);
          margin-right: 12px;

          &.navy {
            color: $navy;

            & i {
              color: $navy;
            }
          }

          @media screen and (max-width: 681px) {
            max-width: calc(50vw);
          }
        }

        &_time {
          white-space: nowrap;
          margin-top: 4px;

          &.navy {
            color: $navy;

            & i {
              color: $navy;
            }
          }

          @media screen and (min-width: 681px) {
            margin-left: 4px;
            margin-top: unset;
          }
        }
      }
    }
  }

  &_copy-modal_confirmation-modal {
    &_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &_sub-text {
      text-align: center;
      margin-top: 1em;
      color: $navy;
      font-size: pxToRem(16);
    }

    &_buttons-wrapper {
      margin-top: 2em;
      display: flex;
      flex-direction: row;
      width: 300px;
      gap: 1rem;
      justify-content: space-around;
    }
  }
}
