.event-list {
  ul {
    margin: 0;
  }

  &__item {
    margin: 0 -1.25rem;

    &__link {
      height: pxToRem(64);
      padding: 0.75rem 1.25rem;

      &:hover,
      &:focus,
      &--active {
        background-color: rgba(241, 242, 248, 0);
        border-radius: pxToRem(6);
        background-color: rgba(241, 242, 248, 0.6);
      }
    }

    &__header {
      font-size: 1rem;
      line-height: 1.125rem;
      display: flex;
      flex-flow: row nowrap;
      white-space: nowrap;
    }

    &__date {
      width: pxToRem(60);
      padding-right: pxToRem(10);
      color: #575454;
    }

    &__title {
      font-size: inherit;
      line-height: inherit;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $black;
    }

    &__state {
      color: #f58562;
    }
  }
}

.events-page-search-filter {
  &_results_container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: #f5f6fa;
  }

  &_table {
    &--filtering {
      @media #{$navbreak-max} {
        display: none !important;
      }
    }
  }
}
