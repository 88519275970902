.events-list-table {
  @media #{$navbreak} {
    padding-top: pxToRem(20);
  }
  padding-bottom: 2rem;
}

.events-list-table {
  font-family: 'TTCommons', sans-serif;
  font-size: 1rem;

  @media #{$navbreak} {
    padding-left: pxToRem(25);
    padding-right: pxToRem(25);
  }
  position: relative;
  &__header {
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: $white;
    margin: 0 1rem;
    border-bottom: 1px solid $border-event-search;
    padding: 1rem 0;
    color: $txt-header-2;
  }

  &__header,
  &__item {
    &__actions {
      @media #{$navbreak} {
        width: pxToRem(405);
      }
      @media #{$xl} {
        width: pxToRem(405);
      }
    }
  }

  &__item {
    align-items: center;
    padding: pxToRem(20) 0;
    @media #{$navbreak} {
      padding: 1rem 0;
    }
    border-bottom: 1px solid #94c7e7;
    color: $light-black;

    .name-date-location__container {
      @media #{$navbreak} {
        padding-top: 5px;
        > div {
          display: flex;
          align-items: center;
        }
      }
    }
    &__name {
      margin-bottom: pxToRem(7);
      @media #{$navbreak} {
        margin-bottom: 0;
      }
      a,
      span {
        display: inline-block;
        font-size: $fs-20;
        line-height: pxToRem(23);
        @media #{$navbreak} {
          font-size: $fs-16;
          line-height: pxToRem(18);
        }
        font-weight: 600;
      }
    }

    time {
      display: inline-block;
      margin-bottom: pxToRem(10);
      @media #{$navbreak} {
        margin-bottom: 0;
      }
      font-weight: 400;
    }

    &__location {
      font-size: $fs-16;
      line-height: pxToRem(18);
      font-weight: 400;
    }

    &__actions {
      font-size: $fs-16;
      line-height: pxToRem(20);
      display: flex;
      margin: 0;
      @media #{$navbreak} {
        padding: 0;
      }
      color: $orange;
      font-weight: 600;
      @media #{$navbreak-max} {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 0;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: 0.5s ease-in-out;
      }
      @media #{$navbreak} {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .bt {
        font-size: $fs-18;
        @media #{$navbreak-max} {
          border: none;
          padding: 0;
          font-size: $fs-20;
          &:not(:last-child) {
            margin-bottom: pxToRem(20);
          }
        }
        @media #{$navbreak} {
          margin-bottom: pxToRem(3);
        }
        @media #{$xl} {
          margin-bottom: 0;
        }
      }
    }

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      .events-list-table__item__actions {
        @media #{$navbreak-max} {
          padding-top: 20px;
          max-height: 500px;
          opacity: 1;
          transition: 0.5s ease-in-out;
        }
      }
    }
  }

  &__no-results {
    color: $txt-header-2;
  }

  ul {
    li:last-child {
      .events-list-table__item {
        border-bottom: none;
      }
    }
  }
}
