.modal {
  &_outer {
    &_wrapper {
      position: fixed;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 99999999999999;
      background: rgba(1, 1, 1, 0.25);

      &_fade {
        padding: 0 1em;
      }

      &.scrollable {
        overflow-y: auto;
      }
    }
  }

  &_inner {
    &_fade {
      display: flex;
      justify-content: center;
    }

    &_wrapper {
      position: relative;
      display: flex;
      border-radius: $card-border-radius;
      flex-direction: column;
      width: 100%;
      min-width: 300px;
      max-width: 95%;
      background: white;
      border: 1px solid $hr-border-color;
      overflow: auto;
      margin: 0;

      &.lg {
        max-width: 900px;
      } 

      &.md {
        max-width: 600px;
      }

      &.sm {
        max-width: 400px;
      }

      @media screen and (min-width: 991px) {
        max-height: calc(80vh);
        width: unset;
        margin: 0 1em;
        max-width: 90%;
      }

      &.off-white-bg {
        background-color: $background;
      }
    }

    &_header {
      &_wrapper {
        display: flex;
        justify-content: space-between;
        padding: 24px 2em 0 2em;
      }

      &_title {
        color: $navy;
        font-weight: 600;
        font-size: $fs-24;
        line-height: normal;
      }

      &_close-btn {
        &_wrapper {
          background: $border;
          // if we hide the header we still need the close button
          // p-abs allows it to remain in the corner so a custom
          // title can be used at the content level
          position: absolute;
          top: 24px;
          right: 2em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          max-height: 34px;
          max-width: 40px;
          min-height: 34px;
          min-width: 40px;
          border-radius: 6px;
          transition-duration: 0.2s;
          opacity: 0.7;

          &:hover {
            transition-duration: 0.2s;
            opacity: 1;
          }

          &:disabled {
            cursor: unset;
            opacity: 0.5;
          }
        }

        &_icon {
          color: $txt-content;
          width: 16px;
        }
      }
    }

    &_content {
      &_wrapper {
        padding: 0 2em 2em 2em;
      }
    }
  }
}

.success-modal {
  &_wrapper {
    display: flex;
    background: white;
    flex-direction: column;
    width: 250px;

    @media screen and (min-width: 991px) {
      width: 400px;
    }
  }

  &_main-icon {
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #6790a6;
  }

  &_footer-button {
    margin-top: auto;
    background: #6790a6;
    color: white;
    font-size: 24px;
    font-weight: 700px;
    padding: 0.7em 0;
    transition-duration: 0.2s;

    &:hover {
      filter: contrast(120%);
    }
  }
}

.simple-success-modal {
  &_wrapper {
    display: flex;
    background: white;
    flex-direction: column;
    width: 250px;
    border-radius: 6px;

    @media screen and (min-width: 991px) {
      width: 380px;
    }

    .modal_inner_header_close-btn_wrapper {
      top: 1.5em !important;
      right: 1.5em !important;
    }
  }

  &_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #6790a6;
    padding: 2.5rem;

    img {
      margin-top: 1.5rem;
      width: 42px;
    }

    h2 {
      margin-top: 1.5rem;
    }

    p {
      color: #6790a6;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
}
