.topic-tooltip {
  bottom: 1em !important;

  .tooltip-inner {
    padding: 10px;
    font-size: $fs-14;
    font-weight: 500;
  }
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

.thanks {
  background-color: white;
  transform: translate3d(50%, 50%, 20px);
  width: 400px;
  z-index: 20000000000000000;
  font-size: $fs-14;
  font-weight: 500;
  box-shadow: 1px 2px 4px 2px rgba(178, 178, 178, 0.5);
  inset: auto auto 0px 0px;
  border-radius: 4px;
  animation: fadeInAnimation 0.25s ease-in;

  h5 {
    font-weight: 700;
    color: $txt-gray-50;
    margin-bottom: 0px;
  }

  &_counter {
    color: $navy;
    font-weight: 600;
    font-size: $fs-16;

    &.fadein {
      animation: fadeInAnimation 0.25s ease-in;
    }

    &.fadeout {
      animation: fadeOutAnimation 0.25s ease-out forwards;
    }
  }

  &_close-btn-wrapper {
    background: #e8e9ee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition-duration: 0.2s;
    opacity: 0.7;
    padding: 5px;

    &:hover {
      opacity: 1;
      transition-duration: 0.2s;
    }
  }

  &::before {
    display: none;
  }

  .arrow,
  .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  .arrow {
    visibility: hidden;
  }

  .arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  &[data-popper-placement^='top'] > .arrow {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: -4px;
  }

  &[data-popper-placement^='left'] > .arrow {
    top: -4px;
  }

  &[data-popper-placement^='right'] > .arrow {
    top: -4px;
  }
}
