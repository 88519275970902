body:not([data-show-simulation='true']) {
  #mobile,
  #main {
    &-nav-item-simulation {
      display: none;
    }
  }
}

body[data-show-notification='true'] {
}
