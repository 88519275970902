.fd-accordion-header {
  width: 100%;
}

.fd-accordion-body {
  .card-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.fd-accordion-item {
  &:last-child {
    .fd-accordion-body .card-body {
      padding-bottom: 1rem !important;
    }
  }
}

.fd-accordion-body-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 300ms ease-out, opacity 300ms ease-out;
}

.fd-accordion-body-enter-active {
  max-height: 500px; /* Adjust based on your content */
  opacity: 1;
}

.fd-accordion-body-exit {
  max-height: 500px; /* Adjust based on your content */
  opacity: 1;
}

.fd-accordion-body-exit-active {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 300ms ease-out, opacity 300ms ease-out;
}
