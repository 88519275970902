.custom-check {
  position: relative;
  padding-left: pxToRem(32);
  &__input,
  &__box {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;

    &:hover {
      cursor: pointer;
    }
  }
  &__input {
    opacity: 0;
    &:checked {
      + .custom-check__box {
        background-color: #6790a6;
        border-color: #6790a6;
        background-image: none;
        box-shadow: inset 0px -1px 1px rgba(16, 22, 26, 0.2);
        &::after {
          opacity: 1;
        }
      }
    }
    &[disabled] {
      + .custom-check__box {
        border-color: #cdced0;
        color: #888585;
        background-color: #e1e8ec !important;
        background-image: linear-gradient(180deg, transparent 0%, transparent 100%);
        &::after {
          color: #888585;
        }
        &--indeterminate::after {
          background-color: #888585;
        }
        &:hover {
          cursor: default;
        }
      }
    }
  }
  &__box {
    z-index: 2;
    background-image: linear-gradient(180deg, #ffffff 0%, #fbfafa 100%);
    border: 1px solid #cccecf;
    border-radius: 3px;

    transition: 0.05s ease-in-out;

    &::after {
      position: absolute;
      top: -2px;
      left: 3px;
      width: 100%;
      height: 100%;
      content: '';
      background-size: 9px auto;
      background-position: center;
      opacity: 0;
      transition: 0.05 ease-in-out;

      font-family: 'FontAwesome';
      border-radius: 4px;
      content: '✓';
      color: #fff;
    }

    &--indeterminate {
      background-color: #6790a6;
      border-color: #6790a6;
      box-shadow: inset 0px -1px 1px rgba(16, 22, 26, 0.2);
      background-image: none;
      &::after {
        opacity: 1;
        background-image: none;
        background-color: white;
        width: 6px;
        height: 2px;
        border-radius: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &__label {
    color: #504141;
  }

  &--navy {
    .custom-check__box {
      background-color: $navy !important;
    }
  }
}
