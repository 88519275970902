& {
  background-color: #ffffff;

  @include media-breakpoint-down(md) {
    padding: 2.25rem 0;
  }
}

.image-col {
  @include media-breakpoint-up(lg) {
    width: 63.61%;
    background: gray no-repeat center / cover;
  }

  img {
    @include media-breakpoint-up(lg) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.login-content {
  .form-wrapper {
    min-height: 220px;
    width: 270px;
    margin: 0 auto;
  }

  @include media-breakpoint-down(md) {
    //padding-top: 0;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding-top: 3rem;
    padding-bottom: 8rem;
  }

  @include media-breakpoint-up(xl) {
    //padding-top: 14.125rem;
  }
}

form {
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    margin: 2rem auto 0;
  }

  .forgot-password {
    text-align: right;

    a {
      display: block;
      margin-top: 0.5rem;
      color: $navy-active;
      font-family: 'TTCommons', sans-serif;
      font-size: 0.75rem;
      cursor: pointer;
    }
  }
}

.modal_inner_wrapper {
  border-radius: 6px;
  box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.06);
}

.modal_inner_header_wrapper {
  display: none;
}

.modal_inner_content_wrapper {
  padding-top: 1.5em;
}

.disabled-account-modal {
  width: pxToRem(340);
  margin: 0 auto !important;
  text-align: center;

  .main-title {
    color: $navy;
  }

  .description {
    color: $navy-75;
    font-weight: 500;
    margin-bottom: 1.5rem;
    margin-top: 0.8rem;

    a {
      display: inline;
    }
  }

  button {
    margin-bottom: 0.6rem;
  }
}

.partner-select {
  &_wrapper {
    border-radius: 4px;
    border: 1px solid #d4d4d4;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    width: 270px;
    max-height: 4.5 * 42.5px;
    color: #616060;
    margin-left: auto;
    margin-right: auto;
    overflow-y: auto;
  }

  &_item {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;

    i {
      display: none;
    }

    &:hover {
      background-color: #f5f6fa;
      cursor: pointer;

      i {
        display: block;
      }
    }

    &:first-of-type {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-of-type {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
