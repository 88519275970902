.page-header {
  z-index: 1;

  @include media-breakpoint-down(md) {
    position: relative;
  }
  @include media-breakpoint-up(lg) {
    @include absolute(top 0 left 0);
    width: 100%;
  }

  .btn-back {
    @include media-breakpoint-down(md) {
      padding: 0 1.25rem;
    }
    @include media-breakpoint-up(lg) {
      @include flex-parent($jc: center, $ai: center);
      width: pxToRem(38);
      height: pxToRem(38);
      margin: 1.875rem 0 0 1.25rem;
      box-shadow: 0 5px 11px rgba(210, 210, 210, 0.32);
      border-radius: pxToRem(19);
      border: 1px solid #dcdcdc;
      background-color: #ffffff;
    }
  }
}
