.user-nav {
  position: relative;
  z-index: 2;
  &__sub {
    &.closed {
      display: none;
    }

    min-width: 150px;
    width: 100%;
    background: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: absolute;
    top: calc(100%);
    left: 100%;
    transform: translateX(-100%);
    text-align: left;
    z-index: 5;
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    // overflow: hidden;
    transition: 0.2s ease-in-out;
    padding: 16px 20px;
    padding-left: 14px;
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 10px solid #fff;
      position: absolute;
      right: 20px;
      top: -10px;
    }
    li {
      white-space: nowrap;
      display: flex;
      justify-content: unset;
      align-items: center;
      padding-bottom: 20px;
    }
    li:last-child {
      padding-bottom: 0;
    }
    a,
    button {
      color: $txt-content;
      display: flex;
      align-items: center;
      &:hover {
        color: $black;
      }
    }
  }
  &:hover {
    .user-nav__sub {
      display: block;
      opacity: 1;
      max-height: pxToRem(200);
    }
  }
  img {
    margin-right: 15px;
    width: 16px;
  }
  img.iconLogout {
    width: 14px;
  }
  span {
    margin-top: 3px;
  }
}
