.fd-paginator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-top: 2rem;

  &__page-size {
    color: #504141;
    font-size: pxToRem(15);
    margin-top: 2px;
  }

  .page-item {
    width: 1.4rem;
    height: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.next, &.previous {
      &.disabled {
        opacity: .5;
      }
    }

    &.next {
      margin-left: -2px;
    }

    &.previous {
      svg {
        transform: rotate(180deg);
      }
    }

    .page-link {
      background-color: transparent;
      border: none;
      color: #504141;
      padding: 0;
      padding-top: 2px;

      &:focus {
        box-shadow: none;
      }
    }

    &.active {
      .page-link {
        color: $white;
        background-color: $orange;
        border-radius: 50%;
        width: 1.4rem;
        height: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}