.campaign-preview {
  &_wrapper {
    margin-top: 2em;
    display: flex;
    border: 1px solid $border-color;
    border-radius: 6px;
    max-width: calc(100%);
    flex-direction: column;

    @media screen and (min-width: 600px) {
      flex-direction: row;
    }

    & > * {
      overflow: hidden;
      text-overflow: ellipsis;
      height: 100%;
    }
  }

  &_img {
    &_wrapper {
      overflow: hidden;
      min-height: calc(100%);
      max-height: calc(100%);
      height: calc(100%);
      max-width: 320px;
      min-width: 300px;

      //   width: 250px;
      @media screen and (min-width: 600px) {
        max-width: 250px;
        min-width: 250px;
        width: 250px;
      }
    }

    & > img {
      object-fit: cover;
      min-width: 100%;
      max-width: 100%;
      min-height: 100%;
      max-height: 100%;
    }
  }

  &_description {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    max-width: calc(100%);
    width: 100%;

    &_title {
      padding: 0.8em 1em;
      color: $txt-header;
      // display: block;
      border-bottom: 1px solid $border-color;
      background-color: $background;
      max-width: calc(100% - 1px);
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &_description {
      width: calc(100%);
      flex-grow: 1;
      padding: 0 1em 1em 1em;
      color: $txt-header-2;
      margin-top: 1em;
      //   height: 150px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.campaign-social-share {
  &_first-screen {
    &_icons_wrapper {
      border-top: 1px solid $border-color;
      display: flex;
      flex-wrap: wrap;
      padding: 1.5em;
      gap: 1.5em;
    }
  }

  &_second-screen {
    padding: 1.5em;
    min-width: 300px;
    max-width: 320px;

    @media screen and (min-width: 600px) {
      min-width: 500px;
      max-width: 550px;
    }
  }

  &_preview-text {
    &_wrapper {
      margin: 2em 0 0 0;
      max-width: 100%;
    }

    &_content {
      margin-top: 6px;
      border-left: 4px solid $orange;
      background: $background;
      padding: 1em 6px;
      color: $txt-gray-50;
    }
  }

  &_image {
    &_card-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition-duration: 0.2s;

      &:hover {
        cursor: pointer;
        filter: contrast(120%);
      }

      @media screen and (min-width: 991px) {
        max-width: 150px;
        min-width: 130px;
        height: 120px;
      }
    }

    &_large {
      &_wrapper {
        min-width: 45px;
        max-width: 45px;
        min-height: 45px;
        max-height: 45px;
        border-radius: 50%;
        display: flex;
        overflow: hidden;
        transition-duration: 0.2s;

        @media screen and (min-width: 991px) {
          min-width: 60px;
          max-width: 60px;
          min-height: 60px;
          max-height: 60px;
        }
      }
    }

    &_small {
      &_wrapper {
        min-width: 30px;
        max-width: 30px;
        min-height: 30px;
        max-height: 30px;
        border-radius: 50%;
        display: flex;
        overflow: hidden;
      }
    }

    &_title {
      margin-top: 1em;
    }
  }
}
