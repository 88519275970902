.multi-select {
  &_menu_toggle {
    &_apply_cancel {
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      & button {
        padding: 0 !important;
        color: $txt-header-2;
        display: flex;
        align-items: center;
        &.apply {
          color: $orange;
          justify-content: flex-end;
        }
      }
    }
    &_btn {
      color: $txt-header-2;
      display: flex;
      flex-direction: column;
      align-self: flex-end;
      justify-content: center;
      align-items: center;
      background: transparent;
      width: 36px;
      margin-left: 1em;
      height: 30px;
      padding: 4px 2px;
      border-radius: 6px;
      &.active {
        background: $orange;
      }
      &:hover {
        cursor: pointer;
      }
      &-each-line {
        background-color: $orange;
        height: 2px;
        &:nth-child(1) {
          width: 20px;
        }
        &:nth-child(2) {
          width: 12px;
          margin-top: 3px;
        }
        &:nth-child(3) {
          width: 7px;
          margin-top: 3px;
        }
        &.active {
          background: #fff;
        }
      }
    }

    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 0.5rem;
    }
    &_text {
      color: $txt-content;
      margin-left: 6px;
      padding-top: 6px;
    }
  }

  &-each-item {
    color: $txt-header-2;

    &_wrapper {
      position: relative;
    }

    &_each {
      color: $txt-header;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      display: flex;

      padding: 0;
      position: relative;
      width: 100%;
      margin-right: 4px;
      &:hover {
        cursor: pointer;
      }

      &:last-child {
        padding-right: 0px;
      }
    }

    &_title {
      color: $txt-header;
      text-align: left;
      font-weight: 500;
      font-size: 18px;

      margin-top: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      &_selected {
        &.active {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-left: 6px;
          background: $orange;
          margin-bottom: 3px;
        }
      }
    }
  }

  &_toggle-btn {
    margin-left: auto;
    border-radius: 50%;
    background: #3b3b3b;
    height: 14px;
    width: 14px;
    &.active {
      background: $orange;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    width: 36px;
    margin-left: 0.5em;
    height: 30px;
    padding: 4px 2px;
    border-radius: 6px;
    &.active {
      background: $orange;
    }

    &-each-line {
      background-color: $orange;
      height: 2px;
      &:nth-child(1) {
        width: 20px;
      }
      &:nth-child(2) {
        width: 12px;
        margin-top: 3px;
      }
      &:nth-child(3) {
        width: 7px;
        margin-top: 3px;
      }
      &.active {
        background: #fff;
      }
    }
  }

  &_dropdown-menu {
    &_custom-checkbox {
      position: relative;
      display: block;
      margin-top: 4px;
      margin-bottom: 4px;
      line-height: 20px;
      & > * {
        border-radius: 4px;
      }
      &:hover {
        cursor: pointer;
        & span {
          color: #464646;
        }
      }

      & span {
        display: block;
        margin-left: 20px;
        padding-left: 12px;
        padding-top: 2px;
        line-height: 20px;
        text-align: left;
      }

      & span::before {
        content: '';
        display: block;
        position: absolute;
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
        top: 0;
        left: 0;
        background: transparent;
        border: 1px solid #d4d4d4;
        border-radius: 4px;
      }
      & span::after {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
      }
      & input[type='checkbox'] {
        opacity: 0;
        z-index: -1;
        position: absolute;
      }
      & input[type='checkbox']:checked + span::after {
        font-family: 'FontAwesome';
        padding-top: 1px;
        padding-left: 1px;
        border-radius: 4px;
        content: '✓';
        background: $orange;
        color: #fff;
      }
    }

    &_container {
      -webkit-padding-start: 0px;
      background: #fff;
      height: max-content;
      min-width: 100%;
      display: inline-table;
      min-height: 100%;
      min-height: unset;
      position: absolute;
      min-width: 300px;
      z-index: 40000000;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
      left: 0;
      top: 47px;
      &.right {
        left: unset;
        right: 0;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &_item {
      width: 100%;
      display: flex;
      padding: 4px;
    }
    &_buttons {
      list-style-type: none;
      &-wrapper {
        margin-top: 1em;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        button {
          flex: 1;
          span {
            font-size: pxToRem(18);
          }
        }
      }
      &_cancel {
        display: block;
        font-weight: 500;
        padding: 8px 14px 6px 14px;
        border: 1px solid #aaaaaa;
        color: $txt-header-2;
      }
      &_apply {
        display: block;
        font-weight: 500;
        padding: 8px 14px 6px 14px;
        background: $orange;
        color: #fff;
        &:hover {
          color: #fff;
        }
      }
    }

    &_status {
      &_row {
        width: 100%;
        display: flex;
      }
    }

    &_source {
      &_row {
        width: 100%;
        display: flex;
      }
    }

    &_dates {
      &_select_input_wrapper {
        padding: 10px 6px 8px 6px;
        border-radius: 6px;
        border: 1px solid #c1c5d3;
        display: flex;
        align-items: center;
      }

      &_select-option {
        padding: 8px 6px;
        transition-duration: 0.2s;
        &:hover {
          background: $background;
        }
      }

      &_select-menu {
        &_outer {
          position: relative;
        }
        &-wrapper {
          background: #fff;
          display: none;
          position: absolute;
          &.open {
            width: 100%;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            z-index: 2000000000000;
            border: 1px solid #c1c5d3;
            border-top: none;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
          }
        }
      }

      &_input {
        margin-top: 6px;
        padding: 6px;
        padding-top: 10px;
        border-radius: 6px;
        border: 1px solid #c1c5d3;
        display: flex;
        & input {
          &:invalid {
            color: rgb(245, 102, 102);
          }
          & [type='date']::-webkit-inner-spin-button {
            display: none;
          }
          &[type='date']::-webkit-calendar-picker-indicator {
            display: none;
          }
        }
      }

      &_row {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        @media only screen and (min-width: 600px) {
          flex-direction: row;
          justify-content: space-evenly;
        }
        &_calendar_left {
          height: 365px;
          margin-right: 6px;
        }
        &_calendar_right {
          height: 365px;
          margin-left: 6px;
        }
      }
    }

    &_inner {
      margin-top: 0.2em;
      height: 120px;
      overflow-x: hidden;
    }
    &_row {
      display: flex;
      align-items: center;
      text-align: left;
      margin: 2px 0;
      line-height: 18px;
    }

    &_searchbar {
      &_wrapper {
        padding-bottom: 8px;
      }
      &_input {
        display: flex;
        padding: 8px 16px 4px 4px;
        border-radius: 4px;
        border: 1px solid #cfcfcf;

        & i {
          color: #bebebe;
        }
      }
    }
  }
}
