.messagesList-page .card {
  min-height: 50vh;
}
.message-list {
  font-family: 'TTCommons', sans-serif;
  font-size: 1rem;
  position: relative;
  min-height: 50vh;

  &__header,
  &__item {
    margin: 0 1rem;
    padding: 1rem 0;
    border-bottom: 1px solid #94c7e7;
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 5;
    color: $txt-header-2;
  }

  &__item {
    color: $light-black;
    transition-duration: 0.3s;
    font-weight: 600;
    cursor: pointer;
    &--read {
      color: $txt-content;
      font-weight: 500;
    }
    &:hover {
      color: $orange;
      transition-duration: 0.3s;
    }
  }

  &__no-results {
    color: $txt-header-2;
  }
  &__nav {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .bt {
      margin: 0.5rem;
      width: 40%;
      max-width: 150px;
      min-width: 110px;
    }
  }
}
