.searchbar-comp {
  &_wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    padding: 6px;
    // margin-left: 6px;
    // margin-right: 6px;
    border-radius: 6px;
    border: 1px solid $input-border;
    background: white;
    transition-duration: 0.2s;
    transform: max-width ease-in-out;
    width: 100%;

    &.focused {
      border: 1px solid $border-event-search;
    }

    &--left-padded {
      padding-left: 1em;
    }

    &--right-padded {
      padding-right: 1em;
    }

    @media screen and (min-width: 991px) {
      width: 100%;
      // margin-left: 0px;
      // margin-right: 0px;
    }
  }

  &_inner-input {
    flex-grow: 1;
    border: none;
    // min-width: 100%;
    // max-width: 100%;
    color: $txt-content;
    margin: 0;
    padding: 0;
    word-wrap: break-word;
    outline: none;
    display: flex;
    -webkit-tap-highlight-color: transparent;
    height: 26px;
    margin-bottom: -3px;
    font-size: 1rem;
    padding-left: 0.325rem;
    flex: 100%;

    &:focus {
      border: none;
      outline: none;

      &:not(.sticky-placeholder) {
        &::placeholder {
          opacity: 0;
        }
      }
    }
  }

  &_icon {
    width: 16px;
  }
}
