.accordion {
  &_outer {
    &_wrapper {
      border: 1px solid #c1c5d3;
      border-radius: 8px;
    }
  }

  .card-header {
    cursor: pointer;
  }

  &_header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    color: $txt-header;
    padding: 1em 1.2em 0.5em 1.2em;

    &:hover {
      cursor: pointer;
    }

    & h4 {
      font-size: 18px;
      color: $txt-header-125;
      font-weight: 600;
    }
  }

  &_arrow {
    width: 0px;
    height: 0px;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid $arrow;
    transition-duration: 0.2s;
    transform: rotate(0);

    &:hover {
      border-top: 9px solid $txt-content;
      cursor: pointer;
    }

    &.active {
      transform: rotate(180deg);
    }
  }

  &_content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s;

    @media print {
      max-height: 800px;
    }

    &.open {
      max-height: 800px;
      transition: max-height 0.5s ease-in-out;
    }
  }
}
