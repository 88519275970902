.error-message {
  padding-top: 1.05rem;
  > :last-child {
    margin-bottom: 0;
  }
}

.alert {
  &_close-btn {
    &_icon {
      color: #6f1f13;
      width: 12px;
      path {
        stroke: #6f1f13;
      }
    }
  }
}
