& {
  background-color: #ffffff;

  @include media-breakpoint-down(md) {
    padding: 2.25rem 0;
  }
}

.image-col {
  @include media-breakpoint-up(lg) {
    width: 63.61%;
    background: gray no-repeat center bottom / cover;
  }

  .image-container {
    img {
      @include media-breakpoint-up(lg) {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.forgot-password-content {
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding-top: 3rem;
    padding-bottom: 8rem;
  }
}

form {
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    margin: 6rem auto 0;
  }
}
