.search-dropdown {
  position: relative;

  .accordion {
    .card {
      border-radius: 4px;
      border: 1px solid rgba(178, 178, 178, 0.5) !important;
      cursor: pointer;
      max-height: 200px;
      overflow-y: auto;

      .card-header {
        padding: 12px 12px 8px 12px;
        margin: 0;
      }

      &.disabled {
        cursor: default;
        border-color: #cdced0;
        background-color: #e1e8ec;

        div {
          color: #888585;
        }
      }

      &.disabled_single {
        cursor: default;
        border-color: #e8e9ee !important;

        div {
          color: #a1a1a1;
          font-weight: 500;
        }
      }

      .align-accordion-arrow {
        display: flex;
        margin-top: 10px;
        align-items: flex-start;
      }
    }
  }

  &_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #504141;
    gap: 0.5em;
    width: 100%;

    &_selected {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }

      &_text {
        display: flex;
        white-space: nowrap;

        @media #{$navbreak-max} {
          flex-direction: column;
        }

        .audience-name {
          &.follow-up {
            &::after {
              content: '\00a0;\00a0';
            }
          }
        }
      }
    }

    .remove {
      transition-duration: 0.2s;

      &:hover {
        cursor: pointer;
        opacity: 1;
        filter: contrast(120%);
        transform: scale(1.2);
        transition-duration: 0.2s;
      }

      svg {
        path {
          fill: $arrow;
        }
      }
    }
  }

  &_multiselect {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    width: 100%;

    &_title {
      display: flex;
      gap: 0.5em;
      background: #f5f6fa;
      width: fit-content;
      padding: 4px 8px;
      border-radius: 4px;

      .remove {
        transition-duration: 0.2s;

        &:hover {
          cursor: pointer;
          opacity: 1;
          filter: contrast(120%);
          transform: scale(1.2);
          transition-duration: 0.2s;
        }

        svg {
          path {
            fill: $arrow;
          }
        }
      }
    }
  }

  &_try-again {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px;
    padding-top: 0;
    gap: 2em;

    @media #{$navbreak-max} {
      flex-direction: column;
      gap: 1em;
    }

    .error {
      color: $red;
    }
  }

  &_dropdown {
    background: $white;
    position: absolute;
    margin-top: 2px;
    top: 40px;
    width: 100%;
    border: 1px solid rgba(178, 178, 178, 0.5) !important;
    border-radius: 4px;
    z-index: 10;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    height: fit-content;
    max-height: 140px;

    &.audience {
      max-height: 180px;
    }

    &_searchbar {
      padding: 12px;

      .searchbar-comp_wrapper {
        border-radius: 4px;
        border: 1px solid rgba(178, 178, 178, 0.5) !important;
      }
    }

    &_results {
      &_item {
        display: flex;
        flex-direction: row;
        gap: 1em;
        align-items: center;
        width: 100%;
        padding: 12px 12px 8px 12px;
        margin: 0;

        input[type='checkbox'] {
          appearance: none;
          width: 1em;
          height: 1em;
          cursor: pointer;
          background: $white !important;
          border-radius: 3px;
          border: 1px solid $navy;

          &:checked {
            background: $navy !important;
            position: relative;
          }

          &::after {
            content: '✓';
            color: white;
            display: block;
            position: absolute;
            top: 10%;
            left: 10%;
            transform: scale(0);
            transition: 100ms transform ease-in-out;
          }

          &:checked::after {
            transform: scale(1);
          }
        }

        input[type='radio'] {
          appearance: none;
          width: 1em;
          height: 1em;
          background: $white !important;
          border: 1px solid $navy;
          border-radius: 50%;
          cursor: pointer;

          &:checked {
            width: 0.9em;
            height: 0.9em;
            background: $navy !important;
            border: 2px solid $white;
            outline: $navy solid 1px;
          }
        }

        &_default {
          display: flex;
          flex-direction: column;
          width: 100%;

          &:hover {
            background-color: #f5f6fa;
            cursor: pointer;
          }
        }

        &_label {
          display: flex;
          flex-direction: column;
          gap: 0.2em;
          font-weight: 400;
          font-size: 16px;
          color: $black;
        }

        &_recommended {
          font-weight: 600;
        }

        &_sub {
          font-size: 15px;
          color: rgba(0, 0, 0, 0.7);
        }

        &.no-hover {
          pointer-events: none;
        }

        &:hover {
          background-color: #f5f6fa;
          cursor: pointer;
        }
      }

      .divider {
        height: 1px;
        background-color: rgba(178, 178, 178, 0.5) !important;
        margin: 8px 12px;
      }
    }
  }
}
