.notifications-page {
  .main-title {
    margin-bottom: 2rem;
  }
  .mainContent-row {
    @media (min-width: 767px) and (max-width: 991px) {
      margin-bottom: 4rem;
    }
    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  .notificationsSettings__col {
    max-width: 350px;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
  .notificationsSettings__col .card-body,
  .notificationsSettings__col .card-header {
    padding-left: 30px;
    padding-right: 25px;
  }

  @media (max-width: 767px) {
    .row .card-body,
    .row .card-header {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }

  .notificationsList {
    color: #3b3b3b;
    padding-left: 0;
    padding-right: 0;

    &__headerBox {
      display: flex;
      padding: 20px 40px 17px 40px;
      border-bottom: 1px solid #cacdd8;
      @media (max-width: 400px) {
        justify-content: space-between;
      }
      @media (max-width: 300px) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    &__header {
      display: block;
      text-align: left;
      font-weight: 500;
      font-size: $fs-18;
      margin-right: 40px;
      position: relative;
      cursor: pointer;
      @media (max-width: 566px) {
        margin-right: 10px;
      }
      @media (max-width: 300px) {
        margin-bottom: 20px;
      }
    }
    &__header.active::after {
      display: block;
      position: absolute;
      content: '';
      height: 2px;
      width: auto;
      background-color: #949ec6;
      bottom: -18px;
      left: -5px;
      right: -5px;
      @media (max-width: 300px) {
        bottom: -5px;
      }
    }
    &__groupBox {
      padding: 20px 0px;
      padding-bottom: 15px;
      border-bottom: 1px solid #ebebeb;
      &:last-child {
        border-bottom: 0px;
      }
    }
    &__groupHeader {
      padding-left: 2.5em;
      padding-right: 2.5em;
      font-size: $fs-18;
      font-weight: 500;
      @media (max-width: 767px) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
      }
    }
    .notificationItem {
      display: flex;
      align-items: center;
      padding: 8px 0px;
      text-align: left;
      font-size: $fs-16;
      line-height: pxToRem(18);
      color: #3b3b3b;
      padding-left: 2.5em;
      padding-right: 2.5em;
      margin-top: 7px;
      @media (max-width: 767px) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
      }

      &:hover {
        background-color: #f7f7fb;
      }
      &__itemAvatar {
        border-radius: 50px;
        background-color: #d9efff;
        color: #1f5274;
        line-height: 0;
        padding: 14px;
        padding-top: 20px;
        margin-right: 16px;
        width: 36px;
        height: 36px;
      }
      &__itemAvatar--v1 {
        background-color: #d9efff;
        color: #1f5274;
      }
      &__itemAvatar--v2 {
        background-color: #dcdbff;
        color: #5553c0;
      }
      &__itemAvatar--v3 {
        background-color: #ffeded;
        color: #d04545;
      }
      &__itemTitle {
        display: inline-block;
      }
      &__itemCategory {
        display: inline-block;
        padding-left: 5px;
        font-weight: 600;
      }
      &__itemTime {
        display: block;
        font-weight: 300;
        @media (max-width: 567px) {
          display: inline-block;
          padding-left: 5px;
        }
      }
    }
  }

  .notificationsSettings {
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__iconBox {
      width: 40px;
      min-width: 40px;
      height: 40px;
      background-color: #f6f6f8;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
    }
    &__itemTitle {
      width: 100%;
      text-align-last: left;
    }
    .custom-switch--desktop {
      transform: scale(1.5);
      justify-self: end;
      margin-left: 20px;
    }
  }
}
