.copy-to-clipboard {
  display: flex;
  gap: 10px;
  align-items: center;

  &__btn-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #1F5274;
    font-weight: 500;
  }

  button {
    display: flex;
    align-items: center;
    span {
      color: #3b3b3b;
      margin-top: -6px;
      display: block;
    }
  }

  &__copied {
    display: flex;
    align-items: center;
    margin-top: -2px;

    svg {
      opacity: 1 !important;
      width: 12px;
      height: 12px;
      margin-top: -2px;

      path {
        stroke: #1F5274 !important;
      }
    }

    span {
      color: #1F5274 !important;
    }
  }

  .copy-icon {
    margin-top: -2px;
  }
}
