.drop-zone {
  cursor: pointer;
  text-align: center;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #838383;
  font-size: $fs-16;
  line-height: pxToRem(19);
  .icon {
    margin-right: 5px;
    font-size: 20px;
    display: inline-block;
    vertical-align: sub;
  }

  &:hover {
    color: #137cbd;
  }
}

.file-list {
  &__item {
    border-bottom: 1px solid $border;
    margin-bottom: pxToRem(4);
    padding-right: pxToRem(20);
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__name {
    font-size: pxToRem(12);
    line-height: pxToRem(12);
    color: #504141;
  }

  &__status {
    margin-left: 12px;
    font-size: pxToRem(12);
    line-height: pxToRem(12);
    color: #504141;

    &--loading {
      animation: mediaUploading 0.75s linear infinite alternate;
    }
  }

  &__remove {
    position: absolute;
    bottom: 2px;
    right: 2px;
    color: $orange;
    &:hover,
    &:focus {
      color: #f17852;
    }
    &:active {
      color: #d15c37;
    }
  }
}

@keyframes mediaUploading {
  0% {
    color: #137cbd;
  }
  100% {
    color: #504141;
  }
}
