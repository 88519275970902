@import '../modules/campaign-form';

.merge-content {
  text-align: center;
  color: $navy;
  padding: 1.5rem 1rem;
  max-width: 450px;

  p {
    color: $navy;
  }

  h2 {
    font-size: 1.3em;
  }

  .modal_inner_header_wrapper {
    justify-content: center;
    padding: 0;
    padding-bottom: 1.5em;
  }

  .highlighted-list {
    padding-top: 0.25em;
    width: 50%;
    margin: auto;
  }

  .highlighted-list-item {
    padding: 0 12px;
    display: list-item;
    list-style-position: inside;
    text-align: start;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.package-builder-page {
  background-color: #f5f6fa;

  @media #{$navbreak-max} {
    margin-bottom: 55px;
    min-height: 100vh;
  }

  &_outer-wrapper {
    color: $txt-header-2;
    padding: 1em;

    @media only screen and (min-width: 992px) {
      min-height: 250px;
      padding: 1.5em;
      display: flex;
      flex-direction: column;
    }

    &.customer-segmentation-card {
      min-height: unset;
    }
  }

  &_results {
    padding: 1.25rem;
  }

  &_sub-headlines {
    color: $txt-header;
    font-weight: 700;
    max-width: 100%;
    font-size: pxToRem(22);
    margin-bottom: 0;
  }

  &_dashboard {
    &_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      & p {
        color: $txt-content;
      }
    }
  }

  &_previous-packages {
    &_title-header {
      &_wrapper {
        & h4 {
          @media screen and (max-width: 992px) {
            font-weight: 600;
            color: $navy-125;
            line-height: 32px;
          }
        }

        margin-top: 2em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        position: relative;
      }

      &_more-dots {
        width: 30px;
        transform: translateY(-6px);
      }
    }

    &_header {
      width: 100%;

      @media screen and (min-width: 600px) {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        width: unset;
      }
    }

    &_table {
      margin-top: 3.6em;

      th,
      td {
        border-top: 0;
        vertical-align: baseline;
        width: fit-content;

        @media screen and (max-width: 300px) {
          padding-left: 0;
        }
      }

      thead th {
        border: 0;
        vertical-align: bottom;
        color: #3b3b3b;
        font-weight: 500;
        font-size: $fs-16;
      }

      tr {
        border-bottom: 1px solid rgba(31, 82, 116, 0.5);
      }

      &_title {
        max-width: 190px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media #{$sm-max} {
          width: 120px;
        }

        @media screen and (max-width: 350px) {
          width: 100px;
          padding-left: 0;
        }
      }

      &_merged-badge {
        // border: 1px solid blue;
        width: max-content;
        min-width: 65px;
        display: block;
        text-align: center;

        strong {
          white-space: nowrap;
          max-width: 130px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;

          @media #{$xl-max} {
            max-width: 90px;
          }
        }

        .icon {
          width: 20px;
        }

        .badge {
          background: $red;
          color: white;
          font-size: 14px;
          padding: 6px 10px 4px 10px;
        }

        @media screen and (max-width: 440px) {
          display: none;
        }

        @media screen and (min-width: 600px) {
          text-align: left;
        }

        @media #{$sm-max} {
          width: 20px;
        }
      }

      &_variations-number {
        // border: 1px solid blue;
        display: none;
        max-width: 30%;
        min-width: 90px;
        text-align: center;

        @media screen and (min-width: 720px) {
          &.indent {
            padding-left: 16px;
          }

          display: flex;
          justify-content: center;
        }

        @media screen and (min-width: 991px) {
          min-width: 130px;
        }
      }

      &_customer-number {
        p {
          max-width: 60px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        @media screen and (max-width: 700px) {
          display: none;
        }
      }

      &_events {
        // border: 1px solid blue;
        min-width: 120px;
        width: 100%;

        @media screen and (max-width: 992px) {
          display: none;
        }

        &_bubbles {
          &_wrapper {
            display: flex;
            line-height: 18px;
            min-width: 20%;
          }

          &_each {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            background: rgba(241, 242, 248, 0.6);
            margin-right: 12px;
            padding: 12px 14px 8px 14px;
            border-radius: 12px;
            max-width: 200px;

            @media #{$xl-max} {
              max-width: 160px;
            }

            &:nth-child(2) {
              display: none;

              @media screen and (min-width: 1200px) {
                display: block;
              }
            }
          }

          &_more {
            white-space: nowrap;
            background: rgba(241, 242, 248, 0.6);
            margin-right: 12px;
            padding: 12px 14px 8px 14px;
            border-radius: 12px;
          }
        }
      }

      &_status {
        white-space: nowrap;
        text-align: end;

        &.icon {
          margin-left: 0;
        }

        &.done {
          color: $green;
          font-weight: 600;
        }

        &.expired {
          color: $orange;
          font-weight: 600;
        }

        &.failed {
          color: $red;
          font-weight: 600;

          svg {
            path {
              stroke: $red;
            }
          }
        }

        &.inprogress,
        &.initializing {
          color: $blue;
          font-weight: 600;
        }

        @media screen and (min-width: 600px) {
          min-width: 50px;
        }

        @media screen and (min-width: 991px) {
          min-width: 80px;
          max-width: 10%;
        }
      }

      &_row {
        display: flex;
        align-items: baseline;
        padding: 1.6em 12px 1em 12px;
        border-bottom: 1px solid $border-event-search;

        &:nth-child(1) {
          border-top: 1px solid $border-event-search;
        }

        &.selected {
          background: rgba(241, 242, 248, 1);
        }

        &:hover {
          background: rgba(241, 242, 248, 1);
          cursor: pointer;
        }

        @media screen and (max-width: 310px) {
          width: 100vw;
        }
      }
    }
  }

  &_name-select {
    &_wrapper {
      & label {
        display: flex;
        align-items: baseline;
        transition-duration: 0.2s;
        border-radius: 4px;
        border: 1px solid transparent;
        width: 100%;
        margin-top: 3.6rem;

        @media screen and (max-width: 992px) {
          margin-top: 2em;
          max-width: 100%;
        }

        &:hover {
          cursor: pointer;
        }

        .input-container {
          min-width: 0;
          display: flex;
        }

        & > span > input {
          font-size: 26px;
          color: $navy;
          font-weight: 600;
          background: none;
          text-overflow: ellipsis;
          flex: 1;
          max-width: 100%;
          width: auto;

          @media screen and (min-width: 600px) {
            font-size: 40px;
          }
        }

        & > span > i {
          color: $navy;
          margin-left: 12px;
        }
      }
    }
  }

  &_row-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1em;

    @media screen and (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &_btns-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (min-width: 600px) {
      justify-content: space-between;
    }
  }

  &_title {
    @media screen and (max-width: 991px) {
      margin-top: 1em;
    }

    color: $txt-content;
    font-size: 18px;
    font-weight: 600;
  }

  &_description {
    @media screen and (max-width: 991px) {
      margin-top: 1em;
    }

    font-size: 16px;
    color: $txt-content;
  }

  &_accordion {
    &_content {
      padding-bottom: 1em;
    }

    &_arrow {
      width: 0px;
      height: 0px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid $arrow;

      &:hover {
        border-top: 7px solid $txt-content;
        cursor: pointer;
      }
    }
  }

  &_table-section {
    &-table-container {
      display: flex;
      flex-direction: column;
      margin-top: 1em;
    }

    &_empty-message {
      color: $txt-content-2;
      text-align: center;
      margin-top: 2em;
    }

    &-header {
      display: flex;
      align-items: baseline;
      padding: 20px 2px;
      border-bottom: 1px solid #4c8fb8;

      &-title {
        color: $txt-content;
        width: 50%;

        @media screen and (min-width: 600px) {
          display: block;
          width: 40%;
        }
      }
    }

    &-row {
      display: flex;
      align-items: baseline;
      border-bottom: 1px solid #4c8fb8;
      padding: 28px 2px 10px 2px;
      line-height: 18px;

      &-title {
        color: $txt-content;
        width: 40%;

        @media screen and (min-width: 600px) {
          display: block;
          width: 40%;
        }
      }

      &-date {
        color: $txt-content;
        text-align: left;
        width: 40%;
        margin-left: 6px;

        @media screen and (min-width: 600px) {
          display: block;
          width: 25%;
        }
      }

      &-location {
        display: none;
        color: $txt-content;
        width: 0%;
        text-align: left;

        @media screen and (min-width: 600px) {
          display: block;
          width: 25%;
        }
      }

      &-cancel {
        color: $txt-content;
        margin-left: auto;

        &:hover {
          cursor: pointer;
          transition-duration: 0.2s;
          color: black;
        }
      }
    }
  }

  &_variations_select {
    &_wrapper {
      display: flex;
      margin-top: 1em;
      align-items: center;
      color: $txt-content;

      & > * {
        color: $txt-content;
      }
    }

    &_drop-down {
      // position: relative;
      &_wrapper {
        display: flex;
        border: 1px solid $arrow;
        padding: 6px 12px 6px 12px;
        border-radius: 4px;
        align-items: center;
        margin-left: 12px;
        width: 60px;

        &.open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: 1px solid transparent;
        }
      }

      &_menu {
        &_wrapper {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 32px;
          right: 0;
          height: 200px;
          width: 60px;
          background: white;
          border: 1px solid $arrow;
          overflow-y: scroll;
        }

        &_row {
          padding: 6px 0px 6px 12px;
          text-align: left;
          white-space: nowrap;

          &:hover {
            cursor: pointer;
            background: rgba(241, 242, 248, 0.6);
          }
        }
      }
    }

    &_input {
      display: flex;
      justify-content: space-between;
      border: 1px solid $arrow;
      padding: 6px 8px 6px 8px;
      border-radius: 4px;
      align-items: center;
      margin-left: 12px;
      width: 60px;
    }
  }

  &_submit-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
    gap: 1rem;

    @media screen and (max-width: 990px) {
      margin-top: 3em;
      flex-direction: column;
      align-self: flex-end;
    }

    & button {
      width: 100%;

      @media screen and (min-width: 600px) {
        width: unset;
      }
    }
  }

  &_variations-section {
    &_container {
      margin-top: 2em;
    }

    &_results-headline {
      color: $navy;
      font-size: $fs-30;
      line-height: pxToRem(28);
      font-weight: 600;
    }

    &_grid {
      // margin-top: 2em;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 991px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 -0.6em;
      }
    }

    &_card {
      color: $txt-content;

      &_container {
        display: flex;
        flex-direction: column;
        background: white;
        border: 1px solid transparent;
        border-radius: $border-radius;
        padding: 1.25em;
        margin-top: 1em;
        margin-bottom: 1em;
        height: 100%;
        cursor: pointer;

        @media screen and (min-width: 991px) {
          min-width: 320px;
          width: 31%;
          // max-width: 600px !important;
          flex-grow: 1;
          margin: 0 0.7em;
          margin-top: 1em;
          margin-bottom: 2em;
          max-width: calc(33% - 2em);
          border-top: 1px solid transparent;

          &:hover {
            border: 1px solid $border-color;
            cursor: pointer;
          }
        }
      }

      // Dark Magenta #8b008b
      // Golden Rod #DAA520
      // Pale Green #98fb98

      &_title-row {
        display: flex;
        justify-content: space-between;
        height: 50px;

        &_headline {
          font-size: $fs-24;
          font-weight: 600;
          color: $txt-content;
        }
      }
    }

    &_topics {
      @media screen and (min-width: 991px) {
        min-height: 200px;
      }
    }

    &_events {
      &_container {
        margin-top: 1em;
        display: flex;
        flex-direction: column;
      }

      &_title {
        color: $txt-content;
      }

      &_row {
        display: flex;
        margin-top: 6px;
        color: $txt-content-2;
      }
    }

    &_btns_container {
      width: 100%;
      margin-top: 1.5em;
    }
  }

  &_progress-bar {
    &_outer {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &_wrapper {
      margin: 2em 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    &_circle {
      &_wrapper {
        background: white;
        position: relative;
        min-height: 20px;
        min-width: 20px;
        max-height: 20px;
        max-width: 20px;
        border: 2px solid $navy;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.6;
        transition-duration: 0.2s;

        &.fill {
          transition-duration: 0.3s;
          transition-delay: 0.1s;
          opacity: 1;
        }

        &:hover {
          cursor: pointer;
          opacity: 1;
          transition-duration: 0.2s;
        }
      }

      &_inner {
        padding: 5px;
        min-height: 8px;
        min-width: 8px;
        max-height: 8px;
        max-width: 8px;
        border-radius: 50%;
        opacity: 0;
        transition-duration: 0.3s;

        &.fill {
          transition-duration: 0.3s;
          transition-delay: 0.1s;
          opacity: 1;
          background: $navy;
        }
      }

      &_title {
        position: absolute;
        color: $navy;
        bottom: 25px;
        left: -4px;
        font-size: 0.8rem;
        max-width: 135px;
        width: 60px;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        word-break: break-word;
        hyphens: auto;

        @media screen and (min-width: 600px) {
          font-size: 0.9rem;
          width: 100px;
        }

        @media screen and (min-width: 800px) {
          font-size: 1rem;
          width: 135px;
        }

        &.center {
          text-align: center;
          left: unset;
        }

        &.last {
          text-align: right;
          right: 0px;
          left: unset;
        }

        @media screen and (min-width: 600px) {
          white-space: wrap;

          &.last {
            text-align: right;
          }
        }
      }
    }

    &_line {
      &_wrapper {
        width: 100%;
        height: 4px;
        border: 1px solid transparent;
        border-left: none;
        border-right: none;
        margin: 0px 2px;
      }

      &_inner {
        width: 100%;
        max-width: 100%;
        height: 100%;
        background: $navy;
        opacity: 0.3;
        transition-duration: 0.3s;

        &.fill {
          max-width: 100%;
          opacity: 1;
          transition-duration: 0.3s;
        }

        &.fill-all {
          max-width: 200%;
        }
      }
    }
  }

  &_build {
    &_columns {
      &_wrapper {
        margin-top: -20px;

        .col {
          margin-top: 20px;
        }

        .col-content {
          background-color: $background;
          border-radius: 5px;
          padding: 1rem;
          height: 100%;
        }
      }

      &_each {
        margin-top: 2em;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: $background;
        border-radius: 5px;
        padding: 20px;

        @media screen and (min-width: 750px) {
          width: unset;
          width: 40%;

          &.center-col {
            width: 20%;
            padding-left: 1em;
          }

          &.right-col {
            width: 30%;
          }
        }
      }

      &_header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        & > button {
          color: $navy;
          font-weight: 500;
        }
      }

      &_row {
        &_wrapper {
          display: flex;
          justify-content: space-between;
          color: $txt-content;
          padding: 6px 0px 8px 0px;

          & p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #575454;
          }

          & time {
            color: #575454;
            white-space: nowrap;
          }
        }

        &.date {
          display: block;
        }
      }
    }
  }

  &_side-drawer {
    &_container {
      overflow-y: auto;
      overflow-x: hidden;
      background: white;
      position: fixed;
      right: 0;
      left: 0;
      top: 0;
      min-width: 300px;
      width: 100%;
      bottom: 50px;
      transform: translateX(100%);
      z-index: 9999999;

      @media screen and (min-width: 991px) {
        z-index: 9999999;
        transition-duration: 0.4s;
        left: unset;
        width: 60%;
        min-width: 400px;
        max-width: 80vw;
        width: 100%;
        bottom: 0;

        &.active {
          transition-duration: 0.4s;
          transform: translateX(0);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }

      @media screen and (min-width: 1200px) {
        max-width: 65vw;
      }

      &.active {
        transform: translateX(0);
      }

      @media print {
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }

    &_header {
      &_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0;
        margin-top: 18px;

        .c-pointer {
          &:hover {
            cursor: pointer;
          }
        }
      }

      &_title {
        font-size: 38px;
        color: $navy;
        font-weight: 600;
      }

      &_close-btn {
        &_wrapper {
          background: $border;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          max-height: 34px;
          max-width: 40px;
          min-height: 34px;
          min-width: 40px;
          border-radius: 6px;
          transition-duration: 0.2s;
          opacity: 0.7;

          &:hover {
            // background: $red-50;
            transition-duration: 0.2s;
            opacity: 1;
          }
        }

        &_icon {
          color: $txt-content;
          width: 16px;
        }
      }
    }

    &_wrapper {
      display: flex;
      flex-direction: column;
      gap: 1em;
      padding: 18px 32px;
    }

    &_events-list {
      .events-card {
        background-color: $background;
        border: none !important;
        overflow: inherit;
        margin: 0;

        &_header {
          font-weight: 500;
          font-size: $fs-18;
          text-align: start;
          display: flex;
          border: none;

          &.open {
            border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
          }

          .whitespace {
            white-space: wrap !important;
          }
        }

        &_topic-title {
          color: $black;
          font-weight: 500;
          font-size: $fs-16;
        }

        .accordion {
          .card {
            border: 1px solid rgba(0, 0, 0, 0.08) !important;
          }
        }

        .card-body {
          padding: 1.25rem;
        }

        .accordion-arrow {
          width: 0px;
          height: 0px;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 7px solid $arrow;
          transition-duration: 0.2s;
          margin-left: auto;
          transform: rotate(0);

          &.open {
            transform: rotate(180deg);
          }
        }

        .disabled-overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto auto;
          width: 80%;
          height: max-content;
          background: white;
          padding: 20px;
          box-shadow: 0px 2px 4px #00000055;
          border-radius: $tooltip-border-radius;
          font-family: 'TTCommons';
          font-size: $fs-14;
          line-height: 20px;
          font-weight: 400;
          color: #505050;
        }

        .event-row {
          &_wrapper {
            display: flex;
            flex-direction: column;
            gap: 0.5em;

            .title {
              color: $txt-header;
              font-weight: 600;
              font-size: $fs-16;
            }
          }

          &_additional-info {
            display: flex;
            align-items: baseline;
            color: $txt-header-2;
            font-size: $fs-16;
            font-weight: 400;
            gap: 0.5em;
            width: 100%;

            @media screen and (max-width: 840px) {
              flex-direction: column;
              gap: 0.25em;
            }
          }

          &_location {
            max-width: 100%;
            display: flex;
            gap: 0.35em;

            @media #{$md-max} {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              word-break: break-word;
              white-space: break-spaces;
            }

            @media screen and (max-width: 681px) {
              max-width: calc(50vw);
            }

            img {
              width: 12px;
              height: 12px;
            }
          }

          &_date-time {
            white-space: nowrap;
            display: flex;
            gap: 0.35em;

            img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }

    &_each-row {
      &_wrapper {
        &.with-border {
          border: 1px solid #c1c5d3;
          border-radius: 6px;
        }
      }

      &_title {
        color: $txt-header;
        font-size: 1.125rem;
        line-height: 1.3125rem;
        font-weight: 600;
        padding: 0.75rem 1.25rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        margin-bottom: 0;
      }

      &_details {
        padding: 0.75rem 1.25rem;
      }
    }

    &_topics {
      box-sizing: border-box;
    }

    &_stat-card {
      &_row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1.5rem 1.25rem;
        gap: 1.5em;

        .events-insights-page_statistics_row_wrapper {
          width: 100%;
          min-width: 170px;
        }

        .events-insights-page_statistics_row_text_title {
          @media #{$md} {
            white-space: nowrap;
          }
        }

        strong {
          color: #000;
        }
      }

      &_wrapper {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        background: $border;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 110px;
        max-width: 140px;
        min-height: 110px;
        min-width: 110px;
        width: 100%;
        border-radius: 6px;
      }

      &_number {
        text-align: center;
        color: $txt-header;
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 0;
      }

      &_title {
        height: 30px;
        padding: 0 12px;
        text-align: center;
        color: $txt-header;
        font-size: 16px;
      }
    }

    &_download-btn {
      width: 100%;

      @media screen and (min-width: 600px) {
        display: flex;
        justify-content: flex-end;
        // max-width: 300px;
      }

      @media print {
        display: none;
      }
    }
  }

  &_utility-buttons {
    &_mobile-menu {
      &_wrapper {
        display: none;
        position: absolute;
        right: 0px;
        top: 32px;
        padding: 4px;
        background: white;

        &.active {
          display: block;
          text-align: left;
          border: 1pz solid #d0d0d0;
          box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        }
      }

      &_btn {
        font-weight: 600;
        font-size: 16px;
        padding: 16px 32px;
        color: $txt-gray-50;

        &.delete {
          color: $red-75;
          background: rgba(241, 242, 248, 0.6);
        }
      }
    }

    &_wrapper {
      background: rgba(241, 242, 248, 0.6);
      padding: 4px 12px;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &.active {
        display: flex;
      }

      .divider {
        font-size: 30px;
        color: $txt-content-2;
        margin-right: 12px;
        margin-top: 2px;
      }
    }

    &_btn-wrapper {
      display: none;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: $border-radius;
      padding: 8px;
      opacity: 0.8;
      transition-duration: 0.2s;
      height: 40px;

      &.active {
        display: flex;
      }

      &:hover {
        opacity: 1;

        &.disabled {
          opacity: 0.6;
        }
      }
    }

    &_icon {
      //
      display: block;
    }
  }

  &_custom-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 4px;

    & > * {
      border-radius: 4px;
    }

    &:hover {
      cursor: pointer;

      & span {
        color: #464646;
      }
    }

    & span {
      display: flex;
      align-items: center;
      margin-left: 20px;
      padding-left: 12px;
      text-align: left;
      text-transform: capitalize;
    }

    & span::before {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: -15px;
      left: 0;
      bottom: 0;
      background: transparent;
      border: 1px solid #d4d4d4;
      border-radius: 4px;
    }

    & span::after {
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: -15px;
      left: 0;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
    }

    & input[type='checkbox'] {
      opacity: 0;
      z-index: -1;
      position: absolute;
    }

    & input[type='checkbox']:checked + span::after {
      font-family: 'FontAwesome';
      padding-top: 1px;
      padding-left: 1px;
      border-radius: 4px;
      content: '✓';
      background: $orange;
      color: #fff;
      top: -15px;
    }
  }

  &_search-section {
    &.wrapper {
      width: 100%;
      padding-left: 0;
      position: relative;

      @media screen and (min-width: 600px) {
        max-width: 500px;
      }
    }

    .search-group-wrapper {
      @media #{$navbreak-max} {
        position: sticky;
        background-color: $white;
        z-index: 10;
      }
    }

    .search-drop-down-menu-wrapper {
      border: 1px solid #c1c5d3;
      border-top: 1px solid transparent;
      display: none;
      flex-direction: column;
      position: absolute;
      bottom: 0;
      top: 56px;
      left: 0;
      width: 100%;
      height: 200px;
      z-index: 2000;
      overflow-y: scroll;

      @media screen and (min-width: 990px) {
        top: 34px;
      }

      &.open {
        display: flex;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        background: white;
      }
    }

    .search-drop-down-menu-error-message {
      padding: 6px 20px 6px 48px;
      color: $txt-content;
    }

    .search-drop-down-menu-row {
      display: flex;
      justify-content: space-between;

      &:first-child {
        padding-top: 12px;
      }

      padding: 6px 20px 6px 48px;

      .date {
        width: 160px;
      }

      &:hover {
        cursor: pointer;
        background: rgba(241, 242, 248, 1);
      }
    }

    .search-group {
      @media #{$navbreak-max} {
        margin-top: pxToRem(20);
        margin-bottom: pxToRem(15);
      }

      margin-bottom: pxToRem(12);
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        right: pxToRem(10);
        transform: translate(-50%, -50%);
        color: #4c8fb8;
      }

      .form-control {
        height: pxToRem(38);
        padding-top: pxToRem(10);
        font-size: $fs-16;
        padding-right: pxToRem(50);
        border-color: #c1c5d3;
        box-shadow: none;
      }
    }
  }
}

#package-builder-create-page {
  background-color: #f5f6fa;
}

.merged-badge {
  background: $red;
  color: white;
  font-size: 14px;
  padding: 6px 10px 4px 10px;
  height: 25px;
  border-radius: $border-radius;
  font-weight: 600;
}

.preview-badge {
  background: $orange-50;
  color: $orange;
  border: 1px solid $orange;
  font-size: 14px;
  padding: 4px 10px 4px 10px;
  height: 25px;
  border-radius: $border-radius;
  font-weight: 600;
  margin-top: 0.4em;
}

.package-builder {
  &_preview {
    .package-builder_preview_events-card .card-body {
      padding-bottom: .2rem;
    }

    &_events {
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      display: grid;
      grid-template-columns: auto 1fr; /* First column takes auto width, second column takes remaining space */

      .item {
        display: flex;
        flex-direction: column;
        padding: 0px;

        &.title {
          flex-grow: 1;
          margin-right: 1rem;

          @media #{$navbreak} {
            margin-right: 3rem;
          }
        }

        &.date {
          margin-bottom: 1rem;
        }
      }

      .title, .qualifier, .value-text {
        color: #575454;
        font-size: 1rem;
      }

      .qualifier, .value-text {
        text-transform: capitalize;
      }
    }

    &_row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: .2rem;

      .title {
        margin-right: .6rem;
      }

      // exclude values
      .title, .qualifier, .value-text {
        color: #575454;
        font-size: 1rem;
      }

      .qualifier, .value-text {
        text-transform: capitalize;
      }
    }
  }
}
