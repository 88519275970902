.events-page {
  .head-wrap {
    flex-direction: column;
    width: 100%;
    // @media screen and (min-width: 990px) {
    //   padding-top: 0;
    //   flex-direction: row;
    // }
  }

  .search-group-wrapper {
    @media #{$navbreak-max} {
      width: 100%;
      padding: 0 0 0 2em;
      position: sticky;
      background-color: $white;
      z-index: 10;
    }
  }

  .search-group {
    min-width: 450px;
    max-width: 450px;
    width: 100%;
    @media #{$navbreak-max} {
      width: 100%;
      max-width: unset;
      margin-top: pxToRem(20);
      margin-bottom: pxToRem(15);
    }
    margin-bottom: pxToRem(12);
    position: relative;
    .icon {
      position: absolute;
      top: 50%;
      left: pxToRem(40);
      transform: translate(-50%, -50%);
    }
    .form-control {
      height: pxToRem(38);
      padding-top: pxToRem(10);
      font-size: $fs-16;
      padding-left: pxToRem(50);
      border-color: #c1c5d3;
      box-shadow: none;

      @media only screen and (min-width: 992px) {
        margin-left: pxToRem(15);
      }
    }
  }
}

input:checked {
  border: 10px solid brown;
  background: $orange !important;
}

.custom_filter_button {
  color: $txt-header-2;
  display: flex;
  flex-direction: column;
  // align-self: flex-end;
  // justify-content: center;
  align-items: center;
  background: transparent;
  width: 36px;
  margin-left: 1em;
  // height: 30px;
  padding: 12px 2px;
  border-radius: 6px;
  &.active {
    background: $orange;
  }
  &:hover {
    cursor: pointer;
  }
  &-each-line {
    background-color: $orange;
    height: 2px;
    &:nth-child(1) {
      width: 20px;
    }
    &:nth-child(2) {
      width: 12px;
      margin-top: 3px;
    }
    &:nth-child(3) {
      width: 7px;
      margin-top: 3px;
    }
    &.active {
      background: #fff;
    }
  }
}
