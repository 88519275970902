.summary {
  &_notice {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: pxToRem(15);
  }
  &_item {
    flex: 0 0 auto;

    &_icon {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      height: pxToRem(48);
      width: pxToRem(48);
      border-radius: 50%;
      line-height: pxToRem(48);
      margin-right: pxToRem(16);
      position: relative;
      background-color: rgba(168, 191, 203, 0.2);
      color: $navy-active;
      transition: 0.3s ease-in-out;

      &:hover {
        color: darken($navy-active, 5);
        background-color: rgba(168, 191, 203, 0.3);
      }

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .icon-campaign {
        font-size: $fs-18;
      }
    }

    &_content {
      position: relative;
      top: 2px;
      display: inline-block;
      vertical-align: bottom;
      font-size: $fs-16;
      line-height: pxToRem(18);
      color: $txt-content;

      strong {
        display: block;
        color: $black;
        font-weight: 600;
        font-size: pxToRem(16);
        line-height: pxToRem(15);
        margin-bottom: pxToRem(4);
      }

      .ads-preview-loader {
        margin-top: -12px;
        &.falling-dots {
          justify-content: flex-start;
    
          &.snippet {
            padding: 0;
            margin: 0;
          }
    
          .stage {
            padding: 1rem 0;
            height: 27.5px;
            width: 40px;
    
            .dot-falling {
              height: 6px;
              width: 6px;
              background-color: #031c41;
              color: #031c41;
              box-shadow: 9999px 0 0 0 #031c41;
              animation: fallingMiddle 1s infinite linear;
              animation-delay: 0.1s;
    
              &::before,
              &::after {
                height: 6px;
                width: 6px;
              }
    
              &::before {
                background-color: #031c41;
                color: #031c41;
                animation: fallingBefore 1s infinite linear;
                animation-delay: 0s;
              }
    
              &::after {
                background-color: #031c41;
                color: #031c41;
                animation: fallingAfter 1s infinite linear;
                animation-delay: 0.2s;
              }
    
              @keyframes fallingMiddle {
                0% {
                  box-shadow: 9999px -15px 0 0 rgba(3, 28, 65, 0);
                }
    
                25%,
                50%,
                75% {
                  box-shadow: 9999px 0 0 0 #031c41;
                }
    
                100% {
                  box-shadow: 9999px 15px 0 0 rgba(3, 28, 65, 0);
                }
              }
    
              @keyframes fallingBefore {
                0% {
                  box-shadow: 9984px -15px 0 0 rgba(3, 28, 65, 0);
                }
    
                25%,
                50%,
                75% {
                  box-shadow: 9984px 0 0 0 #031c41;
                }
    
                100% {
                  box-shadow: 9984px 15px 0 0 rgba(3, 28, 65, 0);
                }
              }
    
              @keyframes fallingAfter {
                0% {
                  box-shadow: 10014px -15px 0 0 rgba(3, 28, 65, 0);
                }
    
                25%,
                50%,
                75% {
                  box-shadow: 10014px 0 0 0 #031c41;
                }
    
                100% {
                  box-shadow: 10014px 15px 0 0 rgba(3, 28, 65, 0);
                }
              }
            }
          }
        }
      }
    }

    &_button {
      display: flex;
      align-items: center;

      button,
      span {
        white-space: wrap !important;
      }

      .preview-btn-loading-spinner {
        margin-top: -3px;
        .loading-spinner__title {
          font-size: .9rem;
          margin-left: 6px;
        }
      }

      .preview-btn-loader {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin-top: -4px !important;
        margin-bottom: 0 !important;
        height: 22px;
        width: 74px;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
      }
    }
  }

  &_modal {
    max-width: 85vh;
    max-height: 85vh;
    display: flex;
    flex-direction: column;

    &_disclaimer {
      background: $navy-active;
      color: white;
      padding: 1.5em 2em;
      gap: 1em;
      display: flex;
      z-index: 1;

      h4 {
        font-weight: 600;
      }
    }

    &_down-arrow {
      display: flex;
      justify-content: center;
      margin-bottom: 1em;
      margin-top: 1em;
      animation: bounce 2s infinite;

      svg {
        path {
          fill: $navy-active;
        }
      }
    }

    &_content {
      padding: 0 1rem 0;
      display: flex;
      justify-content: center;

      h1 {
        font-size: 1.3rem;
        font-weight: 600;
        color: $navy;
        margin-bottom: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      p {
        text-align: center;
        color: $navy;
      }
    }

    .tabs-component_main-content {
      @media #{$navbreak} {
        min-width: 540px;
      }
    }
  }

  &_close-btn {
    &_wrapper {
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 34px;
      max-width: 40px;
      min-height: 34px;
      min-width: 40px;
      border-radius: 6px;
      transition-duration: 0.2s;

      &:hover {
        // background: $red-50;
        transition-duration: 0.2s;
        background: #ffffffd6;
      }

      svg {
        path {
          stroke: $navy;
        }
      }
    }

    &_icon {
      color: $txt-content;
      width: 16px;
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-15px);
  }

  60% {
    transform: translateY(-10px);
  }
}
