.card {
  &--transparent {
    .card-header {
      background: transparent;
      h1, h2, h3, h4, h5, h6 {
        color: #575454;
        font-size: pxToRem(18);
        font-weight: 600;
        line-height: normal;
        margin-bottom: -3px;
      }
    }
  }

  &--with-border {
    border: 1px solid $border-color;
    border-radius: 5px;
  }

  &--compact {
    .card-header {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  &.fd-card {
    .card-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: $white;
      color: #3B3B3B;
      font-size: pxToRem(18);
      font-weight: 600;
    }

    .card-body, .card-header {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 16px;
      padding-bottom: 16px;

      @media #{$navbreak} {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}
