.time-range-wrapper {
  background-color: transparent;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  > * {
    padding-right: 0 !important;
  }
  margin-bottom: 0 !important;
}

.time-range {
  &__container {
    padding-top: 4px;
    display: flex;
    justify-content: space-between;
    flex-flow: nowrap;
    align-items: baseline;
  }
  > * {
    display: inline-block;
  }
  &__intro {
    font-size: 1rem;
    white-space: nowrap;
    color: $txt-header-125;
  }
  &__option {
    color: $black;
    background-color: transparent;

    font-size: $fs-14;
    line-height: pxToRem(15);
    padding: pxToRem(3) pxToRem(7);
    @media #{$xl} {
      font-size: $fs-16;
      line-height: pxToRem(18);
      padding: pxToRem(7) pxToRem(12) pxToRem(3);
    }
    border-radius: pxToRem(20);
    transition: 0.3s;
    &:hover,
    &:focus {
      background-color: $navy-50;
    }
    &--active {
      color: $white;
      background-color: $navy-75;
    }
  }
}
