.create-stepper .customer-segmentation {
  background-color: transparent;


  .card-header {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$navbreak} {
      padding: 1rem 1.5rem;
    }

    &.active {
      border-bottom: 1px solid $border-color !important;
    }

    &:hover {
      cursor: pointer;
    }

    .customer-segmentation__header {
      margin-bottom: 0;
      color: $txt-content;
      font-size: $fs-18;
      font-weight: 600;
    }
  }

  .card-body {
    padding: 0 1.2rem 1rem;

    @media #{$navbreak} {
      padding: 0 1.25rem 1.25rem;
    }
  }

  .filters {
    margin-bottom: 0;
    padding-top: 18px;
    border: 0 !important;
  }

  &__row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .75rem;

    .aligned-input {
      width: 232px;
    }

    &>* {
      margin-right: 1.2rem;
      min-width: 72px;
      align-items: flex-start;
    }

    .custom-radio {
      margin-bottom: -2px;
      z-index: 0;
    }
  }

  .ticket-categories-select {
    width: calc(100% - 1rem);
    max-width: 500px;

    .fd-select__control {
      &:hover {
        cursor: pointer;
      }
    }

    @media #{$navbreak} {
      width: 500px;
    }
  }
}
