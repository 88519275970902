.recommendations-page {
  .user-status-bar-wrapper {
    margin-bottom: 0 !important;
  }

  > .row {
    flex-flow: nowrap;
  }

  .static-half {
    @media #{$navbreak-max} {
      padding: 0;
    }

    @media #{$navbreak} {
      padding-right: pxToRem(19) !important;
    }
  }

  .fluid-half {
    @media #{$navbreak-max} {
      padding: 0;
    }

    @media #{$navbreak} {
      padding-right: 0;
    }

    > .card {
      @media #{$navbreak} {
        border-radius: 0 !important;
      }
    }
  }

  .card.card-default .nav-tabs {
    justify-content: space-between;

    @media #{$navbreak-max} {
      position: sticky;
      background-color: $white;
      top: $mobile-top-bar-height;
      z-index: 10;
    }

    @media #{$navbreak} {
      justify-content: flex-start;

      .nav-item {
        margin-right: pxToRem(30);
      }
    }
  }

  .card-events-tabs {
    .nav-tabs.card-header {
      justify-content: space-between;

      .nav-item {
        margin-right: 0;
      }
    }

    .card-body {
      padding-top: pxToRem(10);
    }
  }

  .event-list-intro {
    margin: pxToRem(60) 0 0;
    padding: 0;
  }

  .accordion {
    @media #{$navbreak-max} {
      margin-left: pxToRem(-20);
      margin-right: pxToRem(-20);
    }
  }
}

.event-details {
  .localization {
    margin: pxToRem(15) 0;
    font-size: 1rem;
    color: #818181;
  }
}

.recommendation {
  &__title {
    display: block;
    width: 100%;
    padding: 0.8rem 1rem;
    margin: 0;
    border-top: 1px solid #4c8fb8;
    font-size: 1.25rem;
  }

  // &__accordian-wrapper {
  //   -webkit-transition: max-height 0.2s;
  //   -moz-transition: max-height 0.2s;
  //   -ms-transition: max-height 0.2s;
  //   -o-transition: max-height 0.2s;
  //   transition: max-height 0.2s;
  //   overflow: hidden;
  //   // "height: 0" not work with css transitions
  //   max-height: 0;
  //   overflow: hidden;
  //   &.active {
  //     transition-duration: 0.2s;
  //     overflow: visible;
  //     max-height: 500px;

  //     &.campaign-setup {
  //       overflow-y: hidden;
  //       height: fit-content;
  //       max-height: fit-content;
  //     }
  //   }
  // }

  &__details {
    &__published {
      h4 {
        font-weight: 500;
        font-size: 1.2rem;
      }

      .file-list {
        &__itemBox {
          // position: relative;
          display: flex;
          margin-bottom: 10px;
        }

        &__thumb-container {
          padding: 4px;
          border: 1px solid #7195ad;
          border-radius: 2px;
          background-color: #fff;
          margin-right: 16px;

          &__thumb {
            width: 52px;
            height: 52px;
            background-position: center;
            background-size: cover;
            border-radius: 2px;
          }
        }
      }
    }
  }

  &__info {
    color: #262626;

    .col {
      display: flex;
      flex-wrap: wrap;
    }

    h4 {
      font-size: 1rem;
      font-weight: 400;
      color: $txt-content;
      white-space: nowrap;
    }

    p {
      font-size: $fs-18;
      white-space: nowrap;
    }

    &__id {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      // max-width: 35%;
      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        // max-width: 25%;
        &:hover {
          overflow: visible;
        }

        color: #656565;
      }
    }
  }

  &__infoItem {
    border: 1px solid #c1c5d3;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 13px 15px;
    margin-right: 10px;
    margin-bottom: 10px;

    @media only screen and (max-width: 1300px) {
      width: calc(50% - 10px);
    }

    @include mobile {
      border: 1px;
      padding: 0;
      margin-bottom: 25px;
      margin-right: 0;
    }
  }

  &__score {
    .progress {
      height: 0.5625rem;
    }

    &__value {
      font-size: pxToRem(35);
      line-height: pxToRem(40);
      font-weight: 600;
    }

    &__label {
      font-size: 1rem;
      color: $txt-content;
    }
  }

  &__description {
    p {
      font-size: $fs-16;
      line-height: pxToRem(18);
      color: $txt-gray-50;
    }
  }

  &__footer {
    .custom-control-label {
      line-height: pxToRem(27);
      color: $txt-content;

      &::before {
        background-color: #e1e3e3;
        border: none;
      }

      &::after {
        background-color: #fff;
      }
    }
  }
}

.progress--dashed {
  display: flex;

  margin-top: 0.3rem;

  .progress {
    background-color: #d9d9d9;
    width: 100%;
    display: block;

    &:not(:first-of-type) {
      margin-left: 0.75rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  main {
    margin-bottom: pxToRem(75);

    &.no-bottom-nav {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-down(xs) {
  .recommendations-page .card {
    border-radius: 0;
  }
}

.text-navy {
  color: $navy;
}

.fs-18 {
  font-size: pxToRem(18);
}

.fs-20 {
  font-size: 1.25rem;
}

.fs-30 {
  font-size: 1.875rem;
}

.rounded {
  border-radius: 8px !important;
}

input[type='range'] {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
}

input[type='range']::-webkit-slider-thumb {
  background: $orange;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;

  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid $orange;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $orange;
  cursor: pointer;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  margin-top: -4px;
}

input[type='range']::-moz-range-thumb {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  border: 1px solid $orange;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $orange;
  cursor: pointer;
}

input[type='range']::-ms-thumb {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  border: 1px solid $orange;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $orange;
  cursor: pointer;
}

input[type='range'] {
  background: linear-gradient(to right, #c1c5d3 0%, #c1c5d3 50%, #fff 50%, #fff 100%);
  box-shadow: inset 0 0 1px #585b64;
  border-radius: 8px;
  height: 7px;
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;
  appearance: none;
  -webkit-appearance: none;
}

.accordion-header-recommender-button {
  // Absolute position currently needed to take it out of the accordion's default <Link>
  // related functionality (makes everything, including nested buttons, activate
  // opening of the accordion card).
  position: absolute;
  cursor: pointer;
  top: 12px;
  right: 4px;
  font-size: $fs-18;
  font-weight: 500;
  color: #fff;
  min-width: 96px;
  padding-top: 8px;
  background: $orange;
  transition: 0.3s ease-in-out;

  @media screen and (min-width: 991px) {
    right: 1em;
  }

  &.select {
    background-color: $navy;
  }

  &.select-disabled {
    cursor: default;
    background-color: #c0bfbe;
  }

  &.selected {
    background-color: $orange;
  }

  &.saved {
    background-color: $orange;
  }

  &.pending {
    background-color: #c0bfbe;
  }
}

.recommendation-setup-action-buttons {
  position: fixed;
  height: 60px;
  z-index: 10000000;
  width: 100%;
  bottom: 0px;
  right: 0px;
  background: #fff;
  padding: 18px 15px;
  justify-content: center;
  border-top: 2px solid #e8e9ee;
  transition: all 0.25s linear;
  box-shadow: 1px -3px 8px rgba(0, 0, 0, 0.5);
}

@media #{$navbreak} {
  .recommendation-setup-action-buttons {
    justify-content: space-between;
    padding: 18px 32px;
  }
}
