#app-toast {
  .toast-container {
    width: 100vw;
    position: fixed;
    z-index: 999999;
    bottom: 50px;
    left: 0;
    background-color: $white;
  }

  .toast {
    z-index: 999999;
    min-width: 100%;
    border-radius: 0;
    box-shadow: none;
    border: none;
    border-top: 1px solid $border;
    transition: transform 0.2s ease-in-out;

    &:first-child {
      box-shadow: -2px -3px 4px rgba(0, 0, 0, 0.15);
    }

    &:last-child {
      @media #{$sm-max} {
        padding-bottom: 1rem;
      }
    }
  }

  @media #{$navbreak} {
    .toast-container {
      background-color: transparent;
      width: unset;
      box-shadow: none;
      padding-bottom: 0rem;

      &.bottom-0 {
        bottom: 25px;
      }

      &.start-0 {
        left: 105px;
      }
    }

    .toast {
      min-width: 400px;
      max-width: 400px;
      border-radius: 6px;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
      border: 1px solid $border;
    }
  }

  .toast-enter {
    transform: translateX(-130%);
  }

  .toast-enter-active {
    transform: translateX(0);
  }

  .toast-exit {
    transform: translateX(130%);
  }

  .toast-exit-active {
    transform: translateX(-130%);
  }
}
