.dots-menu {
  &_wrapper {
    position: absolute;
    top: 25px;
    right: 0px;
    // transform: translateY(-100%);
    flex-direction: column;
    border: 1px solid $border-color;
    background: white;
    border-radius: $border-radius;
    display: none;
    z-index: 9999999999999;
    width: auto;

    &.open {
      display: flex;
      box-shadow: 0px 1px 4px #00000022;
    }
  }

  &_each-item {
    padding: 0 1rem;
    margin: .4rem 0;

    &_separator {
      hr {
        color: #7B7676;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &_wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      //   justify-content: space-evenly;
      padding: 6px 12px 4px 16px;
      height: 42px;
      overflow: hidden;

      &.danger {
        .dots-menu_each-item_title {
          color: $red;
        }
      }

      &.custom {
        height: unset;
      }

      &:first-child {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }

      &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      &:hover {
        color: black;
        background-color: $background;
        cursor: pointer;
      }

      &.no-hover {
        pointer-events: none;
        background-color: transparent;
      }

      &:last-child {
        border-bottom: none;
      }

      .switch {
        justify-content: space-between;
        width: 100%;
        padding: 0;

        .switch_label {
          margin-top: 4px;
          color: #616060;
          font-size: 16px;
          min-width: 60px;
          padding: 0 8px 0 0;
          white-space: nowrap;
        }
      }

      .custom-radio {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin-right: 6px;
        height: 100%;

        label {
          margin-left: 0.6rem !important;
          color: #616060;
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }

      &.bottom-border {
        border-bottom: 1px solid $border-color;
      }

      &_sub {
        height: 46px;
        padding: 4px;
        margin-top: -6px;

        .dots-menu_each-item_wrapper_bg {
          border-radius: 3px;
          width: 100%;
          margin-top: 0;
          height: 100%;
          display: flex;
          align-items: center;

          &:hover {
            background-color: #eceef6;
          }
        }

        .dots-menu_each-item_title {
          margin-left: 2.6rem;
        }
      }

      &--disabled {
        .dots-menu_each-item_title {
          color: rgba($color: $txt-content, $alpha: 0.5);
        }

        .dots-menu_each-item_icon {
          opacity: 0.6;
        }

        &:hover {
          cursor: initial;
          background-color: transparent;
        }
      }
    }

    &_title {
      margin-top: 4px;
      color: $txt-content;
      font-size: 16px;
      min-width: 60px;
      padding: 0 8px 0 0;
      white-space: nowrap;
      text-align: left;

      &.no-icon {
        margin-left: 0;
      }
    }

    &_icon {
      text-align: center;
      margin-top: 9px;
      margin-right: 1em;

      img {
        width: 14px;
        height: 14px;
        margin-top: -10px;
      }
    }

    &_expandable {
      position: relative;

      &_bg {
        display: none;
      }

      &--no-icon {
        // make sure nested item titles don't have left padding
        .dots-menu_each-item_title {
          margin-left: 0;
        }
        .dots-menu_each-item_wrapper_sub {
          padding-left: 1rem;

          &.radio {
            padding-left: 6px;
          }
        }
      }

      &.open {
        .dots-menu_each-item_expandable_bg {
          display: block;
          position: absolute;
          width: calc(100% - 8px);
          height: calc(100% - 4px);
          z-index: -1;
          background-color: $background;
          margin: 0 4px;
          border-radius: 3px;
        }

        .dots-menu_each-item_wrapper {
          &:hover {
            background-color: transparent;
          }

          &__root {
            i.icon {
              transform: translateY(-2px) rotate(180deg);
            }
          }
        }
      }

      .dots-menu_each-item_wrapper__root {
        i.icon {
          font-size: 0.8rem;
          color: $txt-content;
          transition: 0.3s ease-in-out;
          transform-origin: center;
        }
      }
    }
  }
}
