.card.card-default.card-ticket-type {
  @media #{$navbreak-max} {
    padding-top: 0;
    margin-bottom: 0;
  }
}
.ticket-type-chart-wrapper {
  padding: 0 !important;
}
.ticket-type-chart {
  margin: 0 auto;
  max-width: pxToRem(450);
  .highcharts-data-label {
    cursor: pointer;
    &,
    * {
      font-family: 'TTCommons', sans-serif;
    }
    span {
      position: static !important;
      width: auto !important;
      white-space: nowrap !important;
      overflow: visible !important;
      text-overflow: unset !important;
      font-family: 'TTCommons', sans-serif;
    }
    &-name {
      font-size: $fs-16;
      color: $navy-active;
      font-weight: 400;
    }
    &-percent {
      display: inline-block;
      font-size: $fs-16;
      color: $navy;
      margin-left: 8px;
      font-weight: 400;
    }
  }

  /* Border */
  .highcharts-pie-series .highcharts-point {
    stroke: transparent;
    stroke-width: 0;
  }
  .highcharts-pie-series .highcharts-data-label-connector {
    stroke: black;
    stroke-dasharray: 8, 4;
    stroke-width: 1px;
  }
}
