.toast-body {
  background-color: $white;
  // style rules to wrap text
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;

  * {
    color: $black;
    font-size: 0.9rem;
  }

  padding: 1rem 1rem 0;

  @media #{$navbreak} {
    padding: 1rem;
  }
}
