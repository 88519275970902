.sentinel_saved-questions {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &-modal {
    max-width: 60vw;

    .modal_inner_header_wrapper, .modal_inner_content_wrapper {
      padding: 1.2rem;
      padding-bottom: 0;
    }

    .modal_inner_content_wrapper {
      padding-top: 0;
      max-height: 70vh;
    }
    
    @media #{$md} {
      .modal_inner_content_wrapper {
        padding: 0 2em 2em 2em;
      }
      .modal_inner_header_wrapper {
        padding: 24px 2em 0 2em;
      }
    }
  }

  &__description {
    color: #504141;
    margin-top: 1rem;
  }

  &__items {
    margin-top: 1rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.1rem;
    margin-bottom: 1.2rem;

    &:not(:last-child) {
      border-bottom: 1px solid #EAEAEA;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @media #{$navbreak} {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: 1.3rem;
    }

    &__content {
      width: 100%;
    }

    &__title, &__question {
      font-size: 1rem;
      font-weight: normal;
      margin-bottom: .2rem;
    }

    &__question {
      color: #504141;
    }

    .form-group, input {
      width: 100%;
      margin-top: .5rem;
    }

    .expandable-text {
      button {
        color: #2F80ED;
        display: inline;
        padding-left: .5rem;
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: .7rem;
      margin-top: .625rem;

      @media #{$navbreak} {
        justify-content: space-between;
        justify-content: initial;
      }

      .btn {
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        border-radius: 4px;
        padding-top: 4px;
        padding-bottom: 0px;
        max-width: 112px;
        width: max-content;
        color: #504141;
        font-size: $fs-16;
        line-height: pxToRem(18);
        font-weight: 500;
        text-wrap: nowrap;
  
        &:hover {
          background-color: #1f5274;
          color: #fff;
        }

        &--disabled {
          background-color: #EAEAEA !important;
          color: #B0B0B0 !important;
          border: 1px solid #B0B0B0 !important;
          cursor: default !important;
        }
      }

      .icon-button {
        margin-top: -2px;
      }
    }
  }

  .alert  {
    margin-bottom: 0;
  }

  &__empty-msg {
    color: #000;
    font-size: 1rem;
    text-align: center;
    margin-top: .5rem;
    margin-bottom: .5rem;
  }
}
