.main-title {
  margin-top: 3.6rem;
  display: flex;
  align-items: center;
  gap: .8rem;

  // backward compatibility
  color: $navy;
  font-size: $fs-40;
  line-height: pxToRem(46);
  font-weight: 600;

  h1 {
    color: $navy;
    font-size: $fs-40;
    line-height: pxToRem(46);
    font-weight: 600;
    margin-bottom: 0 !important;
  }

  .preview-label {
    font-size: pxToRem(22);
    color: #1F5274;
    font-weight: 600;
  }
}
