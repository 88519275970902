.manual-adjustments {
    &_falling-dots {
        margin: 0 !important;
        padding: 0 !important;
    }

    .circular-progress {
        position: relative;
        font-size: $fs-14;
        color: $txt-content-2;
        gap: 0.25rem;
        top: 2px;

        svg {
            width: 24px !important;
        }
    }
}
