.mobile-top-bar {
  @media #{$navbreak} {
    display: none;
  }
  color: #fff;
  background-color: $navy-active;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 120;
  height: $mobile-top-bar-height;
  width: 100%;
  text-align: center;
  // margin-bottom: 1.375rem;
  > * {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .mobile-top-bar__left {
    left: 1rem;
    button {
      color: #fff;
    }
  }
  .mobile-top-bar__right {
    right: 1rem;
    .icon-caret-left,
    .icon-user {
      font-size: $fs-20;
    }
  }
  .mobile-top-bar__center {
    min-width: 50vw;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .mobile-top-bar__title {
    padding-top: pxToRem(4);
    margin-bottom: 0;
    font-size: $fs-20;
    line-height: pxToRem(22);
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 75vw;
  }
}
