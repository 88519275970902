.sentinel_query {
  background-color: $white;
  border-radius: 6px;
  border: 1px solid #c1c5d3;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  margin-top: 1rem;
  color: #504141;
  position: relative;

  &--loading {
    opacity: 0.5;
  }

  .fd-accordion-header {
    text-align: start;
    padding: 20px;
    &:hover {
      cursor: pointer;
    }
  }

  &.fd-accordion--disabled {
    .fd-accordion-header {
      &:hover {
        cursor: default !important;
      }
      .sentinel_query__toggle {
        &:hover {
          cursor: default !important;
        }
      }
    }
  }

  .fd-accordion-body {
    padding: 0 8px 18px;
  }

  .collapse {
    border-radius: 5px;
    border: 1px solid rgba(31, 82, 116, 0.18);
    z-index: 1;
  }

  .sentinel_query__toggle {
    border-radius: 4px;
    padding: 0.25rem 0.75rem;
    background: $navy-60;
    color: $white;
    display: block;
    width: 88px;
    margin-bottom: 0.5rem;
    margin-top: -32px;
    text-align: center;

    &__icon {
      margin-top: -2px;
      transform: rotate(180deg);
      transition: transform 0.3s;
      &--active {
        transform: rotate(0deg);
      }

      path {
        fill: #504141;
      }
    }
  }

  &__summary {
    &__count {
      border-radius: 5px;
      background: #e1ebf2;
      color: #504141;
      padding: 4px 8px;
    }
  }

  &__section {
    padding: 0 0.8rem;
    min-height: 48px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media #{ $md } {
      flex-direction: row;
      justify-content: space-between;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-self: flex-start;
    }

    &__mode {
      align-self: flex-end;

      @media #{ $md-max } {
        width: 100%;
      }
    }

    &__empty-msg {
      color: #504141;
    }
  }

  &__line {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;

    &:first-child {
      margin-top: 0.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    color: #504141;
    font-size: 1rem;

    &.operator,
    &.value {
      padding: 4px 8px;
      background-color: #e1ebf2;
      border-radius: 4px;

      span {
        display: flex;
        align-items: center;
        height: 1rem;
        margin-top: 2px;
      }
    }
  }

  &__raw-sql {
    color: #504141;
    @media #{$navbreak} {
      margin-right: 0;
    }
  }
}
