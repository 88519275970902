@function pxToRem($sizepx) {
  $remSize: ($sizepx / 16);
  @return #{$remSize}rem;
}

$background: #f5f6fa;
$border: #e8e9ee;
$border-event-search: rgba(31, 82, 116, 0.5);
$input-border: #c1c5d3;

$white: #fff;
$black: #000;
$light-black: #3b3b3b;

$txt-header-125: #443f3f;
$txt-header: #3b3b3b;
$txt-header-2: #5c5c5c;
$txt-content: #616060;
$txt-content-2: #818181;
$txt-gray-50: #505050;

$arrow: #bdbdbd;

$red-125: #812313;
$red: #d63c24;
$red-active: #fa2b37;
$red-75: #ed6d55;
$red-50: #f2a193;

$orange-125: #7e4c3a;
$orange: #f58562;
$orange-second: #d27e61;
$orange-third: #e86b44;
$orange-75: #eeb49f;
$orange-50: #f7ded5;

$navy-125: #11344a;
$navy: #1f5274;
$navy-active: #031c41;
$navy-75: #6790a6;
$navy-50: #a8bfcb;
$navy-60: #7195ad;

$green-125: #1e4a3a;
$green-125: #3c8669;
$green: #4aa480;
$green-75: #6bbca2;
$green-50: #b0ddd0;

$yellow-warning: #f2c94c;
$yellow-warning-2: rgba(242, 201, 76, 0.6);

// font sizes
// p texts btns spans
$fs-14: pxToRem(14);
$fs-16: pxToRem(16);
$fs-18: pxToRem(18);

// headers
$fs-20: pxToRem(20);
$fs-24: pxToRem(24);
$fs-30: pxToRem(30);
$fs-32: pxToRem(32);
$fs-40: pxToRem(40);

// icons
$fs-icon-std: pxToRem(20);

$main-nav: #838383;

$card-border-radius: 8px;
$tooltip-border-radius: 6px;

$mobile-top-bar-height: 3rem;

$xs: 'screen and (min-width: 320px)';
$sm: 'screen and (min-width: 576px)';
$md: 'screen and (min-width: 768px)';
$lg: 'screen and (min-width: 992px)';
$xl: 'screen and (min-width: 1200px)';
$xxl: 'screen and (min-width: 1360px)';

$xs-max: 'screen and (max-width: 319px)';
$sm-max: 'screen and (max-width: 575px)';
$md-max: 'screen and (max-width: 767px)';
$lg-max: 'screen and (max-width: 991px)';
$xl-max: 'screen and (max-width: 1199px)';

$xs-only: 'screen and (max-width: 575px)';
$sm-only: 'screen and (min-width: 576px) and (max-width: 767px)';
$md-only: 'screen and (min-width: 768px) and (max-width: 991px)';
$lg-only: 'screen and (min-width: 992px) amd-nd (max-width: 1199px)';
$xl-only: 'screen and (min-width: 1200px)';

$max-340: 'screen and (max-width: 340px)';

$ie: 'all and (-ms-high-contrast: none), (-ms-high-contrast: active)'; //It detects IE :)
$ie-lg: 'screen and (min-width: 992px) and (-ms-high-contrast: none), (-ms-high-contrast: active)';
$ie-xl: 'screen and (min-width: 1200px) and (-ms-high-contrast: none), (-ms-high-contrast: active)';
$ie-xxl: 'screen and (min-width: 1500px) and (-ms-high-contrast: none), (-ms-high-contrast: active)';

$navbreak-small: $sm;
$navbreak: $lg;
$navbreak-max: $lg-max;

$font-family: 'TTCommons', sans-serif;
