.loading-spinner-window {
  position: fixed;
  z-index: 50;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  &__header {
    margin-bottom: 45px;
    text-align: center;
    img {
      display: inline-block;
      width: 70vw;
      max-width: 220px;
    }
  }
}

$spinner: $navy;
$spinner-background: $white;

.loading-spinner {
  $size: 30px;

  &--mini {
    $size: 16px;
  }

  &--small {
    $size: 20px;
  }

  height: $size;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;

  &__circle {
    position: relative;
    width: $size;
    height: $size;
    animation: spinner 0.75s linear infinite;

    &__line {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;

      &--1 {
        background: linear-gradient(to right, rgba($spinner, 0) 0%, rgba($spinner, 0) 50%, $spinner 51%);
      }

      &--2 {
        background: linear-gradient(to top, rgba($spinner-background, 0) 0%, $spinner-background 100%);
      }

      &--3 {
        $offset: $size * 0.1;
        top: $offset;
        left: $offset;
        width: $size - ($offset * 2);
        height: $size - ($offset * 2);
        background: $spinner-background;
      }
    }
    &--poral-bg {
      .loading-spinner__circle__line {
        &--2 {
          background: linear-gradient(to top, rgba($background, 0) 0%, $background 100%);
        }
        &--3 {
          background: $background;
        }
      }
    }
  }
  &__title {
    margin-left: pxToRem(22);
    font-size: $fs-16;
  }
}

.loading-spinner--grayscale {
  .loading-spinner__circle {
    $spinner: #575454;
    $spinner-background: #f5f6fa;

    &__line {
      &--1 {
        background: linear-gradient(to right, rgba($spinner, 0) 0%, rgba($spinner, 0) 50%, $spinner 51%);
      }

      &--2 {
        background: linear-gradient(to top, rgba($spinner-background, 0) 0%, $spinner-background 100%);
      }

      &--3 {
        background: $spinner-background;
      }
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
