.select-ad-platform {
  &_wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 7em;
  }

  &_section {
    background: $background;
    padding: 1em;
    margin-top: 2em;
    display: flex;
    border-radius: $border-radius;
    flex-direction: column;

    &:first-of-type {
      margin-top: 0;
    }

    @media screen and (min-width: 991px) {
      padding: 2em;
    }
  }

  &_section_title {
    color: $txt-content;
    font-weight: 700;
  }

  &_select-menu {
    &_wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      margin-top: 1em;

      &.open {
        overflow: unset;
      }
    }

    &_select-label {
      color: $txt-content;
      margin-top: 1em;
      font-weight: 500;
    }

    &_input {
      background: white;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 1px solid rgba(178, 178, 178, 0.5) !important;
      border-radius: 4px;
      padding: 4px 12px;
      padding-left: 8px;
      color: $txt-content-2;
      cursor: pointer;
      padding: 9px 12px 7px 12px;
      min-height: 37px;

      &.disabled {
        cursor: default;

        .selected-value {
          color: #a1a1a1 !important;
        }

        border-color: $border !important;
      }

      &.selected {
        border-color: $border-event-search;
        font-weight: 500;
        color: $txt-gray-50;

        &.invalid {
          color: rgba($color: $txt-content, $alpha: .5) !important;

          .selected-value {
            color: rgba($color: $txt-content, $alpha: .5) !important;
          }

          svg {
            width: 18px;
            height: 18px;
            margin-right: 5px;
            margin-top: -2px;
          }
        }

        .remove {
          margin: 0em 1em;
          transition-duration: 0.2s;

          &:hover {
            cursor: pointer;
            opacity: 1;
            filter: contrast(120%);
            transform: scale(1.2);
            transition-duration: 0.2s;
          }

          svg {
            path {
              fill: $arrow;
            }
          }
        }
      }
    }

    &_drop-menu {
      &_wrapper {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100%;
        margin-top: 2px;
        left: 0;
        right: 0;
        width: calc(100%);
        min-height: 40px;
        height: fit-content;
        z-index: 9999999999999;
        background-color: $white;
        border: 1px solid rgba(178, 178, 178, 0.5);
        border-radius: 4px;
        box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
        overflow: auto;

        &.open {
          display: block;
        }
      }

      &_row {
        &_wrapper {
          display: flex;
          align-items: center;
          transition-duration: 0.2s;
          padding: 12px 12px 8px 12px;

          &:hover {
            background: $background;
            cursor: pointer;
          }

          &.invalid {
            color: rgba($color: $txt-content, $alpha: .5);

            svg {
              width: 18px;
              height: 18px;
              margin-right: 5px;
              margin-top: -2px;
            }

            &:hover {
              background: $white;
              cursor: default;
            }
          }
        }
      }
    }
  }

  &_selected-platform {
    &_wrapper {
      display: flex;
      align-items: center;
    }

    &_name {
      color: $txt-content;
    }

    &_img {
      max-width: 20px;
      max-height: 20px;
      min-width: 20px;
      min-height: 20px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;

      & img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
