@function pxToRem($sizepx) {
  $remSize: ($sizepx / 16);
  @return #{$remSize}rem;
}

.slider {
  &_container {
    margin-top: 1em;
    @media screen and (min-width: 992px) {
      padding-left: 1.3em !important;
      padding-right: 1.3em !important;
    }
  }

  &_inner-wrapper {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    overflow: hidden;
    width: 100%;
    padding-bottom: 12px;
  }

  &_page-wrapper {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    @media screen and (min-width: 992px) {
      // trbl
      padding: 4px 18px 12px 18px;
    }
  }

  &_buttons {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    @media screen and (min-width: 992px) {
      padding: 0 18px;
    }
    &-chevron {
      min-width: 50px;
      max-width: 100px;
      //https://codepen.io/stepher/pen/yLOaEOP
      &::before {
        border-style: solid;
        border-width: 0.25em 0.25em 0 0;
        border-color: #3b3b3b;
        opacity: 0.5;
        content: '';
        display: inline-block;
        height: 0.8em;
        position: relative;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 0.8em;
        transition-duration: 0.2s;
      }

      &.disabled {
        opacity: 0;
      }

      &.right:before {
        left: 0;
        transform: rotate(45deg);
      }

      &.left:before {
        left: 0.25em;
        transform: rotate(-135deg);
      }
      &:hover {
        cursor: pointer;
        &::before {
          opacity: 1;
        }
      }
    }
  }

  &_dots {
    display: flex;
    justify-content: center;
    align-items: baseline;
    max-width: 200px;
    width: 100%;
    &-dot {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 0 4px;
      background-color: #3b3b3b;
      opacity: 0.3;
      transition-duration: 0.2s;

      &:hover {
        transform: scale(1.2);
        cursor: pointer;
      }
      &.active {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }
}
