.campaign-form {
  // @media #{$xl} {
  //   margin-right: -10px;
  // }

  .input__remove--r {
    margin-right: 2.8rem;
  }

  &__title {
    color: black;
    font-weight: 500;
    font-size: $fs-20;
    line-height: pxToRem(24);

    @media #{$xl} {
      margin-bottom: 25px;
    }
  }

  label {
    color: $txt-content;
    font-size: $fs-16;
    line-height: pxToRem(16);
  }

  .divider {
    background-color: #e8e9ee;
  }

  .form-group {
    margin-bottom: 10px;
  }

  &__add {
    font-family: inherit;
    font-size: pxToRem(15);
    font-weight: 500;
    color: $navy-60;
    margin-bottom: 15px;
    margin-top: 5px;

    @media #{$sm-max} {
      border: 1px dashed #7195ad;
      border-radius: 4px;
      padding: 13px;
      padding-bottom: 9px;
      display: block;
      width: 100%;
      font-size: pxToRem(15);
      margin-bottom: 10px;
    }

    &:hover,
    &:focus,
    &:active {
      color: #286d9b;
    }

    &:disabled {
      color: #7195ad55;
      cursor: auto;
    }

    span {
      padding-right: 3px;
    }
  }

  &__add--mobileSpecial {
    @media #{$sm-max} {
      background: rgba(113, 149, 173, 0.1);
      border-radius: 4px;
      width: 100%;
      padding-top: 13px;
      padding-bottom: 9px;
      text-align: center;
      border: 1px;
    }
  }

  &__tips {
    background-color: $background;
    min-width: 100%;
    width: 100%;
    margin-bottom: 8px;
    padding: 1em 0.85em;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    @media #{$xl-max} {
      // margin-left: -1.25rem;
      // margin-right: -1.25rem;
      margin-bottom: 10px;
      padding: 10px 1.25rem;
      // width: calc(100% + 2.5rem);
      max-width: none;

      label {
        font-size: $fs-14;
        line-height: pxToRem(17);
      }
    }

    &.first {
      border-top-left-radius: 4px;
    }

    &.last {
      border-bottom-left-radius: 4px;
    }

    &--nokeywords {
      font-size: 14px;
      line-height: 17px;
      color: #504141;

      div {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    &__tag-wrapper {
      display: inline-block;
      position: relative;
      width: fit-content;
      height: auto;
      margin-bottom: 10px;
      padding-right: 20px;

      @media #{$md-max} {
        padding-right: 15px;
      }
    }

    &__tag-wrapper:hover &__tagTooltip {
      transition-duration: 0.3s;
      transform: scale(1);
    }

    &__tag {
      display: block;
      padding: 7px 12px;
      font-weight: 500;
      font-size: $fs-14;
      line-height: 1;
      max-width: fit-content;
      height: 26px;
      margin-right: pxToRem(8);
      color: white;
      background-color: #7195ad;
      border-radius: 4px 0px 0px 4px;
      position: relative;

      &__caption {
        // display: block;
        // position: relative;
        // top: 0;
        // left: 0;
        // z-index: 2;
        // padding: 0;
        // padding-left: 5px;
        // padding-right: 15px;
        width: auto;
        max-width: 210px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // @media #{$navbreak} {
        //   top: -4px
        // }
      }

      &.disabled {
        background-color: $arrow;
        cursor: default;

        &::after {
          display: block;
          content: '';
          width: 0;
          height: 0;
          position: relative;
          left: calc(100% + 11px);
          top: -22px;
          border: 14px solid transparent;
          // @media #{$navbreak} {
          //   top: -28px
          // }
          border-radius: 2px;
          border-left-color: $arrow !important;
        }
      }

      &::after {
        display: block;
        content: '';
        width: 0;
        height: 0;
        position: relative;
        left: calc(100% + 11px);
        top: -22px;
        border: 14px solid transparent;
        // @media #{$navbreak} {
        //   top: -28px
        // }
        border-radius: 2px;
        border-left-color: #7195ad !important;
      }

      // &::before {
      //   position: relative;
      //   top: 14px;
      //   @media #{$navbreak} {
      //     top: 12px;
      //   }
      //   left: calc(100% - 18px);
      //   display: inline-block;
      //   content: '';
      //   height: 6px;
      //   width: 6px;
      //   transform: translate(50%, -50%);
      //   background-color: white;
      //   background: linear-gradient(180deg, #FFFFFF 0%, #FBFAFA 100%);
      //   z-index: 2;
      //   border-radius: 50%;
      //   margin-right: -45px;
      //   margin-left: 10px;
      // }
    }

    &__tagTooltip {
      transition-duration: 0.3s;
      transform-origin: 0 0;
      transform: scale(0);
      position: absolute;
      top: calc(100% + 6px);
      left: 0px;
      display: block;
      color: #fff;
      background-color: $navy;
      border-radius: 5px;
      line-height: 0.9rem;
      padding: 5px;
      width: 100%;
      z-index: 3;
      font-size: pxToRem(12);
      min-width: 200px;
      max-width: 500px;
      cursor: normal;

      &::after {
        display: block;
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        left: 20px;
        top: -10px;
        margin: 0;
        border: 5px solid transparent;
        border-bottom-color: $navy !important;
      }
    }
  }

  .input-cell {
    .input__remove {
      position: absolute;
      bottom: 13px;
      font-size: 14px;
      opacity: 0;
      transition: 0.15s ease-in-out;
      color: $orange;

      &:hover,
      &:focus {
        color: #f17852;
      }

      &:active {
        color: #d15c37;
      }
    }

    &--input {
      input {
        padding-right: 45px;

        &.input-autofill {
          padding-right: 100px !important;
        }
      }

      &-padding-max {
        input {
          padding-right: 88px;
        }
      }

      .input__remove {
        right: 45px;
      }
    }

    &--textarea {
      .input__remove {
        right: 55px;
      }

      textarea {
        min-height: 96px;
        padding-right: 55px;

        &.textarea-autofill {
          @media #{$md} {
            padding-right: 110px;
          }
        }
      }
    }

    &--input,
    &--textarea {
      .input-cell__max {
        &--orange {
          color: $orange-75 !important;
        }

        &--red {
          color: $red-active !important;
        }

        &--green {
          color: #219653 !important;
        }
      }
    }

    &:hover {
      .input__remove {
        opacity: 1;
      }
    }
  }

  &__footer {
    .bt {
      margin-left: 5px;
    }

    @media #{$sm-max} {
      .credits__container,
      .bt {
        margin-bottom: 12px;
      }

      .bt.bt-orange,
      .bt.bt.bt-navy,
      .bt.bt.bt-gray {
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    @media (max-width: 350px) {
      justify-content: center;
      text-align: center;
    }
  }

  .campaign-success {
    display: flex;
    flex-direction: column;
    text-align: center;

    span {
      margin-bottom: 16px;
    }

    @media #{$sm} {
      flex-direction: row;
      text-align: center;

      span {
        flex-grow: 1;
        display: inline-flex;
        align-items: center;
        margin-bottom: 0;
      }
    }
  }

  .generative-input {
    .autofill_btn {
      position: absolute;
      top: 6px;
      right: 11px;
      z-index: 2;

      @media #{$md-max} {
        bottom: 30px !important;
      }
    }

    .input-cell_info {
      top: 7px;
      right: 40px;
      border-right: 1px solid #e0e0e0;
      margin-right: 16px;
      padding-right: 8px;
      height: 26px;

      &.textarea {
        @media #{$md-max} {
          right: 10px;
          border-right: none;
          margin-right: unset;
          padding-right: unset;
          height: unset;
        }
      }
    }

    &.align-bottom {
      .autofill_btn,
      .input-cell_info {
        top: unset;
        bottom: 10px;
      }
    }
  }
}

.cta-dropdown, .geo-targeting-dropdown {
  text-transform: none !important;

  .dropdown_input {
    background: transparent !important;

    &.disabled {
      border-color: #cdced0;
      color: #888585;
      background-color: #e1e8ec !important;
    }
  }

  .dropdown_menu_container {
    padding-top: 0;
    margin-top: 2px;
    border: 1px solid rgba(178, 178, 178, 0.5);
    border-radius: $border-radius;

    .dropdown_menu_row {
      padding: 12px 12px 6px 12px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.cta-dropdown {
  @media #{$navbreak} {
    max-width: 55%;
  }
}

.limited-field-wrapper {
  position: relative;

  &:hover {
    .limit-tooltip {
      display: block;
    }
  }

  .limit-tooltip {
    display: none;
    position: absolute;
    top: 95%;
    right: 0;
    z-index: 9999999;
    background-color: #fff;
    color: #505050;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 33%);
    padding: pxToRem(20);
    text-align: left;
    font-family: 'TTCommons';
    font-size: $fs-14;
    font-weight: 400;
    line-height: 20px;
    max-width: 400px;
    width: 400px;
    height: fit-content;

    &.left-aligned {
      left: 0;
      right: auto;
    }

    @media #{$navbreak-max} {
      width: 80vw;
    }
  }
}
