.event-statistics {
  .running-time {
    color: #818181;
    font-size: 0.95rem;
    margin-top: -2px;
  }
}

.event-series-stats {
  position: relative;
  &.card {
    border: 1px solid #c1c5d3;
    border-radius: 8px;

    .card-header {
      @media #{$navbreak-max} {
        padding: 0.75rem 1.25rem;
        border-bottom: 1px solid #e8e9ee;
        border-top: 0;
      }
    }
  }

  .card-body {
    display: flex;
    padding: 1.25rem 0;
    justify-content: space-between;
    flex-direction: column;
    gap: 1.5em;

    @media #{$md-max} {
      gap: 1em;
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media #{$md-max} {
        margin-bottom: 1em;
      }
    }

    .row {
      margin: 0;
      display: flex;

      @media #{$md-max} {
        flex-direction: column;
      }
    }

    .events-insights-page_statistics_row_wrapper {
      margin-bottom: 0px;
      align-items: flex-start;

      .events-insights-page_statistics_row_icon {
        svg,
        img {
          width: 22px;
          height: 22px;
        }
      }
    }

    .events-insights-page_statistics_row_text_wrapper {
      padding: 0;

      .events-insights-page_statistics_row_text_value {
        strong {
          color: $black;
          font-size: $fs-24;
        }

        .stats-loader {
          &.falling-dots {
            justify-content: flex-start;

            &.snippet {
              padding: 0;
              margin: 0;
            }

            .stage {
              padding: 1rem 0;
              height: 27.5px;
              width: 40px;

              .dot-falling {
                height: 6px;
                width: 6px;
                background-color: #031c41;
                color: #031c41;
                box-shadow: 9999px 0 0 0 #031c41;
                animation: fallingMiddle 1s infinite linear;
                animation-delay: 0.1s;

                &::before,
                &::after {
                  height: 6px;
                  width: 6px;
                }

                &::before {
                  background-color: #031c41;
                  color: #031c41;
                  animation: fallingBefore 1s infinite linear;
                  animation-delay: 0s;
                }

                &::after {
                  background-color: #031c41;
                  color: #031c41;
                  animation: fallingAfter 1s infinite linear;
                  animation-delay: 0.2s;
                }

                @keyframes fallingMiddle {
                  0% {
                    box-shadow: 9999px -15px 0 0 rgba(3, 28, 65, 0);
                  }

                  25%,
                  50%,
                  75% {
                    box-shadow: 9999px 0 0 0 #031c41;
                  }

                  100% {
                    box-shadow: 9999px 15px 0 0 rgba(3, 28, 65, 0);
                  }
                }

                @keyframes fallingBefore {
                  0% {
                    box-shadow: 9984px -15px 0 0 rgba(3, 28, 65, 0);
                  }

                  25%,
                  50%,
                  75% {
                    box-shadow: 9984px 0 0 0 #031c41;
                  }

                  100% {
                    box-shadow: 9984px 15px 0 0 rgba(3, 28, 65, 0);
                  }
                }

                @keyframes fallingAfter {
                  0% {
                    box-shadow: 10014px -15px 0 0 rgba(3, 28, 65, 0);
                  }

                  25%,
                  50%,
                  75% {
                    box-shadow: 10014px 0 0 0 #031c41;
                  }

                  100% {
                    box-shadow: 10014px 15px 0 0 rgba(3, 28, 65, 0);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &_disabled-card {
    opacity: 0.5;
    position: relative;
  }

  &.disabled-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto auto;
    width: 310px;
    z-index: 1;
  }

  &_data {
    display: flex;
    margin: 1em 0em;
    background-color: $background;
    border-radius: 8px;

    .event {
      padding: 10px 2em;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      width: 50%;

      .bold {
        font-weight: 600;
        font-size: $fs-24;
      }

      &_single {
        background-color: rgba(247, 252, 245, 1);
        border: 1px solid rgba(123, 195, 85, 1);
      }

      &_series {
        background: $background;
        border: 1px solid $background;
      }
    }
  }

  &_event-list {
    gap: 1em;
    display: flex;
    flex-direction: column;

    .goals-card_header {
      font-size: 1.125rem !important;
      border: none !important;
    }

    .event-card:first-of-type {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    .event-card:last-of-type {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .event-card {
      display: flex;
      flex-direction: column;
      background-color: $white;
      padding: 1em !important;
      cursor: pointer;
      box-shadow: inset 0 4px 0 0 #f5f6fa;
      transform: scale(1);
      transition-duration: 0.3s;

      &:hover {
        background: rgb(232, 240, 254);
        box-shadow:
          inset 0 4px 0 0 #f5f6fa,
          inset 3px 0 0 0 #11344a,
          inset 0 4px 0 0 #f5f6fa;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        transition-duration: 0.2s;
      }
    }
  }
}

