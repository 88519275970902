.dashboard-page {
  .card-upcoming-events {
    .card-body {
      padding: 0 !important;

      // @media #{$navbreak-max} {
      //   padding-top: pxToRem(2);
      // }
    }
  }
}
