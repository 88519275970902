.mobile-nav {
  @media #{$navbreak} {
    display: none !important;
  }

  cursor: pointer;

  &__content {
    position: fixed;
    transition-duration: 0.5s;
    bottom: 0;
    left: 0;
    top: calc(100% - 50px);
    z-index: 99999;
    width: 100%;
    // padding: 1rem 0;
    background-color: $navy-active;
    border-top: 1px solid rgba(black, 0.2);
  }

  &__bg {
    z-index: 9999;
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__notifications {
    z-index: 2;
    position: absolute;
    bottom: 100%;
    right: 0;
    left: 0;
    background-color: $orange;
    color: #fff;
    text-align: center;
    padding: 5px 5px 3px 5px;
    font-weight: 500;
    font-size: $fs-14;

    &:hover {
      color: $orange-75;
    }
  }

  &__menuIcon {
    margin-left: 20px;
    width: 20px;
    height: 100%;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    &-wrapper {
      padding-left: 12px;
      padding-top: 20px;
      padding-bottom: 20px;
      display: block;
    }

    & span {
      display: block;
      position: absolute;
      height: 2px;
      width: 20px;
      // background-color: #585858;
      background-color: #fff;
      border-radius: 2px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    & span:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    & span:nth-child(2) {
      top: 5px;
      width: 15px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    & span:nth-child(3) {
      top: 10px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }

  ul {
    position: fixed;
    bottom: 0px;
    height: 100%;
    overflow-y: auto;
    left: 0px;
    top: 200%;
    width: 100%;
    opacity: 0.2;
    display: flex;
    transition: all 0.2s linear;
    list-style: none;
    flex-flow: row nowrap;
    justify-content: space-around;
    flex-direction: column;
    padding-bottom: 10px;
    background-color: $navy-active;

    li {
      text-align: center;
      flex-basis: 100%;
      margin: 2px 15px;

      @media #{$max-340} {
        flex-basis: auto;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    text-align: center;
    color: #fff;
    border-radius: 8px;
    padding: 13px 12px;

    i {
      width: 24px;
      color: #fff;
      opacity: 0.6;
      display: inline-block;
      vertical-align: middle;
      font-size: $fs-20;
      margin-right: 20px;

      &.icon-dashboard {
        font-size: pxToRem(23);
      }

      &.icon-pie-chart {
        font-size: pxToRem(21);
      }

      &.icon-events {
        font-size: $fs-18;
      }

      &.icon-notifications {
        font-size: pxToRem(24);
      }

      &.icon-user {
        font-size: pxToRem(23);
      }

      transition: 0.15 ease-in-out;
    }

    &__label {
      color: #fff;
      display: block;
      font-weight: 500;
      font-size: $fs-16;
      line-height: pxToRem(18);
      padding-top: 3px;
    }

    &:hover {
      background-color: rgba(31, 82, 116, 0.1);

      i {
        color: $white;
        opacity: 1;
      }

      .mobile-nav__link__label {
        color: $white;
      }
    }

    .nav-icon--active {
      display: none;
    }

    &--active {
      span {
        color: #89b4d1;
      }

      .nav-icon {
        display: none;

        &--active {
          display: inline-block;

          svg {
            path {
              fill: #89b4d1;
            }
          }
        }
      }

      i {
        color: #89b4d1;
        opacity: 1;
      }
    }
  }
}

.mobile-nav--active {
  .mobile-nav__bg {
    display: block;
  }

  ul {
    top: initial;
    height: fit-content;
    z-index: 140;
    opacity: 1;
    margin-bottom: 50px;
  }

  .mobile-nav__menuIcon {
    & span:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: -2px;
    }

    & span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    & span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 12px;
    }
  }
}
