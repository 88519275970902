.tip-box {
  &_dialogue {
    &_wrapper {
      margin-top: 1em;
      margin-bottom: 1em;
      border-radius: $border-radius;
      display: flex;
      flex-direction: column;
      padding: 1em;
      border-left: 5px solid !important;

      &--with-border {
        border: 1px solid;
      }

      &--danger {
        background-color: #fbecec;
        border-color: $red !important;

        .tip-box_dialogue_title_content {
          color: $navy !important;
          font-weight: 700;
        }

        .tip-box_dialogue_text {
          color: $light-black;
          font-weight: 500;
        }
      }

      &--regular {
        background: $white;
        border-color: $orange-third !important;

        .tip-box_dialogue_title_content {
          color: $txt-header-2 !important;
          font-weight: 700;
        }

        .tip-box_dialogue_text {
          color: $txt-content;
          font-weight: 400;
        }
      }

      &--warning {
        background: $background;
        border-color: $orange-third !important;
      }

      &--warning-light {
        background: $white;
        border-color: $orange-third !important;
      }

      &--compact {
        padding: 0.6rem;
      }

      &--active {
        &:hover {
          cursor: pointer;
          box-shadow: 0px 2px 4px #00000055;
          transition: 0.3s ease-in-out;
        }
      }
    }

    &_title {
      &_wrapper {
        display: flex;
        align-items: flex-start;
      }

      &_content {
        color: $navy-125;
        font-size: $fs-18;
      }

      &_img {
        margin-left: 12px;
      }
    }

    &_text {
      color: #4f4f4f;
    }
  }
}
