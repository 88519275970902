.infobox-tooltip {
  z-index: 20000000000000000;
}

.infobox {
  .infobox__icon {
    transform: translateY(-1px);

    &:hover {
      svg path {
        fill: $navy !important;
      }
    }
  }
}

.infobox-tooltip {
  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: #fff;
    color: #505050;
    border-radius: $tooltip-border-radius;
    box-shadow: 0px 2px 4px #00000055;
    padding: 20px;
    text-align: left;
    font-family: 'TTCommons';
    font-size: $fs-14;
    line-height: 20px;
    font-weight: 400;
    max-width: 340px;

    h5 {
      font-weight: 700;
    }
  }

  &__link {
    display: inline-block;
    text-decoration: underline;
  }

  .arrow {
    z-index: 1;
  }

  &-top {
    .arrow {
      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.4rem 0.4rem 0 0.4rem;
        border-color: #fff transparent transparent transparent;
        filter: drop-shadow(0px 2px 1px #00000055);
        z-index: 200000;
      }

      &::before {
        display: none;
      }
    }
  }

  &-bottom {
    .arrow {
      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0.4rem 0.4rem 0.4rem;
        border-color: transparent transparent #fff transparent;
        filter: drop-shadow(0px -1px 1px #00000011);
      }

      &::after {
        display: none;
      }
    }
  }
}
