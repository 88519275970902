.progress-legend {
  display: flex;
  justify-content: space-between;
  padding-top: pxToRem(20);
  .progress-legend__line {
    height: pxToRem(20);
    width: 1px;
    margin-bottom: pxToRem(5);
    background-color: #c4c4c4;
    position: relative;
    &::before {
      content: attr(data-title);
      position: absolute;
      transform: translateX(-50%);
      bottom: calc(100% + #{pxToRem(8)});
      left: 50%;
      color: #303030;
      font-size: $fs-14;
      line-height: pxToRem(14);
    }
  }
}
.progress-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  background-color: transparent;
  position: relative;
  overflow: visible;
  [data-value]::before {
    content: attr(data-value);
    pointer-events: none;
    position: absolute;
    opacity: 0;
    border-radius: pxToRem(8);
    padding: pxToRem(3) pxToRem(5) 0;
    font-size: pxToRem(13);
    line-height: pxToRem(15);
    background-color: $navy-50;
    color: $navy-125;
    transition: 0.3s;
  }
  .progress-bar__line {
    position: relative;
    height: pxToRem(8);
    border-radius: pxToRem(10);
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &[data-value]::before {
        display: none;
      }
    }
    + .progress-bar__line {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &[data-value]::before {
      top: calc(100%);
      left: 100%;
      transform: translateX(-50%);
    }
    transition: 0.15s;
  }
  .progress-bar__dot {
    position: absolute;
    top: 50%;
    z-index: 5;
    transform: translate(-50%, -50%);
    display: inline-block;
    height: pxToRem(10);
    width: pxToRem(10);
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: 0.1s ease-in-out;
    &[data-value]::before {
      bottom: calc(100%);
      left: 50%;
      transform: translateX(-50%);
    }
  }
  /* For mobile tooltip caused horizontal scroll */
  .tooltip-to-right[data-value]::before {
    @media #{$navbreak-max} {
      transform: translateX(-85%);
    }
  }
  &:hover {
    .progress-bar__line {
      &::before {
        top: calc(100% + 5px);
        opacity: 1;
      }
    }

    .progress-bar__dot {
      width: pxToRem(12);
      height: pxToRem(12);
      &::before {
        bottom: calc(100% + #{pxToRem(5)});
        opacity: 1;
      }
    }
  }
}
