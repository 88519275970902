.toast-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
  background-color: $white;
  width: 100%;
  padding: 1rem;
  padding-bottom: 0;

  @media #{$navbreak} {
    padding: 1rem;
    padding-bottom: 0;
  }

  &-icon {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: none;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-top: -3px;
  }

  &-content {
    flex: 1;
    margin-right: auto;
    font-weight: 700;
    margin-top: 1px;
    font-size: 1.25rem;
    color: #505050;
    line-height: 20px;
  }

  &-close-btn {
    margin-left: auto;
    margin-top: -5px;
    width: 26px;
    height: 26px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    img {
      width: 12px;
      height: 12px;
      opacity: 0.7;
    }

    &:hover {
      background-color: #e6e6e6;
      img {
        opacity: 0.8;
      }
    }

    @media #{$navbreak} {
      width: 18px;
      height: 18px;
      img {
        width: 8px;
        height: 8px;
      }
    }
  }

  &.success,
  &.error {
    .toast-header-icon {
      display: flex;
    }
  }

  &.success {
    .toast-header-icon {
      background-color: $navy;
    }
  }
}
