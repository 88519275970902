.switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.4rem;

  &_label {
    padding-top: 3px;
    white-space: nowrap;
  }

  &_control {
    position: relative;
    width: 28px;
    height: 16px;

    &:hover {
      cursor: pointer;
    }

    &::before {
      left: 0rem;
      top: 0rem;

      width: 1.75rem;
      pointer-events: all;
      border-radius: 0.5rem;
      transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      position: absolute;
      display: block;
      height: 1rem;
      content: '';
      background-color: #cfd1d1;
    }

    &::after {
      top: calc(2px);
      left: calc(2px);
      width: calc(1rem - 4px);
      height: calc(1rem - 4px);
      background-color: white;
      border-radius: 0.5rem;
      transition:
        transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      position: absolute;
      display: block;
      content: '';
      // background: 50%/50% 50% no-repeat;
    }

    &.checked {
      &::before {
        background-color: #104466;
      }

      &::after {
        transform: translateX(0.75rem);
      }
    }
  }

  &.disabled {
    cursor: not-allowed;

    .switch_label {
      color: rgba(97, 96, 96, 0.5);
    }

    .switch_control {
      pointer-events: none;
      opacity: 0.3;

      &::before {
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}
