.tab-pane-mobile {
  @media #{$navbreak} {
    display: block !important;
    opacity: 1 !important;
  }
}

.nav-tabs {
  flex-wrap: nowrap;
  &.card-header {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-color: $white;
  .nav-link {
    padding-left: 2px;
    padding-right: 2px;
    color: #6c6969;
    font-size: $fs-18;
    line-height: pxToRem(20);
    border: none;
    border-bottom: 1px solid transparent;
    &.active {
      color: $black;
      border-bottom: 2px solid #6790a6;
    }
  }
}
