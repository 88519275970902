.target-mode {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 3em;

  @media #{$md-max} {
    flex-direction: column;
    gap: 1.5em;
  }

  &_card {
    flex: 1;
    border-radius: 5px;
    border: 1px solid rgba(31, 82, 116, 0.1);
    outline: 1px solid rgba(31, 82, 116, 0);
    background: var(--blue-bg-light, #f5f6fa);
    box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.2);
    padding: 1.5em 2em;
    cursor: pointer;

    &:hover {
      background-color: #eceef6;
    }

    &.selected {
      border: 1px solid rgba(31, 82, 116, 1);
      outline: 1px solid rgba(31, 82, 116, 1);
    }

    .text {
      &::after {
        content: '\00a0';
      }

      &_clipped {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        text-align: left;
      }
    }

    .more {
      cursor: pointer;
      color: var(--blue-1, #2f80ed);
    }

    .card-header {
      border: none;
      background: none;
      padding: 0;
      color: #575454;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .card-body {
      padding: 0;
      padding-top: 1em;
    }
  }
}
