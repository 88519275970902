.footer {
  text-align: center;
}

.authentication-page {
  .footer {
    position: fixed;
    @media #{$navbreak} {
      position: absolute;
    }
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;

    .language-switcher__link {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .footer__link {
    text-decoration: underline;
    padding-left: 4px;
    padding-right: 4px;
    color: $navy;
    display: block;
    margin-top: 1.5rem;
    color: $navy-active;
    font-family: 'TTCommons', sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    cursor: pointer;
  }
}
