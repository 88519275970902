.how-it-works {
  .card {
    background-color: $white;
    padding-top: 0.8rem;
  }

  .card-default {
    margin-bottom: 0px;
  }

  .card-header {
    font-size: $fs-18;
    line-height: pxToRem(21);
    padding-bottom: 0.2rem;
    padding-left: 1.5rem;
    padding-top: 0 !important;
    background: $gray-100;
    border-bottom: 1px solid #3b3b3b;
    border-top: none !important;
  }

  .card-body {
    padding: 1.5rem;
  }

  ol {
    list-style: none;
    padding-left: 0 !important;
  }

  li {
    font-size: $fs-16;
    line-height: pxToRem(25);
    padding-top: 5px;
    display: list-item;

    @media #{$lg-max} {
      padding-top: 10px;
    }

    &:first-of-type {
      padding-top: 0;
    }
  }

  &_notice {
    font-size: $fs-16 !important;
    line-height: pxToRem(22) !important;
    padding-top: 1em !important;
    color: #3b3b3b !important;

    span {
      color: red;
    }
  }

  &_readMore {
    color: #3b3b3b;

    button {
      color: #7195ad;
    }
  }
}
