.meta {
  &_connection {
    .title-bar {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media #{$navbreak} {
        gap: 0;
      }

      &__title {
        h3 {
          font-size: pxToRem(20);
          margin-bottom: 5px;
          display: inline-block;
        }
      }

      &__description {
        color: $black;
      }
    }

    &__buttons {
      // d-flex align-items-center gap-3
      display: flex;
      flex-direction: column-reverse;
      align-items: stretch;
      gap: 1rem;

      @media screen and (min-width: 512px) {
        flex-direction: row;
        align-items: center;
      }

      &__item {
        width: 100%;

        @media screen and (min-width: 512px) {
          width: auto;
        }
      }
    }

    &__copy-prompt {
      @media #{$navbreak} {
        min-width: 500px;
      }

      align-items: flex-start !important;

      p {
        text-align: start;
        color: $txt-header-2;
      }

      .footer {
        justify-content: unset !important;
        width: 100%;

        #auth-link {
          height: 38px;
          flex-shrink: 0;
          border-radius: 4px;
          border: 1px solid $border;
          color: $black;
          padding-top: .6rem;
          padding-left: .6rem;
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex-grow: 1;
          width: initial !important;
          max-width: 430px;
        }

        .btn-copy {
          height: 37px !important;
        }
      }
    }
  }

  .bt.bt-toolbar {
    transition: all 0.2s ease-in-out;
    border: none;
    padding: 0;

    &:hover {
      text-decoration: underline;
      background-color: transparent !important;
      color: $navy;
    }

    &:first-child {
      margin-left: 0;
    }

    span {
      font-size: 0.9rem !important;
    }
  }

  .bt {
    padding: 0.45rem 0.9rem;
    min-width: 100px;

    span {
      padding-top: 4px;
    }
  }

  .btn-copy {
    .copy-to-clipboard__btn-wrapper {
      color: $white !important;
    }
    .copy-to-clipboard__copied{
      margin-top: 0 !important;
      svg {
        margin-top: 0 !important;
        margin-right: 4px;
      }
      path {
        stroke: $white !important;
      }
    }
  }
  
  .btn-sync {
    position: relative;
    margin-right: -2px;
    &[disabled] {
      cursor: default;
      opacity: 0.8;
    }
    &_icon {
      &--loading {
        animation: rotate 1.5s linear infinite;
        transform-origin: center;
        display: inline-block;
      }
    }
  }

  .bt-connect {
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #166fe5 !important;
      color: $white !important;
    }
    background-color: #1a77f2 !important;
    border-color: #1a77f2;
    color: $white !important;

    span {
      padding-top: 4px;
    }
  }

  .toolbar-separator {
    height: 0.9rem;
    width: 1px;
    background-color: $gray-500;
    margin: 0 0.4rem;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
