.sentinel_skeleton {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;

  * {
    width: 100%;
    background-color: rgba(217, 217, 217, 0.30);
    animation: pulse 1.5s infinite;
    border-radius: 4px;
  }

  &__query {
    height: 102px;
  }

  &__results {
    height: 456px;
  }
}

@keyframes pulse {
  0% {
    background-color: rgba(217, 217, 217, 0.30);
  }
  50% {
    background-color: rgba(217, 217, 217, 0.50);
  }
  100% {
    background-color: rgba(217, 217, 217, 0.30);
  }
}
