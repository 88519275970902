.dashboard-page {
  .pd-desktop-right {
    margin-top: 55px;
  }
  @media screen and (max-width: 353px) {
    .event-row_inner-wrapper {
      margin-right: 7.25em;
    }
  }

  @media screen and (min-width: 549px) and (max-width: 650px) {
    .event-row_inner-wrapper {
      margin-right: 2.25em;
    }
  }
  @media screen and (min-width: 781px) and (max-width: 991px) {
    .event-row_inner-wrapper {
      margin-right: 1.25em;
    }
  }

  @media screen and (min-width: 1400px) and (max-width: 1490px) {
    .pd-desktop-left .card-header {
      padding-left: 1.25em;
    }
  }

  &--compact {
    .pd-desktop-left {
      .card-header,
      .event-row_inner-wrapper {
        @media screen and (min-width: 1400px) and (max-width: 1430px) {
          padding-left: 2.25em;
          padding-right: 2.25em;
        }
        @media screen and (min-width: 1431px) and (max-width: 1455px) {
          padding-left: 0.7rem !important;
          padding-right: 0.7rem !important;
        }
        @media screen and (min-width: 1456px) and (max-width: 1480px) {
          padding-left: 1rem !important;
          padding-right: 1rem !important;
        }
        @media screen and (min-width: 1481px) and (max-width: 1620px) {
          padding-left: 1.25rem !important;
          padding-right: 1.25rem !important;
        }
      }
    }

    @media screen and (max-width: 1570px) and (min-width: 991px) {
      .pd-desktop-right {
        max-width: 270px !important;

        .card-body {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
      }
    }
  }

  .events-list {
    ul {
      margin: 0;
    }

    &__empty {
      color: $txt-content;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      gap: 4px;
      background-color: #f5f6fa;

      .event-row_outer-wrapper {
        &:first-of-type {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
  }

  .col-intro {
    height: pxToRem(50);
    margin-bottom: pxToRem(20);
    line-height: pxToRem(50);
  }

  .left-col {
    @media #{$navbreak-max} {
      .card-header {
        @include flex-parent($jc: flex-start, $ai: center);
        position: relative;
        height: 60px;
        width: 100%;
        padding: 0.625rem 0 0 1.25rem;
        background-color: #ffffff;
      }
    }
  }
}

.empty-events {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;

  .title,
  .description {
    font-weight: 700;
    color: #000;
  }

  .title {
    font-size: 1.2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .description {
    font-size: 1rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  @media #{$lg-max} {
    margin-top: 2rem;
    margin-bottom: 2rem;

    img {
      width: 300px;
    }

    a {
      max-width: 90%;

      button {
        max-width: 100%;
      }
    }
  }
}

.dashboard-columns-view {
  display: none;

  @media screen and (min-width: 1400px) {
    display: flex;
  }
}

.dashboard-tab-view {
  display: block;

  @media screen and (min-width: 1400px) {
    display: none;
  }
}

.dashboard-stats {
  text-align: center;
  margin-bottom: pxToRem(8);

  @media #{$navbreak} {
    margin-bottom: pxToRem(30);
  }

  &__infobox {
    margin-left: pxToRem(20);

    @media #{$navbreak} {
      margin-left: pxToRem(40);
    }
  }

  &__item {
    position: relative;

    @media #{$navbreak} {
      &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        width: 1px;
        height: pxToRem(35);
        background-color: #d0d0d0;
      }
    }
  }

  &__title {
    display: block;
    color: black;
    font-size: $fs-30;
    line-height: pxToRem(34);
    font-weight: 600;

    @media #{$navbreak} {
      font-size: pxToRem(50);
      line-height: pxToRem(57);
    }

    &.active::after {
      display: inline-block;
      content: '';
      background-color: $orange;
      height: pxToRem(13);
      width: pxToRem(13);
      border-radius: 50%;
      vertical-align: super;
      margin-left: 0.25rem;
    }
  }

  &__desc {
    color: $txt-content;
    font-size: 1rem;
    line-height: 1.125rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.progress-bar.bg-navy {
  background-color: $navy;
}

.select-filter {
  margin-bottom: 0.75rem;
}
