.recommendations-page {
  .card-events-tabs {
    .card-body {
      padding-top: pxToRem(10);
    }
  }

  &_cta-btns_row {
    // d-flex align-items-center justify-content-between mb-4
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0em !important;

    & > * {
      width: 100%;
      margin-top: 1em;
    }

    @media screen and (min-width: 975px) {
      padding: 0em !important;
      justify-content: flex-end;
      flex-direction: row;
      gap: 1em;

      & > * {
        margin-top: 0;
        width: unset;
      }
    }
  }

  @media #{$navbreak} {
    .row-main {
      flex-flow: row nowrap;
    }

    .fluid-half {
      background-color: $white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      min-height: calc(100vh - #{pxToRem(40)}); //40px is the height of .user-status-bar-wrapper
    }
  }

  .bt.bt-navy {
    padding-left: 27px;
    padding-right: 27px;
  }
}

.recommendations-header {
  &__toolbar {
    display: flex;
    justify-content: space-between;

    .infobox {
      display: flex;
      flex-flow: column-reverse;
      justify-content: space-between;
    }
  }

  .event-title {
    color: #1f5274;
    line-height: 2.875rem;
    font-weight: 600;
    font-size: pxToRem(30);
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
