.event-header {
  .event-title {
    color: #1f5274;
    line-height: 2.875rem;
    font-weight: 600;
    font-size: $fs-32;

    margin-bottom: pxToRem(12);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media #{$navbreak-max} {
      font-size: $fs-30;
      margin-bottom: pxToRem(12);
      color: #1f5a7e;
    }
  }

  .dots-menu_wrapper--expandable {
    min-width: 172px;
  }

  &_more-dots-button {
    background: $border;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 34px;
    max-width: 40px;
    min-height: 34px;
    min-width: 40px;
    border-radius: 6px;
    transition-duration: 0.2s;
    opacity: 0.7;
    &:hover {
      // background: $red-50;
      transition-duration: 0.2s;
      opacity: 1;
    }
  }

  &_more-dots_wrapper {
    .dots-menu_wrapper {
      width: 270px;
      top: 45px;
      right: 12px;
    }
  }
}
