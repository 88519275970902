.portal-modal {
  &_outer {
    &_wrapper {
      position: fixed;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 99999999999999;
      background: rgba(1, 1, 1, 0.25);
    }
  }

  &_inner {
    &_wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 300px;
      max-height: calc(80vh);
      // width: calc(100%);
      overflow: hidden;
      padding: 0 1em;

      @media screen and (min-width: 991px) {
        // min-width: 800px;
      }

      &.full-height {
        max-height: calc(95vh);
      }
    }

    &_content_wrapper {
      background: white;
      border-radius: $card-border-radius;
      overflow: auto;
      scroll-behavior: smooth;
      scroll-padding: 1rem;

      .content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;
        padding-top: 1.2rem;

        .title-txt {
          color: $navy-75;
          font-weight: 500;
          margin-top: 2rem;
          margin-bottom: 1.2rem;

          &.emphasized {
            color: #1f5274;
            font-weight: 600;
          }
        }

        .title-txt-secondary {
          color: $navy-active;
          font-weight: 500;
          margin-bottom: 1.2rem;
        }

        .body-txt {
          color: $navy;
          font-weight: 500;
          margin-bottom: 1rem;

          &.error {
            color: $red;
            font-size: 0.9rem;
          }

          &.light {
            color: $navy-60;
          }

          &.larger {
            font-size: pxToRem(20);
          }

          &.emphasized {
            font-weight: 600;
          }
        }

        .footer {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          margin-top: 0.5rem;
        }

        &.compact {
          padding: 1.2rem;

          .title-txt {
            margin-top: 0;
          }
        }

        .close {
          background: $border;
          // if we hide the header we still need the close button
          // p-abs allows it to remain in the corner so a custom
          // title can be used at the content level
          position: absolute;
          top: 0.6em;
          right: 0.6em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          max-height: 34px;
          max-width: 40px;
          min-height: 34px;
          min-width: 40px;
          border-radius: 6px;
          transition-duration: 0.2s;
          opacity: 0.7;

          &:hover {
            transition-duration: 0.2s;
            opacity: 1;
          }

          img {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}
