.mobile-bottom-bar {
  position: fixed;
  @media #{$md} {
    display: none;
  }
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 0;
  background-color: white;
  border-top: 1px solid rgba(black, 0.2);
  ul {
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    li {
      text-align: center;
      flex: 100%;
    }
  }
  .mbb-link {
    display: inline-block;
    text-align: center;
    color: #a0a0a0;
    .mbb-icon-wrapper {
      display: inline-block;
      height: pxToRem(24);
      line-height: pxToRem(24);
      margin-bottom: 0.75rem;
    }
    img {
      display: inline-block;
      vertical-align: middle;
      opacity: 0.6;
    }
    .mbb-label {
      display: block;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 0.75rem;
      letter-spacing: 0.01rem;
    }
    &.active {
      img {
        opacity: 1;
      }
      .mbb-label {
        color: #3b86bd;
      }
    }
  }
}
